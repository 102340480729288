.riviere_light_text_color {
    color: $riviere_light;
}

.riviere_dark_text_color {
    color: $riviere_dark;
    text-transform: uppercase
}

.contact_la_riviere_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../pictures/la_riviere/riviere.jpg);
    background-size: cover;
    background-position: 60% 55%;
 
    @extend .black_veil_07;

}

.divider_riviere{
    @extend .divider;
    background-color: $riviere_light;
}

.contact_openings_riviere {
    @extend .contact_openings;
    border-top: 1px solid $riviere_light;
    border-right: 1px solid $riviere_light;
    border-bottom: 1px solid $riviere_light;
}
.icon_fermé_riviere {
    @extend .closed_icon;
    color: $riviere_light;
}
.image_planche {
    position: absolute;
    top: 0;
    left: 10rem;
    height: 10rem;
    transform: translateY(-70%);
    z-index: 3;
}

@include mediaLg {
    .image_planche {
        position: absolute;
        top: 3rem;
        left: 4rem;
        height: 9rem;
        transform: translateY(-70%);
        z-index: 3;
    }
}
@include mediaMd {
    .image_planche {
        position: absolute;
        top: 0;
        left: 2rem;
        height: 7rem;
        transform: translateY(-70%);
        z-index: 3;
    }
}

@include mediaSm {
    .image_planche {
        position: absolute;
        top: 0;
        left: 2rem;
        height: 7rem;
        transform: translateY(-70%);
        z-index: 3;
    }
}

@include mediaXs {
    .image_planche {
        position: absolute;
        top: 0;
        left: 2rem;
        height: 7rem;
        transform: translateY(-70%);
        z-index: 3;
    }
}