.fixed_bg_riviere{
    position: fixed;
    top: 0rem;
    left: 0;
    background-image:url(../pictures/la_riviere/table_solo.jpg);
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    z-index: -2;
    @extend .black_veil_04;
}
