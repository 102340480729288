.page2_container {
    position: absolute;
    height: auto;
    max-width: 100vw;
    width: 100vw;
    top: 0;
    left: 0;
   
}
.scroll_container {
    background-color: transparent;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;

    .fixed_bg_jardin{
        @extend .fixed_bg;
        background-image:url(../pictures/le_jardin/shooting/jardin.jpg);
        background-size: cover;
        background-position: 100% 40%;
        z-index: -1;
        @extend .black_veil_04;
    }
    
    .scroll_header {
        position: relative;
        
        height: 100vh;
        width: 100vw;
        align-items: center;
        justify-content: center;
        text-align: center;

        h2 {
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translatex( -50%);
            font-family: $style_font;
            color: set_text_color($primary_color);
            font-size: 3rem;
            letter-spacing: 3px;
            font-weight: 600;
            text-shadow: 2px 2px rgba(black, 0.8);
            div {
                font-size: 2.5rem;
            }
        }
        h4 {
            position: absolute;
            top: 55%;
            left: 50%;
            transform: translateX(-50%);
            padding: 0;
            font-family: $style_font;
            color: set_text_color($primary_color);
            text-shadow: 2px 2px rgba(black, 0.6);
            font-size: 2rem;
            text-transform: capitalize;
        }
       
    }
}
.jardin_grid_container {
    width: 100vw;
    display: flex;
    background-color: #fff;
    padding: 3rem 0;

    .grid_jardin_left_side {
        width: 50%;
        // text-align: right;
        .title {
            @extend .h3_title;
            width: 55%;
            // color: $garden_dark;
            font-size: 2.5rem;
            margin: 0 5rem 2rem auto;
            
        }
        .jardin_title {
            color: $garden_dark;
        }
        .ile_plate_title {
            color: $ocean;
        }
        .riviere_title {
            color: $riviere_dark;
        }
        .plage_title {
            color: $dark_sand;
        }
        .image_batch_container {
            margin: 0 5rem 5rem auto;
        }
    }
    .grid_jardin_right_side {
        width: 50%;
        .image_batch_container {
            margin: 0rem auto 5rem 5rem;
        }
    }
    .image_batch_container {
        width: 55%;
        // align-items: center;
        // justify-content: center;
        // text-align: center;
        color: set_text_color(#fff);
        .batch_title{
            @extend .h3_title;
            // color: $garden_light;
        }
        .ile_plate_batch_title {
            color: $lagoon;
        }
        .jardin_batch_title {
            color: $garden_light;
        }
        .riviere_batch_title {
            color: $riviere_light;
        }
        .plage_batch_title {
            color: $sand;
        }
        .landscape_image {
            width: 100%;
        }
        p {
            width: 100%;
            text-align: justify;
            margin: 1rem auto;
        }
        .portrait_image {
            width: 30%;
            margin: 0 auto;
        }
        .move1 {
            transform: translate(10%, 25%);
        }
        .move2 {
            transform: translate(20%, 50%);
        }
    }
}
.content3 {
    background-color: rgba($primary_color,0.5);
    font-family: $style_font;
    width: 100vw;
    height: auto;
    padding: 5rem 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;

    
    h2 {
        font-size: 2.5rem;
        letter-spacing: 2px;
        @extend .h2_title;
    }
    div {
        font-size: 0.9rem;
        text-transform: uppercase;
        max-width: 14rem;
        margin: 0 auto;
        color: set_text_color($primary_color);
        
        
        padding: 1rem 0rem;
        letter-spacing: 1px;
        word-spacing: 2px;
        
    }
    .reservation_btn_ile_plate {
        background-color: $ocean;
        border: 2px solid $ocean;
    }
    .reservation_btn_riviere {
        background-color: $riviere_light;
        border: 2px solid $riviere_light;
    }
    .reservation_btn_plage {
        background-color: $sand;
        border: 2px solid $sand;
    }
    .reservation_btn_jardin {
        background-color: $garden_dark;
        border: 2px solid $garden_dark;
    }
    h1 {
        margin-top: 6rem;
        font-size: 3.5rem;
        color: set_text_color(rgba($primary_color,0.5));
    }
    .contact_h2 {
        color: set_text_color(rgba($primary_color,0.9));
    }

    
}

.mini_gallery_jardin {
    width: 100vw;
    background-color: #fff;
    padding: 3rem 0;
    .mini_gallery_wrap {
        max-width: 90%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        gap: 1.5rem;
        img {
            width: 500px;
            height: 340px;
            background-size: cover;
        }
    }
}


@include mediaXl {
    .scroll_container {
        .scroll_header {
            h2 {
                top: 35%;
                font-size: 3rem;
                div {
                    font-size: 2.5rem
                }
            }
            h4 {
                font-size: 2rem;
            }
        }
    }
}
@include mediaLg {
   
        .fixed_bg_jardin {
            background-position: center;
        }
        .scroll_header {
            h2  {
                top: 40%;
                font-size: 2.5rem;
                div {
                    font-size: 2rem;
                }
                
            }
            h4 {
                top: 55%;
                font-size: 2rem;
            }
        }
        .jardin_grid_container {
        
            .grid_jardin_left_side{
                .title{
                    text-align: center;
                    width: 100%;
                    font-size: 2rem;
                }
                .image_batch_container {
                    margin: 0 auto 3rem auto;
                }
            }
            .grid_jardin_right_side {
                width: 50%;
                align-items: center;
                justify-content: center;
                .image_batch_container {
                    margin: 0 auto 3rem auto;
                }
            }
            .image_batch_container {
                width: 80%;
                margin: 0 auto;
                .batch_title {}
                .landscape_image {}
                p {
                    font-size: 0.8rem;
                    word-spacing: 4px;
                    line-height: 1.5rem;
                }
                .portrait_image{}
                .move1 {}
                .move2 {}
            }
        }
        .content3 {
            h2 {}
            div {}
            h1 {}
            .contact_h2 {}
        }
       .mini_gallery_jardin {
        .mini_gallery_wrap {
            img {}
        }
       }
    }


@include mediaMd {
 
        .fixed_bg_jardin {
            background-position: center;
        }
        .scroll_header {
            h2  {
                top: 35%;
                max-width: 100%;
                font-size: 2.5rem;
                div {
                    font-size: 2rem;
                }
            }
            h4 {
                top: 50%;
                font-size: 1.5rem;
            }
        }
        .jardin_grid_container {
            display: block;
            .grid_jardin_left_side{
                width: 100%;
                margin-bottom: 10rem;
                .title{}
                .image_batch_container {}
            }
            .grid_jardin_right_side {
                width: 100%;
                .image_batch_container {}
            }
            .image_batch_container {
                .batch_title {}
                .landscape_image {}
                p {}
                .portrait_image{}
                .move1 {}
                .move2 {}
            }
        }
        .content3 {
            .contact_h2 {
                font-size: 2rem;
            }
            div {
                font-size: 0.8rem;
                padding: 0.7rem 0rem;
            }
            h1 {
                font-size: 2.5rem;
            }
        }
       .mini_gallery_jardin {
        .mini_gallery_wrap {
            img {}
        }
       }
    }


@include mediaSm {
    .page2_container {
        max-width: 100vw;
    }
    .scroll_container {
        max-width: 100vw;
    }
        .fixed_bg_jardin {
            background-position: center;
            background-size: cover;
        }
        .scroll_header {
            h2  {
                display: block;
                top: 35%;
                font-size: 2.5rem;
                width: 90%;
                line-height: 3rem;
                div {
                    font-size: 1.5rem;
                }
            }
            h4 {
                top: 50%;
                font-size: 1.5rem;
            }
        }
        .jardin_grid_container {
            display: block;
            .grid_jardin_left_side{
                width: 100%;
                margin-bottom: 10rem;
                .title{}
                .image_batch_container {}
            }
            .grid_jardin_right_side {
                width: 100%;
                .image_batch_container {}
            }
            .image_batch_container {
                .batch_title {}
                .landscape_image {}
                p {}
                .portrait_image{}
                .move1 {}
                .move2 {}
            }
        }
        .content3 {
            .contact_h2 {
                font-size: 2rem;
            }
            div {
                font-size: 0.8rem;
                padding: 0.7rem 0rem;
            }
            h1 {
                font-size: 2.5rem;
            }
        }
        .mini_gallery_jardin {
            width: 100vw;
            background-color: #fff;
            padding: 3rem 0;
            .mini_gallery_wrap {
                max-width: 90%;
                align-items: center;
                justify-content: center;
                display: flex;
                flex-wrap: wrap;
                margin: 0 auto;
                gap: 1.5rem;
                img {
                    // width: 500px;
                    // height: 340px;
                    width: 100vw !important;
                    height: auto !important;
                    background-size: cover;
                }
            }
        }
    
}

@include mediaXs {
    .page2_container {
        max-width: 100vw;
    }
    .scroll_container {
        max-width: 100vw;
    }
        .fixed_bg_jardin {
            background-position: center;
            background-size: cover;
        }
        .scroll_header {
            h2  {
                display: block;
                top: 35%;
                font-size: 2.1rem;
                width: 90%;
                line-height: 3rem;
                div {
                    font-size: 1.4rem;
                }
            }
            h4 {
                top: 55%;
                font-size: 1.2rem;
            }
        }
        .jardin_grid_container {
            display: block;
            .grid_jardin_left_side{
                width: 100%;
                margin-bottom: 10rem;
                .title{
                    font-size: 1.3rem;
                }
                .image_batch_container {}
            }
            .grid_jardin_right_side {
                width: 100%;
                .image_batch_container {}
            }
            .image_batch_container {
                .batch_title {}
                .landscape_image {}
                p {}
                .portrait_image{}
                .move1 {}
                .move2 {}
            }
        }
        .content3 {
            .contact_h2 {
                font-size: 1.5rem;
            }
            div {
                font-size: 0.7rem;
                padding: 0.7rem 0rem;
            }
            h1 {
                font-size: 2rem;
            }
        }
       .mini_gallery_jardin {
        .mini_gallery_wrap {
            img {}
        }
       }
    

}

// .content1 {
//     width: 100vw;
//     height: auto;
//     background-color: rgba($primary_color,0.9);
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     padding: 3rem 0;
//     h1 {
//         @extend .h1_title;
//         color: set_text_color(rgba($primary_color,0.9));
//         span {
//             margin: 0 3rem;
//         }
//     }
//     p {
//         width: 62%;
//         font-size: 1.4rem;
//         font-weight: 300;
//         margin: 2rem auto;
//         color: set_text_color(rgba($primary_color,0.9));
//         line-height: 2.8rem;
//         @include spacing;
//         word-spacing: 3px;
//         font-family: $style_font;
//     }
// }

// .content2 {
//     width: 100%;
//     height: auto;
//     .content2_post1 {
//         width: 59%;
//         height: 65vh;
//         display: flex;
//         background-color: #E5E5E5;
//         margin: 0 auto;
//         .autoswiper_container{
//             width: 50%;
       
//             align-items: center;
//             justify-content: center;
//         }
//         .content2_comment_container {
//             width: 50%;
//             text-align: center;
//             justify-content: center;
//             align-items: center;
//             padding: 8vh 7vh;
            
//             p {
//                 margin: 1em 0rem;
//                 font-size: 1rem;
//                 color: set_text_color(#E5E5E5);
//                 font-weight: 350;
//                 line-height: 1.7rem;
//                 letter-spacing: 0.02em;
//                 font-family: $style_font;

//             }   
//         }
//     }
// }
// .contact_h2 {
//     @extend .h2_title;
// }

// .content3_container{
//     display: flex;
//     width: 70vw;
//     height: auto;
//     margin: 0 auto;
//     .info_container {
//         width: 50%;
        
//         .contact_h3 {
//             font-size: 1.3rem;
//             font-weight: 650;
//             text-transform: uppercase;
//             letter-spacing: 2px;
//             color: set_text_color(rgba($primary_color,0.9));
//         }
//         .openings_container {
//             text-align: start;
//             padding: 0 3rem;
//             div {
//                 .opening_days {
//                     list-style: none;
//                     max-width: 19rem;
//                     width: 70%;
//                     position: relative;
//                     list-style: none;
//                     color: $dark_grey;
//                 }
//                 .openings {
//                     color: set_text_color(rgba($primary_color,0.9));
//                     position: absolute;
//                     right: 0;
//                 }
//             }
//         }
//         .contact_container {
//             text-align: start;
//             padding: 0 3rem;
//             .contact_info {
//                 width: 23rem;
//                 display: flex;
//                 align-items: center;
//                 .contact_icon{
//                     color: $dark_grey;
//                 }
                
//                 p {
//                     color: $dark_grey;
//                     margin: 0.5rem 1rem;
//                 }
//             }
//         }
//     }
//     .google_map_container {
//         align-items: center;
//         justify-content: center;
//         position: relative;
//         width: 50%;
//         .google_map_frame {
//             position: absolute;
//             top: 50%;
//             right: 3rem;
//             transform: translateY(-50%);
//             width: 30rem;
//             height: 20rem;
//             border: none;
//         }
//     }
// }