.hero_half_contact_plage {
    @extend .hero_half;
    @extend .black_veil_04;
    background-image: url(../pictures/la_plage/la_plage.jpg);
    background-position: 60% 50%;
}

.laplage_gmap{
        width: 100%;
        height: 100%;
        border: none;    
}
.laplage_ocean{
    color: $dark_sand;
}
.contact_phone_laplage{
    @extend .h3_title_bold;
    color: $sand;
}
.divider_laplage{
    @extend .divider;
    background-color: $dark_sand;
}

.icon_fermé_plage {
    @extend .closed_icon;
    color: $dark_sand;
}

.contact_openings_plage {
    @extend .contact_openings;
    border-top: 1px solid $dark_sand;
    border-right: 1px solid $dark_sand;
    border-bottom: 1px solid $dark_sand;
}
.contact_plage_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../pictures/la_plage/sand_wave_zoom.jpg);
    background-size: cover;
    background-position: 50% 50%;
    @extend .black_veil_07; 
}

.image_cut_contact_plage {
    position: absolute;
    top: 5rem;
    left: 3rem;
    height: 20rem;
    transform: translateY(-90%);
    z-index:5;
}


@include mediaXl {
    .image_cut_contact_plage {
       top: 10%;
        left:2%;
        height: 18rem;
    } 
}

@include mediaLg {
    .image_cut_contact_plage {
        top: 20%;
        left: -7%;
        height: 15rem;
    }
}

@include mediaMd {
    .image_cut_contact_plage {
        
        
        height: 13rem;
    }
}
@include mediaSm {
    .image_cut_contact_plage {
        top: 5%;
        left: 25%;
        height: 10rem;
    }
}