@import './tools/configs';
@import './tools/titles';
@import './tools/btns';
@import './tools/effects';

@import './subcomponents/under_construction';

// LAYOUT
@import './layout/navbarjardin';
@import './layout/_mainnavbar';
@import './layout/footer_jardin';
@import "./layout/footer";
@import './layout/sticky_navbar';
@import './layout/sticky_navbar2';

// HOME
@import "./components/home";
@import "./components/home2";

// LE JARDIN
@import './components/lejardin';
@import './components/gallery';
@import './components/contact_jardin';
@import './subcomponents/autoswiper';
@import './subcomponents/contact_form';

// LE CATAMARAN
@import "./components/lecatamaran";
@import './components/contact_cata';
@import './components/offers_cata';

// LA PLAGE
@import "./components/laplage";
@import './components/contact_plage';

// ILE PLATE
@import './components/ile_plate';
@import './components/contact_ile_plate';

// LA RIVIERE
@import './components/lariviere';
@import './components/contact_riviere';

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@200;300;500;600&family=Poppins:wght@300;400;500;600;700&family=Titillium+Web:wght@300;400;700&display=swap');
// RALEWAY
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@500;600&family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@100;300;400;500;600;700&family=Titillium+Web:wght@300;400;700&display=swap');

// MONTSERRAT
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400&family=Noto+Serif+Georgian:wght@500;600&family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@100;300;400;500;600;700&family=Titillium+Web:wght@300;400;700&display=swap');
// POPPINS
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400&family=Noto+Serif+Georgian:wght@500;600&family=Poppins:wght@100;200;600;700&family=Raleway:wght@100;300;400;500;600;700&family=Titillium+Web:wght@300;400;700&display=swap');

html, body {
    // overflow-x: hidden;
  }
  *, *::after,
  *::before {
    box-sizing: border-box;
   
}
body {
    // font-family: 'Montserrat', sans-serif;
    // font-family: 'Noto Serif Georgian', serif;
    font-family: 'Poppins', sans-serif;
    
    // position: relative;
    // font-family: 'Raleway', sans-serif;
    // font-family: 'Titillium Web', sans-serif;
    // height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    margin: 0;
}

.page_container {
  z-index: 1;
}

.contact_page_container {
  background-color: #fff;
  padding: 2rem 0;
}