.language_container_home {
    position: fixed;
    top: 0;
    right: 3%;
    z-index: 15;
    
    display: flex;
    align-items: center;
    transition: all .4s ease-in-out;

    .nav_language_title {
        @extend .info_title;
        color: set_text_color($primary_color);
    }
}
.navbar-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 9rem;
    width: 100%;
    padding: 0;
    background-color: $primary_color;
    transition: all .4s ease-in-out;
    margin: 0;
    z-index: 10;
    

    

    .navbar-logo {
        position: absolute;
        top: 0rem;
        margin: 0 auto;
        z-index: 2;
        margin: 0;
        height: 100%;
        max-width: 10rem;
        text-decoration: none;
        transition: all .4s ease-in-out;
        color: set_text_color($primary_color);
        &:hover {
            cursor: pointer;
            
        }
     
        img {
            vertical-align: middle;
            max-height: 9rem;
            max-width: 8rem; 
            transition: all .4s ease-in-out;
        }
        .logo_jardin {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    .navbar {
        width: 85%;
        justify-content: space-between;
        display: flex;
        border: 2px solid $light_text_color;
        padding: 1rem;
        margin: 0 auto;
        .nav_container {
            display: flex;
            width: 40vw;
            justify-content: space-evenly;
            .navbar-a {
                color: set_text_color($primary_color);
                text-decoration: none;
                
                font-size: 1.3rem;
                display: flex;
                @include spacing;
                text-transform: capitalize;
                text-underline-offset: none;
                
            }

            .active_navlink {
                text-decoration: none;
                font-size: 1.3rem;   
                display: flex;
                @include spacing;
                text-transform: capitalize;
                text-underline-offset: none;
            }
            
            .cata1 {
                color: $ocean;
            }
            .cata2 {
                color: set_text_color($primary_color);
                &:hover {
                    cursor: pointer;
                    color: $ocean;
                }
            }
            .cata3 {
                color: $lagoon;
                &:hover {
                    cursor: pointer;
                    color: $ocean;
                }
            }
            .plage1 {
                color: $dark_sand;
                
            }
            .plage2 {
                color: set_text_color($primary_color);
                &:hover {
                    cursor: pointer;
                    color: $dark_sand;
                }
            }
            .plage3 {
                color: $sand;
                &:hover {
                    cursor: pointer;
                    color: $dark_sand;
                }
            }
            .jardin1 {
                color: $garden_dark;
            }
            .jardin2 {
                color: set_text_color($primary_color);
                &:hover {
                    color: $garden_dark;
                }
            }
            .jardin3 {
                color: $garden_light;
                &:hover {
                    cursor: pointer;
                    color: $garden_dark;
                }
            }
        }
    }
    .nav-btn {
        cursor: pointer;
        background: $primary_color;
        border: none;
        outline: none;
        color: set_text_color($primary_color);
        visibility: hidden;
        opacity: 0;
        z-index: 10;

        .navbar_icon {
            position: absolute;
            top: 0;
            right: 0rem;
            transform: translateY(-50%);
            height: 1rem;
            width: 1rem;
            visibility: hidden;
        
            z-index: -2;
        }
        .active {
            visibility: visible;
            z-index: 10;
        }
        .close_btn {
            position: absolute;
            top: 0;
            right: 0rem;
            transform: translateY(-50%);
            height: 1.2rem;
            width: 1.2rem;
            visibility: hidden;

            z-index: -2;
            
        }
        .visible {
            visibility: visible;
            z-index: 10;
        }
        
    }
}

.langague_flags_container {
    display: flex;
    .flag_container{
        width: 1.7rem;
        height: 0.8rem;
        background-position: center;
        background-size: cover;
        &:hover {
            cursor: pointer;
        }
    }
    .french {
        background-image: url(../pictures/french_flag.png);  
        margin-right: 0.3rem;
    }
    .english {
        background-image: url(../pictures/british_flag.jpg);  
        margin-right: 0.3rem;
    }
    .russian {
        background-image: url(../pictures/russian_flag.png);
    }
}

// SCROLL EFFECT
.navbar-header.sticky {
    background-color: $primary_color;
    height: 2.8rem;
    box-shadow: 0 20px 10px rgba(0,0, 0, 0.3);
    .language_container {
        display: none;
    }
    .navbar  {
        border: none;
    }
    .navbar-logo {
        img {
            transform: translateY(-100%);
        }
        .logo_jardin {
            transform: translate(-50%, -200%);
        }

    }
    
    .navbar-a {
        color: set_text_color($primary_color);
    }
}

// MEDIA

@include mediaXl {
    .navbar-header {
        height: 7rem;
        .language_container {
            transform: translate(-40%, -10%);
            h3 {
                font-size: 0.7rem;
            }
            .langague_flags_container {
                .flag_container{
                    width: 1rem;
                    height: 0.5rem;
                }
            } 
        }
        .navbar-logo {
            left: 50%;
            transform: translateX(-50%);
            img {
                max-height: 7rem;
                max-width: 5.5rem;
            }
        }
        .navbar {
            .nav_container {
                .navbar-a {
                    font-size: 0.8rem;
                    // margin: 0 0.6rem;
                }
                .active_navlink{
                    font-size: 0.8rem;
                    // margin: 0 0.7rem;
                }
            }
        }
    }
    .navbar-header.sticky {
        height: 4rem;
        .navbar {
            .nav_container {
                .navbar-a {
                    font-size: 0.8rem;
                    margin: 0 0.7rem;
                }
                .active_navlink{
                    font-size: 0.8rem;
                    margin: 0 0.7rem;
                }
            }
        }
    }
}
@include mediaLg{
    .navbar-header {
        height: 7rem;
        .language_container {
            transform: translate(-40%, -10%);
            h3 {
                font-size: 0.7rem;
            }
            .langague_flags_container {
                .flag_container{
                    width: 1rem;
                    height: 0.5rem;
                }
            } 
        }
        .navbar-logo {
            left: 50%;
            transform: translateX(-50%);
            img {
                max-height: 7rem;
                max-width: 5.5rem;
            }
        }
        .navbar {
            .nav_container {
                .navbar-a {
                    font-size: 0.8rem;
                    // margin: 0 0.6rem;
                }
                .active_navlink{
                    font-size: 0.8rem;
                    // margin: 0 0.7rem;
                }
            }
        }
    }
    .navbar-header.sticky {
        height: 4rem;
        .navbar {
            .nav_container {
                .navbar-a {
                    font-size: 0.8rem;
                    margin: 0 0.7rem;
                }
                .active_navlink{
                    font-size: 0.8rem;
                    margin: 0 0.7rem;
                }
            }
        }
    }
}

@include mediaMd {
    .navbar-header {
        justify-content: right;
        height: 4rem;
        width: 100vw;
        .navbar {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            height: 2rem;
            min-width: 95%;
            align-items: center;
            gap: 1rem;
            border: none;
            transition: 1s;
            margin: 0.1rem 0;
            z-index: 1;
            .nav_container {
                width: 45%;
                align-items: center;
                .navbar-a {
                    font-size: 0.7rem;
                    
                }
                .active_navlink{
                    font-size: 0.7rem;
                    
                }
            }
        }
        
        .language_container {
            .nav_language_title {
                font-size: 0.7rem;
            }
        }
        .navbar-logo {
            position: absolute;
            top: 0rem;
            left: 50%;
            transform: translateX(-50%);
            img {
                max-height: 4rem;
                
            }
        }
        
    }
    .navbar-header.sticky {
        height: 2rem;
        
        .language_container {
            visibility: hidden;
        }
        .navbar  {
            border: none;
            height: 0.1rem;
            .nav_container {
                width: 45%;
                align-items: center;
                .navbar-a {
                    font-size: 0.7rem;
                    
                }
            }
            
        }
        .navbar-logo {
            position: absolute;
            transform: translatey(-100%);
        }
    }
    .language_container_home {
        .nav_language_title {
            font-size: 0.7rem;
        }
    }
}

@include mediaSm {
    .language_container_home {
        .nav_language_title {
            font-size: 0.7rem;
     
        }
    }
    .navbar-header {
        height: 3rem;
        width: 100vw;
        .language_container {
            top: 50%;
            right: 20%;
            transform: translateY(-50%);
       
        }
        .navbar-logo {
            position: fixed;
            top: 0;
            left: 3rem;
            height: 3rem;
            max-width: 5rem;
            margin: 0;
            transform: none;
            img {
                height: 3rem;
            }
            .logo_jardin {
                position: fixed;
                top: 0;
                left: 3rem;
                transform: none;
            }
        }
        .navbar {
            position: absolute;
            top: 3rem;
            left: 0;
            min-width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem 0;
            gap: 1rem;
            background-color: rgba($primary_color, 0.8);
            border: none;
            transition: 1s;
            transform:translatex(100%);
            z-index: 1;
            .nav_container {
                width: auto;
                .navbar-a {
                    font-size: 1rem;
                    margin: 0 0.7rem;
                }
                .active_navlink{
                    font-size: 1rem;
                    margin: 0 0.7rem;
                }
            }
    
        }
        .nav-btn {
            visibility: visible;
            opacity: 1;
            z-index: 2;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10%;
        }
        .responsive-nav {
            transform: none;
        }
    }
    .navbar-header.sticky {
        background-color: $primary_color;
        height: 3rem;
        width: 100vw;
        box-shadow: none;
        .language_container {
            visibility: hidden;
        }
        .navbar  {
            border: none;
            .nav_container {
                width: auto;
                .navbar-a {
                    font-size: 1rem;
                    margin: 0 0.7rem;
                }
                .active_navlink{
                    font-size: 1rem;
                    margin: 0 0.7rem;
                }
            }
        }
        .navbar-logo {
            position: fixed;
            top: 0;
            left: 3rem;
            transform: none;
            img {
                height: 3rem;
                transform: none;
            }
            .logo_jardin {
                position: fixed;
                top: 0;
                left: 3rem;
                transform: none;
            }
        }

    }
    
}
@include mediaXs {
    .language_container_home {
        .nav_language_title {
            font-size: 0.7rem;
        }
    }
    .navbar-header {
        height: 3rem;
       
        width: 100vw;
        .language_container {
            top: 50%;
            right: 20%;
            transform: translateY(-50%);
            .nav_language_title{
                font-size: 0.7rem;
            }
        }
        .navbar-logo {
            position: fixed;
            top: 0;
            left: 3rem;
            height: 3rem;
            max-width: 5rem;
            margin: 0;
            transform: none;
            img {
                height: 3rem;
            }
            .logo_jardin {
                position: fixed;
                top: 0;
                left: 1rem;
                transform: none;
            }
        }
        .navbar {
            position: absolute;
            top: 3rem;
            left: 0;
            min-width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2rem 0;
            gap: 1rem;
            background-color: rgba($primary_color, 0.8);
            border: none;
            transition: 1s;
            transform:translatex(100%);
            z-index: 1;
            .nav_container {
                width: auto;
                .navbar-a {
                    font-size: 0.7rem;
                    margin: 0 0.7rem;
                }
                .active_navlink{
                    font-size: 0.7rem;
                    margin: 0 0.7rem;
                }
            }
    
        }
        .nav-btn {
            visibility: visible;
            opacity: 1;
            z-index: 2;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10%;
        }
        .responsive-nav {
            transform: none;
        }
    }
    .navbar-header.sticky {
        background-color: $primary_color;
        height: 3rem;
        width: 100vw;
        box-shadow: none;
        .language_container {
            visibility: hidden;
        }
        .navbar  {
            border: none;
            .nav_container {
                width: auto;
                .navbar-a {
                    font-size: 1rem;
                    margin: 0 0.7rem;
                }
                .active_navlink{
                    font-size: 1rem;
                    margin: 0 0.7rem;
                }
            }
        }
        .navbar-logo {
            position: fixed;
            top: 0;
            left: 3rem;
            transform: none;
            img {
                height: 3rem;
                transform: none;
            }
            .logo_jardin {
                position: fixed;
                top: 0;
                left: 3rem;
                transform: none;
            }
        }

    }
}