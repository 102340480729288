

// https://www.youtube.com/watch?v=lPJVi797Uy0
.offers_container {
    width: 100vw;
    min-height: 80vh;
    margin: 11rem auto 3rem auto;
    display: block;
    
    // NIGHT
    .img_container_night{
        width: 70%;
        height: 60vh;
        margin: 0 auto;
        
        .img {
        background-image: url(../pictures/le_catamaran/night/catamaran_night.jpg);
        background-size: cover;
        background-position: 10% 50%;
        width: 95%;
        height: 95%;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;
            background-color: rgba($sunset, 0.9);
            
        }
        .concept_description_container {
                position: absolute;
                right: 7.5%;
                top: 50%;
                transform: translateY(-50%);
                width: 35%;
                max-height: 95%;
                height: auto;
                
                text-align: center;
                padding: 0;
            .concept_description {
                
                font-weight: 300;
                margin: 1.5rem 0;
                font-size: 1rem;
                word-spacing: 8px;
                line-height: 1.4rem;
                font-weight: 300;
                // line-height: 2.5rem;
                color: white;
                margin: 2rem 0;
                text-align: justify;
            }
        }
        
        .quote_icon {
            position: absolute;
            color: white;
            
        }
        .begin {
            top: 10%;
            right: 40%;
        }
        .end {
            bottom: 10%;
            right: 7.5%;
        }
        }

    }
    .card_offer_container_night{
       padding-left: 2rem;
       width: 70%;
        height: auto;
        margin: 0 auto;
        .offer_derscription_container {
            .offer_title {
                color: $sunset;
                @extend .h2_title;
                font-size: 2rem;
                
                margin-bottom: 1rem;
            }
            .description_container {
                
                .menu_category_title {
                    color: $sunset;
                    @extend .h2_title;
                    font-size: 1.7rem;
                    
                    margin-bottom: 1rem;
                    text-transform: capitalize;
                    width: 70vw;
                    border-bottom: 1px solid $sunset;
                }
                .intro_night {
                 
                    font-weight: 400;
                    color: rgba($primary_color, 0.5);
                    margin: 0.5rem;
                    font-size: 0.9rem;
                    text-align: justify;
              
                }
                .overview {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                    margin-bottom: 1.5rem;
                    .icon_container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 0 1rem;
                        line-height: 0.1rem;
    
                        .cata_icon {
                            color: $sunset;
                        }
                        p {
                            font-size: 0.85rem;
                            margin-left: 1rem;
                            color: set_text_color($light_text_color);
                            
                            span {
                                color: rgba($primary_color, 0.5);
                            }
                            .green {
                                color: $garden_dark;
                                margin: 0 0.15rem;
                            }
                            .red {
                                color: $dark_red;
                                margin: 0 0.15rem;
                            }
                            
                        }
                    }
                }
              
                .description {
                    margin-bottom: 2rem;
                    display: flex;
                }
                .golden {
                    width: 50%;
                    padding-right: 0.5rem;
                }
                .infinite {
                    width: 50%;
                }
                .menu_container {
                    h3 {
                        color: $sunset2;
                        margin: 0;
                        text-transform: capitalize;
                        @extend .h3_title;
                        font-size: 1.3rem;
                        letter-spacing: 1.5px
                    }
                    h4 {
                        @extend .h3_title;
                        font-size: 1rem;
                        margin: 0.5rem 0 0 0 ;
                        text-transform: capitalize;
                        color: rgba($primary_color, 0.6);
                        text-transform: capitalize;
                    }
                    .menu2_container {
                        display: flex;
                        flex-wrap: wrap;
                        width: auto;
                        line-height: 0.7rem;
                        align-items: center;
                        p {
                            margin: 0.5rem;
                            font-size: 0.82rem;
                            line-height: 0.5rem;
                        }
                        .divide {
                            background-color: $sunset;
                            height: 10px;
                            width: 1px;
                        }
                    }

                }
                .packs_container {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 1rem;
                    width: 100%;
                    margin-bottom: 1rem;
                    justify-content: flex-start;
                    text-align: start;
                    .pack_bulles_or {
                        width: 30%;
                        margin-right: 1.5rem;
                       
                        
                        h3 {
                            color: $sunset2;
                            margin: 0;
                            text-transform: capitalize;
                            @extend .h3_title;
                            font-size: 1.3rem;
                            letter-spacing: 1.5px
                            
                        }
                        h4 {
                            @extend .h3_title;
                            font-size: 1rem;
                            color: rgba($primary_color, 0.6);
                            margin: 1rem 0 1rem 0 ;
                            text-transform: capitalize;
                        }
                        h5 {
                            font-weight: 400;
                            margin: 1rem 0;
                            font-size: 0.82rem;
                            line-height: 0.5rem;
                        }
                        .menu2_container {
                            width: 100%;
                            justify-content: center;
                            text-align: flex-start;
                            margin: 0.5rem 0;
                            
                            h5 {
                                font-weight: 400;
                                margin: 0.5rem;
                                font-size: 0.82rem;
                                line-height: 0.5rem;
                            }
                            .divider_container {
                                display: flex;
                                text-align: center;
                                width: 100%;
                                justify-content: flex-start;
                                align-items: center;
                                padding-left: 0.5rem;
                                .divide_or {
                                    height: 1px ;
                                    width: 2rem;
                                    background-color: $sunset;
                                    margin: 0 0rem;
                                }
                                p {
                                margin: 0.5rem;
                                font-size: 0.8rem;
                                line-height: 0.5rem;
                                }
                            }
                        }
                    }
                }
                .btn_tocontact {
                    padding: 0.4rem 1rem;
                    border: 1px solid $sunset;
                    text-decoration: none;
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    color: $sunset;
                    letter-spacing: 2px;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        cursor: pointer;
                        background-color: $sunset;
                        color: set_text_color($sunset);
                    }
                    
                }
            }
        }
        
    }
    
}


@include mediaXl {
    .offers_container {
        margin: 10rem auto 3rem auto;
        display: block;
        width: 90vw;
        height: auto;
        .img_container{
            width: 100%;
            height: 50vh;
        }
        .card_offer_container {
            margin: 1rem 0;

            .offer_derscription_container {
                width: 100%;
                margin: 0 auto;
                .offer_title {
                    margin-top: 1rem;
                    font-size: 2rem;
                }
                .description_container {
                    margin: 0 auto;
                    .overview {
                        width: 100%;
                        gap: 0.5rem;
                        .icon_container{
                            .cata_icon{}
                            p {
                                font-size: 0.8rem;
                            }
                            .green {}
                            .red {}
                        }
                    }
                    .description {
                        p{
                            text-align: justify;
                            font-size: 0.8rem;
                            
                        }
                        .menu_container {
                            h3 {
                                margin-top: 1rem;
                                margin-bottom: 0;
                            }
                            h4 {
                                
                            }
                            .menu2_container {
                                justify-content: left;
                                width: auto;
                                p{
                                    
                                }
                                .divide {}
                            }
                        }
                    }
                    .btn_tocontact {
                        font-size: 1rem;
                    }
                }
            }
        }

        .img_container_night {
            width: 100%;
            
            
            padding: 0;
            .img {
                .concept_description_container {
                    .concept_description{
                        line-height: 1.6rem;
                        
                    }
                }
               
            }
            
        }
        .card_offer_container_night {
            margin: 1rem 0;
            width: 86vw;
            padding: 0;
            .offer_derscription_container {
                
                .offer_title {
                    margin-left: 0;
                    margin-top: 1rem;
                    font-size: 2rem;
                }
                .description_container {
                    .overview {
                        width: 100%;
                        gap: 0.5rem;
                        .icon_container{
                            .cata_icon {
                                margin-right: 1rem;
                            }
                            p {
                                font-size: 0.8rem;
                                line-height: 1rem;
                                margin: 0.5rem 0;
                            }
                            .green {}
                            .red {}
                        }
                    }
                    .description {
                        
                    }
                    .golden {
                    }
                    .infinite {

                    }
                    .menu_container {
                        
                        h3 {
                            
                        }
                        h4 {}
                        .menu2_container {
                            display: block;
                            p{
                                font-size: 0.8rem;
                                line-height: 1rem;
                            }
                            .divide {}
                        }
                    }
                    .packs_container {
                        width: 100%;
                        .pack_bulles_or {
                            width: 30%;
                            h3 {
                                
                                margin-left: 0.1rem;
                            }
                            h4 {}
                            .menu2_container {
                                text-align: flex-start;
                                h5 {
                                    font-size: 0.8rem;
                                    line-height: 1rem;
                                }
                                .divider_container {
                                    justify-content: left;


                                    .divide_or {}
                                    h5 {
                                        margin: 0 0.5rem;
                                        
                                    }
                                }
                            }  
                        }
                    }
                    .btn_tocontact {}
                }
            }
        }
    }
}
@include mediaLg {
    .offers_container {
        margin: 9rem auto 3rem auto;
        display: block;
        width: 90vw;
        height: auto;
        .img_container{
            width: 100%;
            height: 40vh;
        }
        .card_offer_container {
            margin: 1rem 0;

            .offer_derscription_container {
                width: 100%;
                margin: 0 auto;
                .offer_title {
                    margin-top: 1rem;
                    font-size: 2rem;
                }
                .description_container {
                    margin: 0 auto;
                    .overview {
                        width: 100%;
                        gap: 0.5rem;
                        .icon_container{
                            .cata_icon{}
                            p {
                                font-size: 0.8rem;
                            }
                            .green {}
                            .red {}
                        }
                    }
                
                    .description {
                        p{
                            text-align: justify;
                            font-size: 0.8rem;
                            
                        }
                        .menu_container {
                            h3 {
                                margin-top: 1rem;
                                margin-bottom: 0;
                            }
                            h4 {
                                
                            }
                            .menu2_container {
                                justify-content: left;
                                width: auto;
                                p{
                                    
                                }
                                .divide {}
                            }
                        }
                    }
                    .btn_tocontact {
                        font-size: 1rem;
                    }
                }
            }
        }

        .img_container_night {
            width: 100%;
            height: 40vh;
            background-position: 100% 40%;
            padding: 0;
            .img {
                .concept_description_container{
                    .concept_description{
                        line-height: 1.1rem;
                        font-size: 0.9rem;
                        margin: 1rem 0;
                        right: 7.5%;

                    }
                }
                
                .begin {
                    top: 5%;
                    height: 2rem;
                }
                .end {
                    bottom: 5%;
                    height: 2rem;
                }
            }
        }
        .card_offer_container_night {
            margin: 1rem 0;
            width: 86vw;
            padding: 0;
            .offer_derscription_container {
                
                .offer_title {
                    margin-left: 0;
                    margin-top: 1rem;
                    font-size: 2rem;
                }
                .description_container {
                    .overview {
                        width: 100%;
                        gap: 0.5rem;
                        .icon_container{
                            .cata_icon {
                                margin-right: 1rem;
                            }
                            p {
                                font-size: 0.8rem;
                                line-height: 1rem;
                                margin: 0.5rem 0;
                            }
                            .green {}
                            .red {}
                        }
                    }
                    .description {
                        
                    }
                    .golden {
                    }
                    .infinite {

                    }
                    .menu_container {
                        
                        h3 {
                            
                        }
                        h4 {}
                        .menu2_container {
                            display: block;
                            p{
                                font-size: 0.8rem;
                                line-height: 1rem;
                            }
                            .divide {}
                        }
                    }
                    .packs_container {
                        width: 100%;
                        .pack_bulles_or {
                            width: 30%;
                            h3 {
                                
                                margin-left: 0.1rem;
                            }
                            h4 {}
                            .menu2_container {
                                text-align: flex-start;
                                h5 {
                                    font-size: 0.8rem;
                                    line-height: 1rem;
                                }
                                .divider_container {
                                    justify-content: left;


                                    .divide_or {}
                                    h5 {
                                        margin: 0 0.5rem;
                                        
                                    }
                                }
                            }  
                        }
                    }
                    .btn_tocontact {}
                }
            }
        }
    }
}

@include mediaMd {
    .offers_container {
        margin: 6rem auto 3rem auto;
        display: block;
        width: 95vw;

        .img_container{
            width: 100%;
            height: 40vh;
        }
        .card_offer_container {
            margin: 1rem 0;

            .offer_derscription_container {
                .offer_title {}
                .description_container {
                    .intro_night {
                        font-size: 1rem;
                        color: red;
                    }
                    .overview {
                        .icon_container{
                            .cata_icon{}
                            p {}
                            .green {}
                            .red {}
                        }
                    }
                    .description {
                        p{}
                        .menu_container {
                            h3 {}
                            h4 {}
                            .menu2_container {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 0rem;
                                p{
                                    
                                }
                                .divide {}
                            }
                        }
                    }
                    .btn_tocontact {
                        font-size: 0.9rem;
                    }
                }
            }
        }

        .img_container_night {
            width: 100%;
            height: 40vh;
            background-position: 100% 40%;
            .img {
               .concept_description_container{
                width: 40%;
                right: 5%;
                .concept_description{
                    line-height: 1.1rem;
                    font-size: 0.85rem;
                }
               }
                
                .begin {
                    top: 5%;
                    height: 2rem;
                }
                .end {
                    bottom: 5%;
                    height: 2rem;
                }
            }
        }
        .card_offer_container_night {
            margin: 1rem 0;
            padding: 0;
            width: 100%;
            .offer_derscription_container {
               
                .offer_title {
                    margin-left: 0;
                }
                .description_container {
                    .overview {
                        .icon_container {
                            .cata_icon {
                                margin-right: 1rem;
                            }
                            p {
                                line-height: 1rem;
                                margin: 0.5rem 0;
                            }
                        }
                    }
                    .description {}
                    .golden {
                    
                    }
                    .infinite {

                    }
                    .menu_container {
                        h3 {}
                        h4 {}
                        .menu2_container {
                            p{
                                line-height: 0.9rem;
                            }
                            .divide {}
                        }
                    }
                    .packs_container {
                        width: 100vw;
                        
                        .pack_bulles_or {
                            width: 33.3%;

                            h3 {
            
                                font-size: 1.1rem;
                                letter-spacing: 0.5px;
                            }
                            h4 {
                                margin: 0.5rem 0;
                            }
                            .menu2_container {
                              
                                margin: 0.2rem 0;
                                h5 {}
                                .divider_container {
                                    .divide_or {}
                                    p {}
                                }
                            }  
                        }
                    }
                    .btn_tocontact {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}

@include mediaSm {
    .offers_container {
        margin: 6rem auto 3rem auto;
        display: block;
        width: 90vw;

        .img_container{
            width: 100%;
            height: 40vh;
        }
        .card_offer_container {
            margin: 1rem 0;

            .offer_derscription_container {
               
                .offer_title {
                    
                }
                .description_container {
                    .overview {
                        .icon_container{
                            .cata_icon{}
                            p {}
                            .green {}
                            .red {}
                        }
                    }
                    .description {
                        p{}
                        .menu_container {
                            h3 {}
                            h4 {}
                            .menu2_container {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 0rem;
                                p{}
                                .divide {}
                            }
                        }
                    }
                    .btn_tocontact {
                        font-size: 0.8rem;
                    }
                }
            }
        }

        .img_container_night {
            width: 100%;
            height: 50vh;
            min-height: 25rem;
            background-position: 100% 40%;
            .img {
                background-position: 45% 50%;  
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 60%;
                    height: 100%;
                    background-color: rgba($sunset, 0.9);
                    
                }
                .concept_description_container {
                    width: 55%;
                    right: 2.5%;
                    .concept_description {
                        line-height: 1rem;
                        font-size: 0.75rem;
                        
                    }
                }
                // .noptional {
                //     transform: translateY(30%);
                // }
                // .noptional1 {
                //     transform: translateY(-5rem);
                // }
                // .optional {
                //     visibility: hidden;
                // }
               .begin {
                height: 1rem;
                right: 50%;
               }
               .end {
                height: 1rem;
                right: 0%;
               }
            }
        }
        .card_offer_container_night {
            margin: 1rem 0;
            padding: 0;
            .offer_derscription_container {
                .offer_title {
                    margin-left: 0;
                }
                .description_container {
                    .intro_night {
                        font-size: 0.8rem;
                        
                    }
                    .overview {

                        padding: 0;
                        .icon_container {
                            margin: 0;

                            .cata_icon {
                                margin-right: 1rem;
                            }
                            p {
                                line-height: 1rem;
                                margin: 0.5rem 0;
                            }
                        }
                    }
                    .description {
                        display: block;
                    }
                    .golden {
                        width: 100%;
                    }
                    .infinite {
                        width: 100%;
                    }
                    .menu_container {
                        margin-bottom: 2rem;
                        width: 100%;
                        h3 {}
                        h4 {}
                        .menu2_container {  
                            p{}
                            .divide {}
                        }
                    }
                    .packs_container {
                        display: block;
                        width: 100%;
                        
                        
                        .pack_bulles_or {
                            width: 100%;
                            margin: 0;
                            margin-bottom: 2rem;
                            h3 {
                                width: 100%;
                                margin: 1rem 0;
                            }
                            h4 {
                                margin: 1rem 0rem 0rem 0rem;
                            }
                            .menu2_container {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 0;
                                width: 100%;
                                min-width: 25rem;
                                align-items: center;
                                justify-content: flex-start;
                                h5 {
                                    margin: 0;
                                    line-height: 1rem;
                                }
                                .divider_container {
                                    width: 3.5rem;
                                    .divide_or {
                                        width: 0.5rem;
                                    }
                                    p {}
                                }
                            }  
                        }
                    }
                    .btn_tocontact {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }

}
@include mediaXs {
    .offers_container {
        margin: 4.5rem auto 3rem auto;
        display: block;
        width: 90vw;

        .img_container{
            width: 100%;
            height: 40vh;
        }
        .card_offer_container {
            margin: 1rem 0;

            .offer_derscription_container {
               
                .offer_title {
                    
                }
                .description_container {
                    .overview {
                        .icon_container{
                            .cata_icon{}
                            p {}
                            .green {}
                            .red {}
                        }
                    }
                    .description {
                        p{}
                        .menu_container {
                            h3 {}
                            h4 {}
                            .menu2_container {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 0rem;
                                p{}
                                .divide {}
                            }
                        }
                    }
                    .btn_tocontact {
                        font-size: 0.8rem;
                    }
                }
            }
        }

        .img_container_night {
            width: 100%;
            height: 50vh;
            min-height: 25rem;
            background-position: 100% 40%;
            .img {
                background-position: 42% 50%;  
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 60%;
                    height: 100%;
                    background-color: rgba($sunset, 0.9);
                    
                }
                .concept_description_container {
                    width: 55%;
                    right: 2.5%;
                    .concept_description {
                        line-height: 0.9rem;
                        font-size: 0.7rem;
                        
                    }
                }

               .begin {
                height: 1rem;
                right: 46%;
               }
               .end {
                height: 1rem;
                right: 0%;
               }
            }
        }
        .card_offer_container_night {
            margin: 1rem 0;
            padding: 0;
            .offer_derscription_container {
                .offer_title {
                    margin-left: 0;
                    font-size: 1.5rem;
                }
                .description_container {
                    .overview {

                        padding: 0;
                        .icon_container {
                            margin: 0;

                            .cata_icon {
                                margin-right: 1rem;
                            }
                            p {
                                line-height: 1rem;
                                margin: 0.5rem 0;
                                font-size: 0.75rem;
                            }
                        }
                    }
                    .menu_category_title {
                        font-size: 1.5rem;
                        width: 90vw;
                    }
                    .description {
                        display: block;
                    }
                    .golden {
                        width: 100%;
                    }
                    .infinite {
                        width: 100%;
                    }
                    .menu_container {
                        margin-bottom: 2rem;
                        width: 100%;
                        h3 {
                            font-size: 1rem;
                        }
                        h4 {
                            font-size: 0.9rem;
                        }
                        .menu2_container {  
                            p{
                                font-size: 0.75rem;
                            }
                            .divide {}
                        }
                    }
                    .packs_container {
                        display: block;
                        width: 100%;
                        
                        
                        .pack_bulles_or {
                            width: 100%;
                            margin: 0;
                            margin-bottom: 2rem;
                            h3 {
                                width: 100%;
                                margin: 1rem 0;
                                font-size: 1rem;
                            }
                            h4 {
                                margin: 1rem 0rem 0rem 0rem;
                                font-size: 0.9rem;
                            }
                            .menu2_container {
                                display: flex;
                                flex-wrap: wrap;
                                gap: 0;
                                width: 95%;
                                min-width: 10rem;
                                align-items: center;
                                justify-content: flex-start;
                                h5 {
                                    margin: 0;
                                    line-height: 1rem;
                                }
                                .divider_container {
                                    width: 3.5rem;
                                    .divide_or {
                                        width: 0.5rem;
                                    }
                                    p {}
                                }
                            }  
                        }
                    }
                    .btn_tocontact {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }

}
// .offers_container {
//     margin-top: 12rem;
//     .card_offer_container {
//         display: flex;
//         width: 80%;
//         margin: 0 auto 4rem auto;
  
//         .img_container {
//             background-image: url(../pictures/le_catamaran/day/gabriel_banc_sable.jpeg);
//             width: 40%;
//             height: auto;
//             background-size: cover;
//         }
//         .offer_derscription_container{
//             width: 60%;
            
//             .offer_title {
//                 @extend .h2_title;
//                 font-size: 2.5rem;
//                 margin-left: 1.5rem;
//                 margin-bottom: 1rem;
//                 color: $ocean;
//             }
//             .description_container {
//                 margin-left: 1.5rem;
//                 width: 90%;
//                 .overview {
//                     display: flex;
//                     flex-wrap: wrap;
//                     gap: 0.5rem;
//                     margin-bottom: 1.5rem;
//                     .icon_container {
//                         display: flex;
//                         justify-content: center;
//                         align-items: center;
//                         margin: 0 1rem;
//                         line-height: 0.4rem;

//                         .cata_icon {
//                             color: $ocean;
//                         }
//                         p {
                           
//                             margin-left: 1rem;
//                             color: set_text_color($light_text_color);
                            
//                             span {
//                                 color: rgba($primary_color, 0.5);
//                             }
//                             .green {
//                                 color: $garden_dark;
//                                 margin: 0 0.15rem;
//                             }
//                             .red {
//                                 color: $dark_red;
//                                 margin: 0 0.15rem;
//                             }
                            
//                         }
//                     }
//                 }
//                 .description {
//                     margin-bottom: 2rem
//                 }
//                 .menu_container {
//                     h3 {
//                         @extend .h3_title;
//                         color: $ocean;
//                         text-transform: capitalize;
//                     }
//                     h4 {
//                         @extend .h3_title;
//                         font-size: 1rem;
//                         color: $lagoon;
//                         margin: 0.5rem 0 0 0 ;
//                         text-transform: capitalize;
//                     }
//                     .menu2_container {
//                         display: flex;
//                         width: auto;
//                         line-height: 0.6rem;
//                         align-items: center;
//                         p {
//                             margin: 0.5rem;
                            
//                         }
//                         .divide {
//                             height: 0.7rem ;
//                             width: 2px;
//                             background-color: $ocean;
//                             margin: 0 0rem;
//                         }
//                     }

//                 }
//                 .btn_tocontact {
//                     padding: 0.4rem 1rem;
//                     border: 1px solid $ocean;
//                     text-decoration: none;
//                     font-size: 1.2rem;
//                     text-transform: uppercase;
//                     color: $ocean;
//                     letter-spacing: 2px;
//                     transition: all 0.3s ease-in-out;
//                     &:hover {
//                         cursor: pointer;
//                         background-color: $ocean;
//                         color: set_text_color($ocean);
//                     }
                    
//                 }
//             }
           
//         }
//     }

//     .night {
//         .img_container {
//             background-image: url(../pictures/le_catamaran/night/catamaran_night.jpg);
//         }
//         .offer_derscription_container {
//             .offer_title {
//                 color: $sunset;
//             }
//             .description_container {
//                 .overview {
//                     margin-bottom: 0.5rem;
//                     .icon_container {
//                         .cata_icon {
//                             color: $sunset;
//                         }
//                     }
//                 }
//                 .description {
//                     margin-bottom: 2rem;
//                     display: flex;
//                 }
//                 .golden {
//                     width: 50%;
//                     padding-right: 0.5rem;
//                 }
//                 .infinite {
//                     width: 50%;
//                 }
//                 .menu_container {
//                     h3 {
//                         color: $sunset;
//                         margin: 0;
//                         text-transform: capitalize;
//                     }
//                     h4 {
//                         color: $sunset2;
//                         text-transform: capitalize;
//                     }
//                     .menu2_container {
//                         display: flex;
//                         flex-wrap: wrap;
//                         width: auto;
//                         line-height: 0.7rem;
//                         align-items: center;
//                         p {
//                             margin: 0.5rem;
//                             font-size: 0.9rem;
//                             line-height: 0.5rem;
//                         }
//                         .divide {
//                             background-color: $sunset;
//                         }
//                     }

//                 }
//                 .packs_container {
//                     display: flex;
//                     width: 100%;
//                     margin-bottom: 2rem;
//                     .pack_bulles_or {
//                         width: 50%;
//                         h3 {
//                             @extend .h3_title;
//                             color: $sunset;
//                             margin: 0;
                            
//                         }
//                         h4 {
//                             @extend .h3_title;
//                             font-size: 1rem;
//                             color: $sunset2;
//                             margin: 1rem 0 1rem 0 ;
//                             text-transform: capitalize;
//                         }
//                         .menu2_container {
//                             width: 100%;
//                             justify-content: center;
//                             text-align: start;
//                             margin: 0.5rem 0;
                            
//                             h5 {
//                                 margin: 0.5rem;
//                                 font-size: 0.9rem;
//                                 line-height: 0.5rem;
//                                 font-weight: 400;
//                             }
//                             .divider_container {
//                                 display: flex;
//                                 text-align: center;
//                                 width: 100%;
//                                 justify-content: start;
//                                 align-items: center;
//                                 padding-left: 0.5rem;
//                                 .divide_or {
//                                     height: 1px ;
//                                     width: 2rem;
//                                     background-color: $sunset;
//                                     margin: 0 0rem;
//                                 }
//                                 p {
//                                 margin: 0.5rem;
//                                 font-size: 0.9rem;
//                                 line-height: 0.5rem;
//                                 }
//                             }
//                         }
//                     }
//                 }
                
//                 .btn_tocontact {
//                     border: 1px solid $sunset;
//                     color: $sunset;
//                     &:hover {
//                         background-color: $sunset;
//                         color: set_text_color($sunset);
//                     }
                    
//                 }
//             }
//         }
        
        
//     }
// }

// @include mediaLg {
//     .offers_container {
//         margin-top: 10rem;
//         .card_offer_container {
//             flex-wrap: wrap;
//             width: 90%;
//             align-items: left;
//             justify-content:left;
//             text-align: left;
//             .img_container {
//                 width: 100%;
//                 height: 40vh;
//                 background-position: 100% 20%;
//             }
//             .offer_derscription_container {
//                 width: 100%;
//                 margin: 0 auto;
//                 .offer_title {
//                     margin-top: 1rem;
//                     font-size: 2rem;
//                 }
//                 .description_container {
//                     margin: 0 auto;
//                     .overview {
//                         width: 100%;
//                         gap: 0.5rem;
//                         .icon_container{
//                             .cata_icon{}
//                             p {
//                                 font-size: 0.8rem;
//                             }
//                             .green {}
//                             .red {}
//                         }
//                     }
//                     .description {
//                         p{
//                             text-align: justify;
//                             font-size: 0.8rem;
//                         }
//                         .menu_container {
//                             h3 {
//                                 margin-top: 1rem;
//                                 margin-bottom: 0;
//                             }
//                             h4 {
                               
//                             }
//                             .menu2_container {
//                                 justify-content: left;
//                                 width: auto;
//                                 p{}
//                                 .divide {}
//                             }
//                         }
//                     }
//                     .btn_tocontact {
//                         font-size: 1rem;
//                     }
//                 }
//             }
//         }
//         .night {
//             position: relative;
//             .img_container {
//                 position: absolute;
//                 top: 0;
//                 left: 50%;
//                 transform: translateX(-50%);
//                 width: 90%;
//                 height: 40vh;
//             }
//             .offer_derscription_container {
//                 position: absolute;
//                 top: 40vh;
//                 .offer_title {}
//                 .description_container {
//                     .overview {
                    
//                         .icon_container {
//                             .cata_icon {}
//                         }
//                     }
//                     .description {

//                     }
//                     .golden {
//                     }
//                     .infinite {

//                     }
//                     .menu_container {
//                         h3 {
                          
//                         }
//                         h4 {}
//                         .menu2_container {
//                             p{
//                                 font-size: 0.8rem;
                                
//                             }
//                             .divide {}
//                         }
//                     }
//                     .packs_container {
                    
//                         .pack_bulles_or {
                           
//                             h3 {
                                
//                                 margin-left: 0.1rem;
//                             }
//                             h4 {}
//                             .menu2_container {
//                                 text-align: start;
//                                 h5 {
//                                     font-size: 0.8rem;
//                                 }
//                                 .divider_container {
//                                     justify-content: left;


//                                     .divide_or {}
//                                     h5 {
//                                         margin: 0 0.5rem;
                                        
//                                     }
//                                 }
//                             }  
//                         }
//                     }
//                     .btn_tocontact {}
//                 }
//             }
//         }
//     }
// }

// @include mediaMd {
//     .offers_container {
//         margin-top: 6rem;
//         .card_offer_container {
//             .img_container {}
//             .offer_derscription_container {
//                 .offer_title {}
//                 .description_container {
//                     .overview {
//                         .icon_container{
//                             .cata_icon{}
//                             p {}
//                             .green {}
//                             .red {}
//                         }
//                     }
//                     .description {
//                         p{}
//                         .menu_container {
//                             h3 {}
//                             h4 {}
//                             .menu2_container {
//                                 display: flex;
//                                 flex-wrap: wrap;
//                                 gap: 0rem;
//                                 p{
                                  
//                                 }
//                                 .divide {}
//                             }
//                         }
//                     }
//                     .btn_tocontact {
//                         font-size: 0.9rem;
//                     }
//                 }
//             }
//         }
//         .night {
//             .img_container {}
//             .offer_derscription_container {
//                 .offer_title {}
//                 .description_container {
//                     .overview {
//                         .icon_container {
//                             .cata_icon {}
//                         }
//                     }
//                     .description {}
//                     .golden {
                   
//                     }
//                     .infinite {

//                     }
//                     .menu_container {
//                         h3 {}
//                         h4 {}
//                         .menu2_container {
//                             p{
//                                 line-height: 0.9rem;
//                             }
//                             .divide {}
//                         }
//                     }
//                     .packs_container {
//                         .pack_bulles_or {
//                             h3 {}
//                             h4 {}
//                             .menu2_container {
//                                 h5 {}
//                                 .divider_container {
//                                     .divide_or {}
//                                     p {}
//                                 }
//                             }  
//                         }
//                     }
//                     .btn_tocontact {}
//                 }
//             }
//         }
//     }
// }

// @include mediaSm {
//     .offers_container {
//         margin-top: 5rem;
//         .card_offer_container {
//             align-items: left;
//             justify-content:left;
//             text-align: left;
//             .img_container {}
//             .offer_derscription_container {
               
//                 .offer_title {
                    
//                 }
//                 .description_container {
//                     .overview {
//                         .icon_container{
//                             .cata_icon{}
//                             p {}
//                             .green {}
//                             .red {}
//                         }
//                     }
//                     .description {
//                         p{}
//                         .menu_container {
//                             h3 {}
//                             h4 {}
//                             .menu2_container {
//                                 display: flex;
//                                 flex-wrap: wrap;
//                                 gap: 0rem;
//                                 p{}
//                                 .divide {}
//                             }
//                         }
//                         .btn_tocontact {}
//                     }
//                 }
//             }
//         }
//         .night {
//             .img_container {}
//             .offer_derscription_container {
//                 .offer_title {}
//                 .description_container {
//                     .overview {
//                         .icon_container {
//                             .cata_icon {}
//                         }
//                     }
//                     .description {
//                         display: block;
//                     }
//                     .golden {
//                         width: 100%;
//                     }
//                     .infinite {
//                         width: 100%;
//                     }
//                     .menu_container {
//                         width: 100%;
//                         h3 {}
//                         h4 {}
//                         .menu2_container {  
//                             p{}
//                             .divide {}
//                         }
//                     }
//                     .packs_container {
//                         display: block;
//                         width: 100%;
                        
//                         .pack_bulles_or {
//                             width: 100%;
//                             margin: 0;
//                             h3 {
//                                 width: 100%;
//                                 margin: 1rem 0;
//                             }
//                             h4 {
//                                 margin: 1rem 0rem 0rem 0rem;
//                             }
//                             .menu2_container {
//                                 display: flex;
//                                 flex-wrap: wrap;
//                                 gap: 0;
//                                 width: 100%;
//                                 min-width: 25rem;
//                                 align-items: center;
//                                 justify-content: start;
//                                 h5 {
//                                     margin: 0;
//                                     line-height: 1rem;
//                                 }
//                                 .divider_container {
//                                     width: 3.5rem;
//                                     .divide_or {
//                                         width: 0.5rem;
//                                     }
//                                     p {}
//                                 }
//                             }  
//                         }
//                     }
//                     .btn_tocontact {}
//                 }
//             }
//         }
//     }
// }