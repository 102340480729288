.page_container {
    position: relative;
    width: 100vw;
    height: 100vh;

    .home_page_title {
        position: absolute;
        z-index: 15;
        color: white;
        font-size: 3rem;
        @extend .h1_title;
        top: 80%;
    }

    .split {
        position: absolute;
        width: 33.34%;
        height: 100%;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: all 1000ms ease-in-out;

        img {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            height: auto;
            width: 20rem;
            z-index: 10;
           
        }
        p {
            position: absolute;
            top: 80%;
            left: 50%;
            transform: translateX(-50%);
            color: white;
            font-style: italic;
            width: 100%;
            max-width: none;
            margin: 0;
            font-size: 0.9rem;
        }
        .btn_home {
            position: absolute;
            top: 70%;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: white;
            border: white solid 0.2rem;
            font-size: 1rem;
            letter-spacing: 1px;
            font-weight: bold;
            text-transform: uppercase;
            width: 15rem;
            padding: 1.5rem;

        }
    }

    .left {
        left: 0;
        background: url(../pictures/lile_plate/vue.jpg);
        background-repeat: no-repeat;
        background-position: 30% 100%;
        background-size:  auto 100%;
        transition: all 1000ms ease-in-out;
        @extend .black_veil_04;
        img {
            top: 20%;
            
        }
        .btn_home{
            &:hover {
                background-color: $lagoon;
                border-color: $lagoon;
                transition: all 0.4s ease;
            }
        }
    }
    
    .center {
        left: 33.33%;
       
        background: url(../pictures/le_jardin/shooting/entree_portrait_cut.jpg);
        background-repeat: no-repeat;
       
        background-size: auto 100%;
        background-position: 45%;
        transition: all 1000ms ease-in-out;
        @extend .black_veil_04;
        img {
            top: 42%;
            height: auto;
            width: 20rem;
        }
        .btn_home{
            &:hover {
                background-color: $garden_dark;
                border-color: $garden_dark;
                transition: all 0.4s ease;
            }
        }
    }
    .right {
        right: 0;
        background: url(../pictures/la_plage/la_plage.jpg);
        background-repeat: no-repeat;
        background-position: 70%;
        background-size: auto 100%;
        transition: all 1000ms ease-in-out;
        @extend .black_veil_04;
        img {
            top: 15%;
            height: auto;
            width: 20rem;
        }
        .btn_home{
            &:hover {
                background-color: $sand;
                border-color: $sand;
                transition: all 0.4s ease;
            }
        }
    }
    
    h1 {
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translateX(-50%);
        white-space: nowrap;
        font-size: 4rem;
        color: set_text_color(rgba($primary_color,0.5));
        text-shadow: $light_text_shadow;
    }
    h2 {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translateX(-50%);
        white-space: nowrap;
        font-size: 3rem;
        font-weight: 400;
        color: set_text_color(rgba($primary_color,0.5));
        color: $light_text_color;
    }
  
    

}

// HOVER LEFT
.hover-left .left {
    width: 35%;
    z-index: 10;
    background-position: 32% 100%;
}

// HOVER CENTER
.hover-center .center {
    left: 32.5%;
    width: 35%;
    background-position: 40%;
    z-index: 10;
}

// HOVER RIGHT
.hover-right .right {
    
    width: 35%;
    z-index: 10;
    background-position: 75%;
}


// MEDIA
@include mediaXl {
    .page_container{
        .home_page_title {
            font-size: 2.5rem;
            top: 80%;
        }
        .split {
            img {
                width: 16rem;
            }
            .btn_home {
                font-size: 0.9rem;
                width: 12rem;
                padding: 1rem;
            }
        }
        .left {
            img {
                top: 20%;
            }
        }
        .center {
            img {
                top: 44.15%;
            }
        }
        .right {
            img {
                top: 20%;
            }
        }
        h1 {
        }
        h2 {
        }
        .btn_home { 
        }
    }
}
@include mediaLg {
    .page_container{
        .home_page_title {
            font-size: 2rem;
            top: 80%;
        }
        
        
        .split {
            position: absolute;
            width: 100%;
            height: 33.5vh;
            p {
                top: 90%;
            }
            img {
                width: 9rem;
            }
            .btn_home {
                font-size: 0.7rem;
                padding: 0.5rem;
                width: 6rem;
                top: 78%;
                border: white solid 0.1rem;
            }
        }
        .left {
            top: 0;
            left: 0;
            background-size: 100% auto;
            background-position: 30% 55%;
            img {
                top: 5%;
                width: auto;
                height: 70%;
            }
        }
        .center {
            top: 33.33vh;
            left: 0;
            background-size: 100% auto;
        
            img {
                width: auto;
                height: 50%;
                top: 15%;
            }
        }
        .right {
            top: 66.66vh;
            left: 0;
            background-size: 100% auto;
            background-position: 50% 70%;
            img {
                top: 5%;
                width: auto;
                height: 70%;
            }
        }
        h1 {
            top: 13%;
            font-size: 2.4rem;
        }
        h2 {
            font-size: 1.7rem;
        }
        .btn_home { 
            top: 55%;
            border: $light_text_color solid 0.15rem;
            font-size: 0.9rem;
            width: auto;
            padding: 1rem;
        }
    }
}

@include mediaMd {
    .page_container{
        .split {
            position: absolute;
            width: 100%;
            height: 33.5vh;
        }
        .left {
            top: 0;
            left: 0;
            background-size: 100% auto;
            img {
                top: 5%;
                width: auto;
                height: 70%;
            }
            
        }
        .center {
            top: 33.33vh;
            left: 0;
            background-size: 100% auto;
            img {
                width: auto;
                height: 50%;
                top: 15%;
            }
        }
        .right {
            top: 66.66vh;
            left: 0;
            background-size: 100% auto;
            img {
                top: 5%;
                width: auto;
                height: 70%;
            }
        }
        h1 {
            top: 10%;
            font-size: 2.5rem;

        }
        h2 {
        
        }
        .btn_home { 
            border: $light_text_color solid 0.15rem;
            font-size: 0.9rem;
            width: auto;
            padding: 1rem;
            top: 60%;
        }
    }
}

@include mediaSm {
    .page_container{
        .split {
            position: absolute;
            width: 100%;
            height: 33.4vh;
            p {
                top: 92%;
                font-size: 0.7rem;
            }
            .btn_home { 
            
                border: $light_text_color solid 0.15rem;
                font-size: 0.6rem;
                
                width: auto;
                padding: 0.4rem;
                top: 77%;
            }
        }
        .left {
            top: 0;
            left: 0;
            background-size: cover;
            img {
                width: auto;
                height: 70%;
                top: 3%;
            }
            
        }
        .center {
            top: 33.33vh;
            left: 0;
            background-size: cover;
            img {
                top: 20%;
                width: auto;
                height: 50%;
            }
        }
        .right {
            top: 66.66666vh;
            left: 0;
            background-size: cover;
            img {
                width: auto;
                height: 70%;
                top: 3%;
            }
        }
        h1 {
            top: 10%;
            font-size: 2.2rem;

        }
        h2 {
            font-size: 1.7rem;
        }
        
    }
}
