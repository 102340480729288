.construction_bg {
    position: absolute;
    top: 6rem;
    left: 0;
    height: 89vh;
    width: 100vw;
}
.construction_title1 {
    word-spacing: 4px;
    min-width: 35rem;
    font-weight: 400;
    text-transform: uppercase;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    font-size: 2rem;
}

.roller_img {
    position: absolute;
    top: 40%;
    left: 50%;
    height: 30%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0.7;
}

@include mediaLg {
    .construction_title1{
        font-size: 1.5rem;
        min-width: 30rem;
       
    }
}
@include mediaMd {
    .construction_title1{
        font-size: 1.5rem;
        min-width: 30rem;
       
    }
}

@include mediaSm {
    .construction_bg {
        top: 3rem;
        width: 100vw;
        height: 100vh;

    }
    .construction_title1{
        font-size: 1rem;
        min-width: 20rem;
       
    }
}
@include mediaXs {
    .construction_bg {
        top: 3rem;
        width: 100vw;
        height: 100vh;

    }
    .construction_title1{
        font-size: 0.8rem;
        min-width:16rem;
   
       
    }
}