.gallery_header {
    width: 100vw;
    height: 40vh;
    top: 0;
    left: 0;
    right: 0;
    background-color: $primary_color;
    background-size: cover;
    background-position: 100% 80%;
    h2 {
        position: absolute;
        top: 50%;
        left: 50%;
    }
    
}
.home_logo_container {
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    position: relative;
    
    
    .home_logo {
        position: absolute;
        margin: auto auto;
        max-width: 35%;
        max-height: 50%;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.hero {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 100vh;
    width: 100vw;

    z-index: -2;
    position: fixed;
    top: 0;
    left: 0; 
}
.fake_scrolling {
    background-color: transparent;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative; 
}
.landing_logo {
    width: 35%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.fixed_bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;

}
.black_veil_03 {
    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(	$primary_color, 0.3);
        transition: all 1000ms ease-in-out;
    }
}
.black_veil_04 {
    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(	$primary_color, 0.4);
        transition: all 1000ms ease-in-out;
    }
}
.black_veil_05 {
    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(	$primary_color, 0.5);
        transition: all 1000ms ease-in-out;
    }
}
.black_veil_07 {
    &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(	$primary_color, 0.7);
        transition: all 1000ms ease-in-out;
    }
}

.margin_tb {
    margin: 5rem 0;
}
.bold_text {
    font-weight: 500;
    color: black;
}
.hero_half {
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 45vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: -10;
    position: relative;
}
.hero {
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: -10;
    position: relative;
}
.transparent {
    opacity: 0;
}



// COLORS
.lejardin_color1 {
    color: $garden_dark;
}
.lejardin_color2 {
    color: $garden_light;
}
.laplage_color1 {
    color: $dark_sand;
}
.laplage_color2 {
    color: $sand;
}
.lecata_color1 {
    color: $ocean;
}
.lecata_color2 {
    color: $lagoon;
}
// BG COLOR
.lejardin_bg_color1 {
    background-color: $garden_dark;
    &:disabled {
        background-color: $dark_grey;
    }
}
.lejardin_bg_color2 {
    background-color: $garden_light;
    &:disabled {
        background-color: $dark_grey;
    }
}
.lariviere_bg_color1 {
    background-color: $riviere_dark;
    &:disabled {
        background-color: $dark_grey;
    }
}
.laplage_bg_color1 {
    background-color: $dark_sand;
    &:disabled {
        background-color: $dark_grey;
    }
}
.laplage_bg_color2 {
    background-color: $sand;
    &:disabled {
        background-color: $dark_grey;
    }
}
.lecata_bg_color1 {
    background-color: $ocean;
    &:disabled {
        background-color: $dark_grey;
    }
}
.lecata_bg_color2 {
    background-color: $lagoon;
    &:disabled {
        background-color: $dark_grey;
    }
}

// BORDERS
.light_border {
    border: 1px solid rgba($primary_color, 0.1);
}
.lejardin_border1 {
    border: 1px solid $garden_dark;
}
.lejardin_border2 {
    border: 1px solid $garden_light;
}
.lariviere_border1 {
    border: 1px solid $riviere_dark;
}
.lariviere_border2 {
    border: 1px solid $riviere_light;
}
.laplage_border1 {
    border: 1px solid $dark_sand;
}
.laplage_border2 {
    border: 1px solid $sand;
}
.lecata_border1 {
    border: 1px solid $ocean;
}
.lecata_border2 {
    border: 1px solid $lagoon;
}
//FOCUS
.lejardin_border_focus1 {
    background-color: $light_text_color;
    color: set_text_color($light_text_color);
    border: 1px solid rgba($primary_color, 0.1);
    &:focus {
        outline: none !important;
        border: 1px solid $garden_dark;  
    }
}
.lejardin_border_focus2 {
    background-color: $light_text_color;
    color: set_text_color($light_text_color);
    border: 1px solid rgba($primary_color, 0.1);
    &:focus {
        outline: none !important;
        border: 1px solid $garden_light;  
    }
}
.lariviere_border_focus1 {
    background-color: $light_text_color;
    color: set_text_color($light_text_color);
    border: 1px solid rgba($primary_color, 0.1);
    &:focus {
        border: 1px solid $riviere_dark;  
        outline: none !important;
        
    }
}
.laplage_border_focus1 {
    background-color: $light_text_color;
    color: set_text_color($light_text_color);
    border: 1px solid rgba($primary_color, 0.1);
    &:focus {
        border: 1px solid $dark_sand;  
        outline: none !important;
        
    }
}
.laplage_border_focus2 {
    background-color: $light_text_color;
    color: set_text_color($light_text_color);
    border: 1px solid rgba($primary_color, 0.1);
    &:focus {
        outline: none !important;
        border: 1px solid $sand;  
    }
}
.lecata_border_focus1 {
    background-color: $light_text_color;
    color: set_text_color($light_text_color);
    border: 1px solid rgba($primary_color, 0.1);
    &:focus {
        outline: none !important;
        border: 1px solid $ocean;  
    }
}
.lecata_border_focus2 {
    background-color: $light_text_color;
    color: set_text_color($light_text_color);
    border: 1px solid rgba($primary_color, 0.1);
    
    &:focus {
        outline: none !important;
        border: 1px solid $lagoon;  
    }
}

//HOVER
.lejardin_hover1 {
    color: set_text_color($primary_color);
    &:hover {
        background-color: $light_text_color;
        border: 1px solid $garden_dark;
        color: $garden_dark;
        cursor: pointer;
        &:disabled {
            cursor: not-allowed;
        }
    }
}
.lejardin_hover2 {
    &:hover {
        color: $garden_dark;
    }
}
.lariviere_hover1 {
    color: set_text_color($primary_color);
    &:hover {
        background-color: $light_text_color;
        border: 1px solid $riviere_dark;
        color: $riviere_dark;
        cursor: pointer;
        &:disabled {
            cursor: not-allowed;
        }
    }
}
.laplage_hover1 {
    color: set_text_color($primary_color);
    &:hover {
        background-color: $light_text_color;
        border: 1px solid $dark_sand;
        color: $dark_sand; 
        cursor: pointer;
        &:disabled {
            cursor: not-allowed;
        }
    }
}
.laplage_hover2 {
    &:hover {
        color: $sand; 
    }
}
.lecata_hover1 {
    color: set_text_color($primary_color);
    &:hover {
        background-color: $light_text_color;
        border: 1px solid $ocean;
        color: $ocean; 
        cursor: pointer;
        &:disabled {
            cursor: not-allowed;
        }
    }
}
.lecata_hover2 {
    &:hover {
        color: $lagoon; 
    }
}

@include mediaLg {
    .hero_half  {
        height: 40vh;
    }
}

@include mediaSm {
    .home_logo_container {
        .home_logo {
            max-width: 70vw;
            max-height: 70vh;
        }
    }
    .landing_logo {
        width: 70vw;
    }
}