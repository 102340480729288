.navbar2 {
    height: 6rem;
    width: 100vw;
    background-color: $primary_color;
    color: set_text_color($primary_color);
    display: flex;
    position: relative;
    align-items: center;

    .logo_link {
        height: 100%;
        width: auto;
        position: absolute;
        left: 10rem;
        top: 0;
    }
    .navbar_container {
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        display: flex;
        height: 100%;

        align-items: center;
        justify-content: center;
        text-align: center;

        }
        .navbar_link {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content:flex-start;
            position: relative;
            .navlink {
                margin: 0 2rem;
                color: set_text_color($primary_color);
                text-decoration: none;
                width: 9rem;
                font-size: 1.3rem;
                @include spacing;
                text-transform: capitalize;
                text-underline-offset: none;
                
                padding: 0.8rem 1.5rem;
                
                &:hover {
                    cursor: pointer;
                    background-color: $secondary_color;
                }
            }
            .open {
                background-color: $secondary_color; 
            }
        }
    }


// DROPDOWN
.submenu_container {   
    position: absolute;
    top: 6rem;
    right: 1rem;
    width: 13rem;
    list-style: none;
    text-align: flex-start;
    background-color: $primary_color;
    .submenu_item_li {
        list-style: none;
        padding: 0 1rem;
        &:hover {
            background-color: darken($secondary_color,5);
            .navlink {
                background-color: $secondary_color;
            }
        }
        .submenu_item {
            display: block;
       
            height: 100%;
            text-decoration: none;
            color: set_text_color($primary_color);
            padding: 01rem 0;
            cursor: pointer;
        }
    }
}
.clicked {
    display: none;
}