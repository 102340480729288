.testhover{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 5rem;
}
.cata_title_container {
    height: 100vh;
    width: 100vw; 
    text-align: center;
  
    
}
.cata_title {
    @extend .h2_title;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 3rem;
    color: set_text_color(rgba($primary_color,0.5));
    text-shadow: $text-shadow2_06;
    word-spacing: 10px;
    letter-spacing: 2px
}
.cata_subtitle {
    font-weight: 400;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    color: set_text_color(rgba($primary_color,0.5));
    text-shadow: $text-shadow2_06;
    word-spacing: 10px;
   font-size: 2rem;
   text-align: center;
   width: 54rem;
   

}
.cata_comingsoon {
    font-weight: 450;
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 1.2rem;
    transform: translateX(-50%);
    color: set_text_color(rgba($primary_color,0.5));
    text-shadow: $text-shadow2_06;
    word-spacing: 10px;
    letter-spacing: 1px;
 }
 .btn_cata1 {
    position: absolute;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 60%;
    transform: translateX(-50%);
    text-decoration: none;
    color: set_text_color(rgba($primary_color,0.5));
    background-color: $ocean;
    border-color: $ocean;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 1.5rem;
    letter-spacing: 1.5px;
    word-spacing: 3px;
    width: 15rem;
    text-align: center;
    transition: all 0.4s ease;
    z-index: 10;
    @include fastEaseOut;
    &:hover {
        scale: 1.02;
    }
}
.fixed_bg_cata {
    @extend .fixed_bg; 
    background-image: url(../pictures/le_catamaran/night/sunset1.jpg);
    background-size: cover;
    background-position: 50% 0%;
    @extend .black_veil_04;
    z-index: -1;
   
   
}

.half {
    position: absolute;
    width: 50vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 1000ms ease-in-out;

    
}
.left_side {
    left: 0;
    background-image: url(../pictures/le_catamaran/night/sunset1.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size:  cover;
    transition: all 1000ms ease-in-out;
    @extend .black_veil_04;

    .btn2 {
        &:hover {
            background-color: $secondary_color;
            border-color: $secondary_color;
            transition: all 0.4s ease;
        }
    }
}


// HOVER LEFT
.hover_left_side .left_side {
    width: 53%;
}

// HOVER RIGHT
.hover_right_side .right_side {
    width: 53%;
    
}

@include mediaXl {
    .cata_title_container {
        .cata_title {
            
        }
        .cata_subtitle {
            font-size: 1.7rem;
            width: 46.5rem;

            margin: 0 auto;
            top: 45%;
        }
        .cata_comingsoon{
          
        }
        .btn_cata1 {
            
        }
    }
}
@include mediaLg {
    .cata_title_container {
        .cata_title {
            font-size: 2.5rem;
        }
        .cata_subtitle {
            font-size: 1.5rem;
            width: 41.5rem;
            
            margin: 0 auto;
            top: 43%;
        }
        .cata_comingsoon{
            top: 47%;
            font-size: 1.1rem;
        }
        .btn_cata1 {
            font-size: 0.9rem;
            font-weight: 500;
            top: 60%;
            
        }
    }
   
}

@include mediaMd {
    .cata_title_container {
        .cata_title {
            top: 25%;
        }
        .cata_subtitle {
            font-size: 1.3rem;
            width: 36.5rem;
            
            margin: 0 auto;
            top: 40%;
        }
        .cata_comingsoon{
            font-size: 1rem;
        }
        .btn_cata1 {
            width: 13rem;
            top: 50%;
            
        }
    }
}
@include mediaSm {
    .cata_title_container {
        width: 100vw;
        height: 100vh;
        .cata_title {
            font-size: 1.7rem;
            max-width: 95vw;
            top: 20%;
        }
        .cata_subtitle {
            font-size: 1rem;
           
            max-width: 99vw;
            margin: 0 auto;
            top: 28%;
        }
        .cata_comingsoon{
            font-size: 0.7rem;
            width: 15.5rem;
            white-space: nowrap;
            top: 50%;
        }
        .btn_cata1 {
            padding: 1.2rem;
            white-space: nowrap;
            width: 13rem;
            top: 40%;
           
        }
    }
}
@include mediaXs {
   
    .cata_title_container {
        width: 100vw;
        .cata_title {
            font-size: 1.5rem;
            max-width: 95vw;
            top: 15%;
        }
        .cata_subtitle {
            font-size: 0.9rem;
            max-width: 95vw;
            
            margin: 0 auto;
            top: 25%;
        }
        .cata_comingsoon{
            font-size: 0.7rem;
            width: 15.5rem;
            white-space: nowrap;
            top: 45%;
        }
        .btn_cata1 {
            padding: 1rem;
            white-space: nowrap;
            width: 10rem;
            font-size: 0.8rem;
            top: 40%;
        }
    }
}