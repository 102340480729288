.btn {
    
}
.btn1 {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 60%;
    transform: translateX(-50%);
    text-decoration: none;
    color: set_text_color(rgba($primary_color,0.5));
    border: set_text_color(rgba($primary_color,0.5)) solid 0.2rem;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    width: 15rem;
    padding: 1.5rem;
}
.btn2 {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 60%;
    transform: translateX(-50%);
    text-decoration: none;
    color: set_text_color(rgba($primary_color,0.5));
    border: set_text_color(rgba($primary_color,0.5)) solid 0.2rem;
    word-spacing: 3px;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    width: 15rem;
    padding: 1.5rem;
}

.divider {
    width: 20%;
    height: 1px;
    margin-bottom: 1rem;
}

@include mediaLg {
    .btn2 {
        width: auto;
        padding: 1rem 1.2rem;
        font-size: 1rem;
    } 
}

@include mediaMd {
    .btn2 {
        top: 70%;
        padding: 1rem 1.5rem;
    }
}

@include mediaSm {
    .btn2 {
        top: 70%;
        padding: 1rem 1.5rem;
    }
}