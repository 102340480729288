.autoswipe_container{
    height: 100%;
  
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .swiper {
        width: 100%;
        height: 100%;
      }
      
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: transparent;
        border: none;
        height: 100%;
        width: 100%;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }
      
      .swiper-slide img {
        max-width: 100%;
        height: 100%;
        vertical-align: top;
        border-style: none;
        background-position: center;

      }
}


.swiper-button-next {
    color: rgba($primary_color, 0.7);
}
.swiper-button-prev {
    color: rgba($primary_color, 0.7);
}
.swiper-pagination-bullet-active {
    background-color: rgba($primary_color, 0.7);
}

@include mediaLg {
    
}