@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@200;300;500;600&family=Poppins:wght@300;400;500;600;700&family=Titillium+Web:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Georgian:wght@500;600&family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@100;300;400;500;600;700&family=Titillium+Web:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400&family=Noto+Serif+Georgian:wght@500;600&family=Poppins:wght@300;400;500;600;700&family=Raleway:wght@100;300;400;500;600;700&family=Titillium+Web:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,400&family=Noto+Serif+Georgian:wght@500;600&family=Poppins:wght@100;200;600;700&family=Raleway:wght@100;300;400;500;600;700&family=Titillium+Web:wght@300;400;700&display=swap");
.info_title, .language_container .nav_language_title, .footer_language_container .footer_language_title, .language_container_home .nav_language_title {
  font-weight: 300;
  font-size: 0.8rem;
  text-transform: capitalize;
  margin-right: 1rem;
}

.h1_title, .page_container .home_page_title {
  letter-spacing: 3px;
  font-size: 6rem;
  font-family: "Noto Serif Georgian", serif;
  font-weight: 400;
  text-transform: uppercase;
}

.h2_title, .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_category_title, .offers_container .card_offer_container_night .offer_derscription_container .offer_title, .cata_title, .content3 h2, .home_scrolling .home_reservation_container .contact_h2 {
  font-family: "Noto Serif Georgian", serif;
  font-size: 3.4rem;
  font-weight: 400;
  text-transform: uppercase;
}

.h3_title, .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h4, .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h3, .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container h4, .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container h3, .jardin_grid_container .image_batch_container .batch_title, .jardin_grid_container .grid_jardin_left_side .title {
  font-family: "Noto Serif Georgian", serif;
  font-size: 1.5rem;
  font-weight: 400;
}

.h3_title_upper, .scroll_container .plage_comingsoon, .contact_post1 .left_container .text_container h3 {
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Noto Serif Georgian", serif;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0.08em;
}

.h3_title_bold, .contact_phone_laplage, .contact_phone_lecata, .contact_post1 .left_container .text_container .contact_phone {
  font-family: "Noto Serif Georgian", serif;
  font-weight: 600;
  font-size: 1.2rem;
}

.page_header {
  position: absolute;
  top: 25vh;
  left: 0;
  width: 100%;
  text-align: center;
}

.page_header_title {
  color: #fff;
  font-size: 3.5rem;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 3rem;
  font-family: "Noto Serif Georgian", serif;
  text-transform: capitalize;
}

@media screen and (max-width: 1170px) {
  .h1_title, .page_container .home_page_title {
    font-size: 3rem;
  }
  .page_header {
    top: 20vh;
  }
  .page_header_title {
    font-size: 3rem;
  }
  .h3_title, .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h4, .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h3, .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container h4, .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container h3, .jardin_grid_container .image_batch_container .batch_title, .jardin_grid_container .grid_jardin_left_side .title {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .h1_title, .page_container .home_page_title {
    font-size: 2rem;
  }
  .h3_title_upper, .scroll_container .plage_comingsoon, .contact_post1 .left_container .text_container h3 {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 649px) {
  .page_header_title {
    font-size: 1.5rem;
  }
  .h1_title, .page_container .home_page_title {
    font-size: 1.5rem;
  }
  .h3_title_upper, .scroll_container .plage_comingsoon, .contact_post1 .left_container .text_container h3 {
    font-size: 1rem;
  }
  .h3_title_bold, .contact_phone_laplage, .contact_phone_lecata, .contact_post1 .left_container .text_container .contact_phone {
    font-size: 1rem;
  }
}
.btn1 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 60%;
  transform: translateX(-50%);
  text-decoration: none;
  color: #fff;
  border: #fff solid 0.2rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  width: 15rem;
  padding: 1.5rem;
}

.btn2 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 60%;
  transform: translateX(-50%);
  text-decoration: none;
  color: #fff;
  border: #fff solid 0.2rem;
  word-spacing: 3px;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  width: 15rem;
  padding: 1.5rem;
}

.divider, .divider_riviere, .divider_laplage, .divider_lecata, .contact_else .divider_lejardin, .contact_post2 .text_container .divider_lejardin, .contact_post2_cata .text_container .divider_lejardin {
  width: 20%;
  height: 1px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 1170px) {
  .btn2 {
    width: auto;
    padding: 1rem 1.2rem;
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .btn2 {
    top: 70%;
    padding: 1rem 1.5rem;
  }
}
@media screen and (max-width: 649px) {
  .btn2 {
    top: 70%;
    padding: 1rem 1.5rem;
  }
}
.gallery_header {
  width: 100vw;
  height: 40vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  background-size: cover;
  background-position: 100% 80%;
}
.gallery_header h2 {
  position: absolute;
  top: 50%;
  left: 50%;
}

.home_logo_container {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: relative;
}
.home_logo_container .home_logo {
  position: absolute;
  margin: auto auto;
  max-width: 35%;
  max-height: 50%;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  height: 100vh;
  width: 100vw;
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;
}

.fake_scrolling {
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.landing_logo {
  width: 35%;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fixed_bg, .fixed_bg_ile_plate, .fixed_bg_cata, .scroll_container .fixed_bg_jardin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.black_veil_03:before, .contact_else .contact_else_container .contact_riviere:before, .contact_else .contact_else_container .contact_jardin:before, .contact_else .contact_else_container .contact_plage:before, .contact_else .contact_else_container .contact_cata:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 1000ms ease-in-out;
}

.black_veil_04:before, .fixed_bg_riviere:before, .hero_half_contact_ile_plate:before, .fixed_bg_ile_plate:before, .hero_half_contact_plage:before, .scroll_container .fixed_bg_plage:before, .hero_half_contact_cata:before, .left_side:before, .fixed_bg_cata:before, .hero_half_contact_jardin:before, .scroll_container .fixed_bg_jardin:before, .page_container .right:before, .page_container .center:before, .page_container .left:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 1000ms ease-in-out;
}

.black_veil_05:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 1000ms ease-in-out;
}

.black_veil_07:before, .contact_la_riviere_bg:before, .contact_ile_plate_bg:before, .contact_plage_bg:before, .contact_jardin_bg:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 1000ms ease-in-out;
}

.margin_tb {
  margin: 5rem 0;
}

.bold_text {
  font-weight: 500;
  color: black;
}

.hero_half, .hero_half_contact_ile_plate, .hero_half_contact_plage, .hero_half_contact_cata, .hero_half_contact_jardin {
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 45vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: -10;
  position: relative;
}

.hero {
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: -10;
  position: relative;
}

.transparent {
  opacity: 0;
}

.lejardin_color1 {
  color: #678D58;
}

.lejardin_color2 {
  color: #A6C48A;
}

.laplage_color1 {
  color: #CDAA6D;
}

.laplage_color2 {
  color: #f6d7b0;
}

.lecata_color1 {
  color: #008dc7;
}

.lecata_color2 {
  color: #66CCCC;
}

.lejardin_bg_color1 {
  background-color: #678D58;
}
.lejardin_bg_color1:disabled {
  background-color: rgba(255, 255, 255, 0.5);
}

.lejardin_bg_color2 {
  background-color: #A6C48A;
}
.lejardin_bg_color2:disabled {
  background-color: rgba(255, 255, 255, 0.5);
}

.lariviere_bg_color1 {
  background-color: #48434c;
}
.lariviere_bg_color1:disabled {
  background-color: rgba(255, 255, 255, 0.5);
}

.laplage_bg_color1 {
  background-color: #CDAA6D;
}
.laplage_bg_color1:disabled {
  background-color: rgba(255, 255, 255, 0.5);
}

.laplage_bg_color2 {
  background-color: #f6d7b0;
}
.laplage_bg_color2:disabled {
  background-color: rgba(255, 255, 255, 0.5);
}

.lecata_bg_color1 {
  background-color: #008dc7;
}
.lecata_bg_color1:disabled {
  background-color: rgba(255, 255, 255, 0.5);
}

.lecata_bg_color2 {
  background-color: #66CCCC;
}
.lecata_bg_color2:disabled {
  background-color: rgba(255, 255, 255, 0.5);
}

.light_border {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.lejardin_border1 {
  border: 1px solid #678D58;
}

.lejardin_border2 {
  border: 1px solid #A6C48A;
}

.lariviere_border1 {
  border: 1px solid #48434c;
}

.lariviere_border2 {
  border: 1px solid #b7b9c1;
}

.laplage_border1 {
  border: 1px solid #CDAA6D;
}

.laplage_border2 {
  border: 1px solid #f6d7b0;
}

.lecata_border1 {
  border: 1px solid #008dc7;
}

.lecata_border2 {
  border: 1px solid #66CCCC;
}

.lejardin_border_focus1 {
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.lejardin_border_focus1:focus {
  outline: none !important;
  border: 1px solid #678D58;
}

.lejardin_border_focus2 {
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.lejardin_border_focus2:focus {
  outline: none !important;
  border: 1px solid #A6C48A;
}

.lariviere_border_focus1 {
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.lariviere_border_focus1:focus {
  border: 1px solid #48434c;
  outline: none !important;
}

.laplage_border_focus1 {
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.laplage_border_focus1:focus {
  border: 1px solid #CDAA6D;
  outline: none !important;
}

.laplage_border_focus2 {
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.laplage_border_focus2:focus {
  outline: none !important;
  border: 1px solid #f6d7b0;
}

.lecata_border_focus1 {
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.lecata_border_focus1:focus {
  outline: none !important;
  border: 1px solid #008dc7;
}

.lecata_border_focus2 {
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.lecata_border_focus2:focus {
  outline: none !important;
  border: 1px solid #66CCCC;
}

.lejardin_hover1 {
  color: #fff;
}
.lejardin_hover1:hover {
  background-color: #fff;
  border: 1px solid #678D58;
  color: #678D58;
  cursor: pointer;
}
.lejardin_hover1:hover:disabled {
  cursor: not-allowed;
}

.lejardin_hover2:hover {
  color: #678D58;
}

.lariviere_hover1 {
  color: #fff;
}
.lariviere_hover1:hover {
  background-color: #fff;
  border: 1px solid #48434c;
  color: #48434c;
  cursor: pointer;
}
.lariviere_hover1:hover:disabled {
  cursor: not-allowed;
}

.laplage_hover1 {
  color: #fff;
}
.laplage_hover1:hover {
  background-color: #fff;
  border: 1px solid #CDAA6D;
  color: #CDAA6D;
  cursor: pointer;
}
.laplage_hover1:hover:disabled {
  cursor: not-allowed;
}

.laplage_hover2:hover {
  color: #f6d7b0;
}

.lecata_hover1 {
  color: #fff;
}
.lecata_hover1:hover {
  background-color: #fff;
  border: 1px solid #008dc7;
  color: #008dc7;
  cursor: pointer;
}
.lecata_hover1:hover:disabled {
  cursor: not-allowed;
}

.lecata_hover2:hover {
  color: #66CCCC;
}

@media screen and (max-width: 1170px) {
  .hero_half, .hero_half_contact_ile_plate, .hero_half_contact_plage, .hero_half_contact_cata, .hero_half_contact_jardin {
    height: 40vh;
  }
}
@media screen and (max-width: 649px) {
  .home_logo_container .home_logo {
    max-width: 70vw;
    max-height: 70vh;
  }
  .landing_logo {
    width: 70vw;
  }
}
.construction_bg {
  position: absolute;
  top: 6rem;
  left: 0;
  height: 89vh;
  width: 100vw;
}

.construction_title1 {
  word-spacing: 4px;
  min-width: 35rem;
  font-weight: 400;
  text-transform: uppercase;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 2rem;
}

.roller_img {
  position: absolute;
  top: 40%;
  left: 50%;
  height: 30%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: 0.7;
}

@media screen and (max-width: 1170px) {
  .construction_title1 {
    font-size: 1.5rem;
    min-width: 30rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .construction_title1 {
    font-size: 1.5rem;
    min-width: 30rem;
  }
}
@media screen and (max-width: 649px) {
  .construction_bg {
    top: 3rem;
    width: 100vw;
    height: 100vh;
  }
  .construction_title1 {
    font-size: 1rem;
    min-width: 20rem;
  }
}
@media screen and (max-width: 320px) {
  .construction_bg {
    top: 3rem;
    width: 100vw;
    height: 100vh;
  }
  .construction_title1 {
    font-size: 0.8rem;
    min-width: 16rem;
  }
}
.language_container_home {
  position: fixed;
  top: 0;
  right: 3%;
  z-index: 15;
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;
}
.language_container_home .nav_language_title {
  color: #fff;
}

.navbar-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 9rem;
  width: 100%;
  padding: 0;
  background-color: #000000;
  transition: all 0.4s ease-in-out;
  margin: 0;
  z-index: 10;
}
.navbar-header .navbar-logo {
  position: absolute;
  top: 0rem;
  margin: 0 auto;
  z-index: 2;
  margin: 0;
  height: 100%;
  max-width: 10rem;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  color: #fff;
}
.navbar-header .navbar-logo:hover {
  cursor: pointer;
}
.navbar-header .navbar-logo img {
  vertical-align: middle;
  max-height: 9rem;
  max-width: 8rem;
  transition: all 0.4s ease-in-out;
}
.navbar-header .navbar-logo .logo_jardin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.navbar-header .navbar {
  width: 85%;
  justify-content: space-between;
  display: flex;
  border: 2px solid #fff;
  padding: 1rem;
  margin: 0 auto;
}
.navbar-header .navbar .nav_container {
  display: flex;
  width: 40vw;
  justify-content: space-evenly;
}
.navbar-header .navbar .nav_container .navbar-a {
  color: #fff;
  text-decoration: none;
  font-size: 1.3rem;
  display: flex;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  text-underline-offset: none;
}
.navbar-header .navbar .nav_container .active_navlink {
  text-decoration: none;
  font-size: 1.3rem;
  display: flex;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  text-underline-offset: none;
}
.navbar-header .navbar .nav_container .cata1 {
  color: #008dc7;
}
.navbar-header .navbar .nav_container .cata2 {
  color: #fff;
}
.navbar-header .navbar .nav_container .cata2:hover {
  cursor: pointer;
  color: #008dc7;
}
.navbar-header .navbar .nav_container .cata3 {
  color: #66CCCC;
}
.navbar-header .navbar .nav_container .cata3:hover {
  cursor: pointer;
  color: #008dc7;
}
.navbar-header .navbar .nav_container .plage1 {
  color: #CDAA6D;
}
.navbar-header .navbar .nav_container .plage2 {
  color: #fff;
}
.navbar-header .navbar .nav_container .plage2:hover {
  cursor: pointer;
  color: #CDAA6D;
}
.navbar-header .navbar .nav_container .plage3 {
  color: #f6d7b0;
}
.navbar-header .navbar .nav_container .plage3:hover {
  cursor: pointer;
  color: #CDAA6D;
}
.navbar-header .navbar .nav_container .jardin1 {
  color: #678D58;
}
.navbar-header .navbar .nav_container .jardin2 {
  color: #fff;
}
.navbar-header .navbar .nav_container .jardin2:hover {
  color: #678D58;
}
.navbar-header .navbar .nav_container .jardin3 {
  color: #A6C48A;
}
.navbar-header .navbar .nav_container .jardin3:hover {
  cursor: pointer;
  color: #678D58;
}
.navbar-header .nav-btn {
  cursor: pointer;
  background: #000000;
  border: none;
  outline: none;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  z-index: 10;
}
.navbar-header .nav-btn .navbar_icon {
  position: absolute;
  top: 0;
  right: 0rem;
  transform: translateY(-50%);
  height: 1rem;
  width: 1rem;
  visibility: hidden;
  z-index: -2;
}
.navbar-header .nav-btn .active {
  visibility: visible;
  z-index: 10;
}
.navbar-header .nav-btn .close_btn {
  position: absolute;
  top: 0;
  right: 0rem;
  transform: translateY(-50%);
  height: 1.2rem;
  width: 1.2rem;
  visibility: hidden;
  z-index: -2;
}
.navbar-header .nav-btn .visible {
  visibility: visible;
  z-index: 10;
}

.langague_flags_container {
  display: flex;
}
.langague_flags_container .flag_container {
  width: 1.7rem;
  height: 0.8rem;
  background-position: center;
  background-size: cover;
}
.langague_flags_container .flag_container:hover {
  cursor: pointer;
}
.langague_flags_container .french {
  background-image: url(../pictures/french_flag.png);
  margin-right: 0.3rem;
}
.langague_flags_container .english {
  background-image: url(../pictures/british_flag.jpg);
  margin-right: 0.3rem;
}
.langague_flags_container .russian {
  background-image: url(../pictures/russian_flag.png);
}

.navbar-header.sticky {
  background-color: #000000;
  height: 2.8rem;
  box-shadow: 0 20px 10px rgba(0, 0, 0, 0.3);
}
.navbar-header.sticky .language_container {
  display: none;
}
.navbar-header.sticky .navbar {
  border: none;
}
.navbar-header.sticky .navbar-logo img {
  transform: translateY(-100%);
}
.navbar-header.sticky .navbar-logo .logo_jardin {
  transform: translate(-50%, -200%);
}
.navbar-header.sticky .navbar-a {
  color: #fff;
}

@media screen and (min-width: 1171px) and (max-width: 1600px) {
  .navbar-header {
    height: 7rem;
  }
  .navbar-header .language_container {
    transform: translate(-40%, -10%);
  }
  .navbar-header .language_container h3 {
    font-size: 0.7rem;
  }
  .navbar-header .language_container .langague_flags_container .flag_container {
    width: 1rem;
    height: 0.5rem;
  }
  .navbar-header .navbar-logo {
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-header .navbar-logo img {
    max-height: 7rem;
    max-width: 5.5rem;
  }
  .navbar-header .navbar .nav_container .navbar-a {
    font-size: 0.8rem;
  }
  .navbar-header .navbar .nav_container .active_navlink {
    font-size: 0.8rem;
  }
  .navbar-header.sticky {
    height: 4rem;
  }
  .navbar-header.sticky .navbar .nav_container .navbar-a {
    font-size: 0.8rem;
    margin: 0 0.7rem;
  }
  .navbar-header.sticky .navbar .nav_container .active_navlink {
    font-size: 0.8rem;
    margin: 0 0.7rem;
  }
}
@media screen and (max-width: 1170px) {
  .navbar-header {
    height: 7rem;
  }
  .navbar-header .language_container {
    transform: translate(-40%, -10%);
  }
  .navbar-header .language_container h3 {
    font-size: 0.7rem;
  }
  .navbar-header .language_container .langague_flags_container .flag_container {
    width: 1rem;
    height: 0.5rem;
  }
  .navbar-header .navbar-logo {
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-header .navbar-logo img {
    max-height: 7rem;
    max-width: 5.5rem;
  }
  .navbar-header .navbar .nav_container .navbar-a {
    font-size: 0.8rem;
  }
  .navbar-header .navbar .nav_container .active_navlink {
    font-size: 0.8rem;
  }
  .navbar-header.sticky {
    height: 4rem;
  }
  .navbar-header.sticky .navbar .nav_container .navbar-a {
    font-size: 0.8rem;
    margin: 0 0.7rem;
  }
  .navbar-header.sticky .navbar .nav_container .active_navlink {
    font-size: 0.8rem;
    margin: 0 0.7rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .navbar-header {
    justify-content: right;
    height: 4rem;
    width: 100vw;
  }
  .navbar-header .navbar {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 2rem;
    min-width: 95%;
    align-items: center;
    gap: 1rem;
    border: none;
    transition: 1s;
    margin: 0.1rem 0;
    z-index: 1;
  }
  .navbar-header .navbar .nav_container {
    width: 45%;
    align-items: center;
  }
  .navbar-header .navbar .nav_container .navbar-a {
    font-size: 0.7rem;
  }
  .navbar-header .navbar .nav_container .active_navlink {
    font-size: 0.7rem;
  }
  .navbar-header .language_container .nav_language_title {
    font-size: 0.7rem;
  }
  .navbar-header .navbar-logo {
    position: absolute;
    top: 0rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .navbar-header .navbar-logo img {
    max-height: 4rem;
  }
  .navbar-header.sticky {
    height: 2rem;
  }
  .navbar-header.sticky .language_container {
    visibility: hidden;
  }
  .navbar-header.sticky .navbar {
    border: none;
    height: 0.1rem;
  }
  .navbar-header.sticky .navbar .nav_container {
    width: 45%;
    align-items: center;
  }
  .navbar-header.sticky .navbar .nav_container .navbar-a {
    font-size: 0.7rem;
  }
  .navbar-header.sticky .navbar-logo {
    position: absolute;
    transform: translatey(-100%);
  }
  .language_container_home .nav_language_title {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 649px) {
  .language_container_home .nav_language_title {
    font-size: 0.7rem;
  }
  .navbar-header {
    height: 3rem;
    width: 100vw;
  }
  .navbar-header .language_container {
    top: 50%;
    right: 20%;
    transform: translateY(-50%);
  }
  .navbar-header .navbar-logo {
    position: fixed;
    top: 0;
    left: 3rem;
    height: 3rem;
    max-width: 5rem;
    margin: 0;
    transform: none;
  }
  .navbar-header .navbar-logo img {
    height: 3rem;
  }
  .navbar-header .navbar-logo .logo_jardin {
    position: fixed;
    top: 0;
    left: 3rem;
    transform: none;
  }
  .navbar-header .navbar {
    position: absolute;
    top: 3rem;
    left: 0;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    gap: 1rem;
    background-color: rgba(0, 0, 0, 0.8);
    border: none;
    transition: 1s;
    transform: translatex(100%);
    z-index: 1;
  }
  .navbar-header .navbar .nav_container {
    width: auto;
  }
  .navbar-header .navbar .nav_container .navbar-a {
    font-size: 1rem;
    margin: 0 0.7rem;
  }
  .navbar-header .navbar .nav_container .active_navlink {
    font-size: 1rem;
    margin: 0 0.7rem;
  }
  .navbar-header .nav-btn {
    visibility: visible;
    opacity: 1;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10%;
  }
  .navbar-header .responsive-nav {
    transform: none;
  }
  .navbar-header.sticky {
    background-color: #000000;
    height: 3rem;
    width: 100vw;
    box-shadow: none;
  }
  .navbar-header.sticky .language_container {
    visibility: hidden;
  }
  .navbar-header.sticky .navbar {
    border: none;
  }
  .navbar-header.sticky .navbar .nav_container {
    width: auto;
  }
  .navbar-header.sticky .navbar .nav_container .navbar-a {
    font-size: 1rem;
    margin: 0 0.7rem;
  }
  .navbar-header.sticky .navbar .nav_container .active_navlink {
    font-size: 1rem;
    margin: 0 0.7rem;
  }
  .navbar-header.sticky .navbar-logo {
    position: fixed;
    top: 0;
    left: 3rem;
    transform: none;
  }
  .navbar-header.sticky .navbar-logo img {
    height: 3rem;
    transform: none;
  }
  .navbar-header.sticky .navbar-logo .logo_jardin {
    position: fixed;
    top: 0;
    left: 3rem;
    transform: none;
  }
}
@media screen and (max-width: 320px) {
  .language_container_home .nav_language_title {
    font-size: 0.7rem;
  }
  .navbar-header {
    height: 3rem;
    width: 100vw;
  }
  .navbar-header .language_container {
    top: 50%;
    right: 20%;
    transform: translateY(-50%);
  }
  .navbar-header .language_container .nav_language_title {
    font-size: 0.7rem;
  }
  .navbar-header .navbar-logo {
    position: fixed;
    top: 0;
    left: 3rem;
    height: 3rem;
    max-width: 5rem;
    margin: 0;
    transform: none;
  }
  .navbar-header .navbar-logo img {
    height: 3rem;
  }
  .navbar-header .navbar-logo .logo_jardin {
    position: fixed;
    top: 0;
    left: 1rem;
    transform: none;
  }
  .navbar-header .navbar {
    position: absolute;
    top: 3rem;
    left: 0;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    gap: 1rem;
    background-color: rgba(0, 0, 0, 0.8);
    border: none;
    transition: 1s;
    transform: translatex(100%);
    z-index: 1;
  }
  .navbar-header .navbar .nav_container {
    width: auto;
  }
  .navbar-header .navbar .nav_container .navbar-a {
    font-size: 0.7rem;
    margin: 0 0.7rem;
  }
  .navbar-header .navbar .nav_container .active_navlink {
    font-size: 0.7rem;
    margin: 0 0.7rem;
  }
  .navbar-header .nav-btn {
    visibility: visible;
    opacity: 1;
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10%;
  }
  .navbar-header .responsive-nav {
    transform: none;
  }
  .navbar-header.sticky {
    background-color: #000000;
    height: 3rem;
    width: 100vw;
    box-shadow: none;
  }
  .navbar-header.sticky .language_container {
    visibility: hidden;
  }
  .navbar-header.sticky .navbar {
    border: none;
  }
  .navbar-header.sticky .navbar .nav_container {
    width: auto;
  }
  .navbar-header.sticky .navbar .nav_container .navbar-a {
    font-size: 1rem;
    margin: 0 0.7rem;
  }
  .navbar-header.sticky .navbar .nav_container .active_navlink {
    font-size: 1rem;
    margin: 0 0.7rem;
  }
  .navbar-header.sticky .navbar-logo {
    position: fixed;
    top: 0;
    left: 3rem;
    transform: none;
  }
  .navbar-header.sticky .navbar-logo img {
    height: 3rem;
    transform: none;
  }
  .navbar-header.sticky .navbar-logo .logo_jardin {
    position: fixed;
    top: 0;
    left: 3rem;
    transform: none;
  }
}
.navbar2 {
  height: 6rem;
  width: 100vw;
  background-color: #000000;
  color: #fff;
  display: flex;
  position: relative;
  align-items: center;
}
.navbar2 .logo_link {
  height: 100%;
  width: auto;
  position: absolute;
  left: 10rem;
  top: 0;
}
.navbar2 .navbar_container {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.navbar2 .navbar_link {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-start;
  position: relative;
}
.navbar2 .navbar_link .navlink {
  margin: 0 2rem;
  color: #fff;
  text-decoration: none;
  width: 9rem;
  font-size: 1.3rem;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  text-underline-offset: none;
  padding: 0.8rem 1.5rem;
}
.navbar2 .navbar_link .navlink:hover {
  cursor: pointer;
  background-color: #dd8808;
}
.navbar2 .navbar_link .open {
  background-color: #dd8808;
}

.submenu_container {
  position: absolute;
  top: 6rem;
  right: 1rem;
  width: 13rem;
  list-style: none;
  text-align: flex-start;
  background-color: #000000;
}
.submenu_container .submenu_item_li {
  list-style: none;
  padding: 0 1rem;
}
.submenu_container .submenu_item_li:hover {
  background-color: #c47907;
}
.submenu_container .submenu_item_li:hover .navlink {
  background-color: #dd8808;
}
.submenu_container .submenu_item_li .submenu_item {
  display: block;
  height: 100%;
  text-decoration: none;
  color: #fff;
  padding: 1rem 0;
  cursor: pointer;
}

.clicked {
  display: none;
}

.footer {
  display: flex;
  background-color: #000000;
  justify-content: space-evenly;
  padding: 2rem 10rem;
}
.footer .footer_container {
  width: 30%;
  margin: 1rem;
}
.footer .footer_container p {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: justify;
}
.footer .footer_container li {
  color: rgba(255, 255, 255, 0.5);
}
.footer .footer_title {
  font-size: 0.75rem;
  font-weight: 650;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
}
.footer .footer_contact_infos {
  align-items: center;
  justify-content: center;
}
.footer .footer_contact_infos .footer_contact_info {
  display: flex;
}
.footer .footer_contact_infos .footer_contact_info .contact_icon {
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  margin: 0 0.5rem;
  padding: 0.4rem;
  transform: translateY(15%);
  margin-right: 1rem;
}
.footer .social_media {
  display: flex;
  justify-content: left;
}
.footer .social_media .social_media_link, .footer .social_media .social_media_link_laplage, .footer .social_media .social_media_link_lejardin, .footer .social_media .social_media_link_lariviere, .footer .social_media .social_media_link_lecata {
  margin: 0 0.5rem;
  padding: 0.4rem;
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.5);
}
.footer .social_media .social_media_link_lecata {
  color: rgba(255, 255, 255, 0.5);
}
.footer .social_media .social_media_link_lecata:hover {
  color: #008dc7;
}
.footer .social_media .social_media_link_lariviere {
  color: rgba(255, 255, 255, 0.5);
}
.footer .social_media .social_media_link_lariviere:hover {
  color: #b7b9c1;
}
.footer .social_media .social_media_link_lejardin {
  color: rgba(255, 255, 255, 0.5);
}
.footer .social_media .social_media_link_lejardin:hover {
  color: #678D58;
}
.footer .social_media .social_media_link_laplage {
  color: rgba(255, 255, 255, 0.5);
}
.footer .social_media .social_media_link_laplage:hover {
  color: #CDAA6D;
}
.footer .footer_openings {
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: justify;
  width: 100%;
  margin-top: 1rem;
}
.footer .footer_openings .footer_openings_li {
  list-style: none;
  min-width: 15rem;
  max-width: 20rem;
  width: 70%;
  position: relative;
  text-transform: capitalize;
}
.footer .footer_openings .footer_openings_li .openings {
  position: absolute;
  right: 0;
  color: #fff;
}

.footer_gmap {
  width: 100%;
  max-width: 25rem;
  border: none !important;
}

.footer_language_container {
  display: flex;
  color: #fff;
  align-items: center;
}
.footer_language_container .footer_language_title {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 1rem;
}

@media screen and (max-width: 1170px) {
  .footer {
    width: 100vw;
    padding: 0.5rem;
  }
  .footer .footer_container {
    width: 26%;
    margin: 0;
  }
  .footer .footer_container p {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
    text-align: justify;
  }
  .footer .footer_container li {
    color: rgba(255, 255, 255, 0.5);
    min-width: none;
  }
  .footer .footer_container .social_media .social_media_link, .footer .footer_container .social_media .social_media_link_lecata, .footer .footer_container .social_media .social_media_link_lariviere, .footer .footer_container .social_media .social_media_link_lejardin, .footer .footer_container .social_media .social_media_link_laplage {
    margin: 0 0.6rem;
    font-size: 0.7rem;
  }
  .footer .footer_container .footer_contact_infos .footer_contact_info {
    align-items: center;
    width: 100%;
  }
  .footer .footer_container .footer_contact_infos .footer_contact_info .contact_icon {
    color: rgba(255, 255, 255, 0.5);
    height: 1rem;
    width: 1rem;
    background-color: transparent;
    margin-right: 0rem;
    transform: translateY(5%);
  }
  .footer .footer_container .footer_contact_infos .footer_contact_info p {
    font-size: 0.7rem;
    text-align: left;
  }
  .footer .footer_container .footer_openings {
    font-size: 0.7rem;
  }
  .footer .footer_container .footer_openings .footer_openings_li {
    list-style: none;
    width: 100%;
    min-width: 6rem;
    position: relative;
    text-transform: capitalize;
  }
  .footer .footer_container .footer_openings .footer_openings_li .openings {
    position: absolute;
    right: 0;
    color: #fff;
  }
  .footer_contact_infos .footer_contact_info {
    display: flex;
    margin: 0;
    padding: 0;
  }
  .footer_contact_infos .footer_contact_info i {
    font-family: FontAwesome !important;
    color: rgba(255, 255, 255, 0.5);
    height: 1rem;
    width: 1rem;
    background-color: transparent;
    margin: 0 0.5rem;
    padding: 0.4rem;
    transform: translateY(-50%);
    margin-right: 1rem;
  }
  .footer_contact_infos .footer_contact_info p {
    margin: 0;
  }
  .footer_gmap {
    width: 100%;
    height: 60%;
    border: none !important;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1.5rem;
  }
  .footer .footer_container {
    width: 45%;
  }
  .footer .footer_container .footer_gmap {
    width: 100%;
    height: 80%;
  }
  .footer .footer_container .social_media a {
    margin: 0 0.6rem;
    font-size: 0.6rem;
  }
  .footer_contact_infos .footer_contact_info p {
    margin: 0;
    font-size: 0.7rem;
  }
  .footer_openings {
    margin-top: 0rem;
  }
  .footer_openings .footer_openings_li {
    font-size: 0.7rem;
    min-width: none;
    max-width: 15rem;
  }
}
@media screen and (max-width: 649px) {
  .footer {
    display: block;
    align-items: center;
    justify-content: flex-start;
  }
  .footer .footer_container {
    width: 90%;
    margin-bottom: 1.5rem;
  }
  .footer .footer_container .footer_gmap {
    width: 100%;
  }
  .footer .footer_container .social_media a {
    margin: 0 0.6rem;
    font-size: 0.8rem;
  }
  .footer_contact_infos .footer_contact_info p {
    margin: 0;
    font-size: 0.8rem;
  }
  .footer_openings {
    max-width: 60%;
    margin-top: 0rem;
  }
  .footer_openings .footer_openings_li {
    font-size: 0.7rem;
    max-width: 15rem;
    min-width: none;
  }
}
@media screen and (max-width: 320px) {
  footer {
    width: 100vw;
  }
  .footer_container {
    width: 100%;
  }
  .footer_openings {
    margin-top: 0rem;
    width: 100%;
    max-width: none;
  }
  .footer_openings .footer_openings_li {
    font-size: 0.7rem;
    min-width: none;
    max-width: none;
    width: auto;
  }
  .footer_gmap {
    width: 100%;
    max-width: none;
  }
}
.links_container {
  display: flex;
}
.links_container h3 {
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
}
.links_container ul {
  display: flex;
  flex-direction: column;
}
.links_container ul .quick_link {
  list-style: none;
  color: #fff;
  text-decoration: none;
  font-size: 0.8rem;
}
.links_container ul .quick_link:hover {
  color: #dd8808;
}

.language_container {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-60%, -10%);
  display: flex;
  align-items: center;
  transition: all 0.4s ease-in-out;
  z-index: 10000;
}
.language_container .nav_language_title {
  color: #fff;
}

.navbar_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  box-sizing: border-box;
  height: 5rem;
  align-items: center;
}
.navbar_header .nav-logo {
  height: 4rem;
  margin: 0.25rem 0;
  transition: all 0.3s ease-in-out;
}
.navbar_header #menu-bar {
  display: none;
}
.navbar_header .menu-bar {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  transition: all 0.3s ease-in-out;
  display: none;
  z-index: 10;
}
.navbar_header .menu-bar:hover {
  cursor: pointer;
}
.navbar_header .nav {
  transition: all 0.3s ease-in-out;
  justify-content: center;
  height: 100%;
  align-items: center;
  text-align: center;
  padding: 1rem 0 0.2rem 0;
}
.navbar_header .nav .nav_container {
  list-style: none;
  transition: all 0.3s ease-in-out;
}
.navbar_header .nav .nav_container li {
  position: relative;
  float: left;
  height: 2rem;
  text-align: center;
  align-items: center;
}
.navbar_header .nav .nav_container li div {
  transform: translate(0, -8px);
}
.navbar_header .nav .nav_container li .nav-link {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.3rem;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  height: 100%;
  margin: auto 0;
}
.navbar_header .nav .nav_container li .nav-link:hover {
  color: #dd8808;
  cursor: pointer;
}
.navbar_header .nav .nav_container li .nav-link .dropdown_icon {
  transform: translateY(4px);
}
.navbar_header .nav .nav_container li .navbar_submenu_off {
  display: none;
  transition: all 0.3s ease-in-out;
}
.navbar_header .nav .nav_container li .navbar_submenu_on_sticky {
  position: absolute;
  left: 0;
  top: 2.9rem;
  width: 200px;
  background-color: #000000;
  list-style: none;
  display: initial;
  padding: 0;
  transition: all 0.3s ease-in-out;
}
.navbar_header .nav .nav_container li .navbar_submenu_on_sticky li {
  margin: 1.5rem 0;
  width: 100%;
  color: #fff;
  text-decoration: none;
}
.navbar_header .nav .nav_container li .navbar_submenu_on_sticky li .navbar_sublink {
  text-decoration: none;
  color: #fff;
  font-size: 1.3rem;
}
.navbar_header .nav .nav_container li .navbar_submenu_on_sticky li .navbar_sublink:hover {
  color: #dd8808;
}
.navbar_header .nav .nav_container li .navbar_submenu_on {
  position: absolute;
  left: 0;
  top: 3.4rem;
  width: 200px;
  background-color: transparent;
  list-style: none;
  display: initial;
  padding: 0;
  transition: all 0.3s ease-in-out;
}
.navbar_header .nav .nav_container li .navbar_submenu_on li {
  margin: 1.5rem 0;
  width: 100%;
  color: #fff;
  text-decoration: none;
}
.navbar_header .nav .nav_container li .navbar_submenu_on li .navbar_sublink {
  text-decoration: none;
  color: #fff;
  font-size: 1.3rem;
}
.navbar_header .nav .nav_container li .navbar_submenu_on li .navbar_sublink:hover {
  color: #dd8808;
}

.sticky {
  background-color: #000000;
  box-shadow: 0 20px 10px rgba(0, 0, 0, 0.3);
  height: 4.7rem;
}
.sticky .nav-logo {
  height: 3.8rem;
  margin: 5px 0;
  display: flex;
}
.sticky .nav {
  margin: 10px 0;
  padding: 0.8rem 0 0.2rem 0;
}
.sticky .nav .nav_container li div {
  transform: translate(0, -6px);
}
.sticky .nav .nav_container li .nav-link {
  color: #fff;
}
.sticky .nav .nav_container li .nav-link:hover {
  color: #dd8808;
}

@media screen and (max-width: 1170px) {
  .sticky .nav-logo {
    height: 3.7rem !important;
  }
  .navbar_header .nav-logo {
    height: 4rem;
  }
  .navbar_header .nav .nav_container li .nav-link {
    font-size: 1.1rem;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky {
    top: 2.7rem;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky li {
    margin: 0.5rem 0;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky li .navbar_sublink {
    font-size: 1rem;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on {
    top: 2.9rem;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on li {
    margin: 0.5rem 0;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on li .navbar_sublink {
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .sticky .nav-logo {
    height: 3.7rem !important;
  }
  .navbar_header {
    padding: 20px;
  }
  .navbar_header .menu-bar {
    display: initial;
  }
  .navbar_header .nav-logo {
    height: 4rem;
  }
  .navbar_header .nav {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 50vw;
    height: 100vh;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .navbar_header .nav .nav_container {
    margin: auto 0;
    padding: 0;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }
  .navbar_header .nav .nav_container li {
    transition: all 0.3s ease-in-out;
    width: 100%;
    margin: 2rem 0;
    position: relative;
    height: auto;
  }
  .navbar_header .nav .nav_container li .nav-link {
    font-size: 1.3rem;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on {
    position: relative !important;
    top: 0;
    height: auto;
    width: 100%;
    margin: 0;
    transition: all 0.3s ease-in-out;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on li {
    margin: 0.3rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 width 100%;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky {
    position: relative !important;
    top: 0;
    height: auto;
    width: 100%;
    margin: 0;
    transition: all 0.3s ease-in-out;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky li {
    margin: 0.3rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 width 100%;
  }
  .navbar_header #menu-bar:checked ~ .nav {
    display: initial;
  }
}
@media screen and (max-width: 649px) {
  .sticky .nav-logo {
    height: 3.3rem !important;
  }
  .navbar_header {
    padding: 1rem 1.5rem;
    width: 100vw;
  }
  .navbar_header .menu-bar {
    display: initial;
  }
  .navbar_header .nav-logo {
    height: 3.7rem;
  }
  .navbar_header .nav {
    position: absolute;
    top: 0;
    left: 25%;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 75vw;
    height: 100vh;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .navbar_header .nav .nav_container {
    margin: auto 0;
    padding: 0;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }
  .navbar_header .nav .nav_container li {
    transition: all 0.3s ease-in-out;
    width: 100%;
    margin: 2rem 0;
    position: relative;
    height: auto;
  }
  .navbar_header .nav .nav_container li .nav-link {
    font-size: 1.2rem;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on {
    position: relative !important;
    top: 0;
    height: auto;
    width: 100%;
    margin: 0;
    transition: all 0.3s ease-in-out;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on li {
    margin: 0.3rem 0;
    padding: 0 width 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on li .navbar_sublink {
    font-size: 1.15rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 2rem;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky {
    position: relative !important;
    top: 0;
    height: auto;
    width: 100%;
    margin: 0;
    transition: all 0.3s ease-in-out;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky li {
    margin: 0;
    margin: 0.3rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0 width 100%;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky li .navbar_sublink {
    font-size: 1.15rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 2rem;
  }
  .navbar_header #menu-bar:checked ~ .nav {
    display: initial;
  }
}
@media screen and (max-width: 320px) {
  .language_container {
    transform: translate(-15%, -10%);
  }
  .sticky .nav-logo {
    height: 3rem !important;
  }
  .navbar_header {
    padding: 20px;
  }
  .navbar_header .menu-bar {
    display: initial;
    padding: 0.5rem 0.5rem;
    transform: translateY(15%);
  }
  .navbar_header .nav-logo {
    height: 3.3rem;
  }
  .navbar_header .nav {
    position: absolute;
    top: 0;
    left: 20%;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    width: 80vw;
    height: 100vh;
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }
  .navbar_header .nav .nav_container {
    margin: auto 0;
    padding: 0;
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }
  .navbar_header .nav .nav_container li {
    transition: all 0.3s ease-in-out;
    width: 100%;
    margin: 2rem 0;
    position: relative;
    height: auto;
  }
  .navbar_header .nav .nav_container li .nav-link {
    font-size: 1.1rem;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on {
    position: relative !important;
    top: 0;
    height: auto;
    width: 100%;
    margin: 0;
    transition: all 0.3s ease-in-out;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on li {
    margin: 0;
    padding: 0 width 100%;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on li .navbar_sublink {
    font-size: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 2rem;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky {
    position: relative !important;
    top: 0;
    height: auto;
    width: 100%;
    margin: 0;
    transition: all 0.3s ease-in-out;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky li {
    margin: 0;
  }
  .navbar_header .nav .nav_container li .navbar_submenu_on_sticky li .navbar_sublink {
    font-size: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 2rem;
  }
  .navbar_header #menu-bar:checked ~ .nav {
    display: initial;
  }
}
.page_container {
  position: relative;
  width: 100vw;
  height: 100vh;
}
.page_container .home_page_title {
  position: absolute;
  z-index: 15;
  color: white;
  font-size: 3rem;
  top: 80%;
}
.page_container .split {
  position: absolute;
  width: 33.34%;
  height: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 1000ms ease-in-out;
}
.page_container .split img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 20rem;
  z-index: 10;
}
.page_container .split p {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-style: italic;
  width: 100%;
  max-width: none;
  margin: 0;
  font-size: 0.9rem;
}
.page_container .split .btn_home {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  border: white solid 0.2rem;
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  width: 15rem;
  padding: 1.5rem;
}
.page_container .left {
  left: 0;
  background: url(../pictures/lile_plate/vue.jpg);
  background-repeat: no-repeat;
  background-position: 30% 100%;
  background-size: auto 100%;
  transition: all 1000ms ease-in-out;
}
.page_container .left img {
  top: 20%;
}
.page_container .left .btn_home:hover {
  background-color: #66CCCC;
  border-color: #66CCCC;
  transition: all 0.4s ease;
}
.page_container .center {
  left: 33.33%;
  background: url(../pictures/le_jardin/shooting/entree_portrait_cut.jpg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: 45%;
  transition: all 1000ms ease-in-out;
}
.page_container .center img {
  top: 42%;
  height: auto;
  width: 20rem;
}
.page_container .center .btn_home:hover {
  background-color: #678D58;
  border-color: #678D58;
  transition: all 0.4s ease;
}
.page_container .right {
  right: 0;
  background: url(../pictures/la_plage/la_plage.jpg);
  background-repeat: no-repeat;
  background-position: 70%;
  background-size: auto 100%;
  transition: all 1000ms ease-in-out;
}
.page_container .right img {
  top: 15%;
  height: auto;
  width: 20rem;
}
.page_container .right .btn_home:hover {
  background-color: #f6d7b0;
  border-color: #f6d7b0;
  transition: all 0.4s ease;
}
.page_container h1 {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 4rem;
  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
}
.page_container h2 {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 3rem;
  font-weight: 400;
  color: #fff;
  color: #fff;
}

.hover-left .left {
  width: 35%;
  z-index: 10;
  background-position: 32% 100%;
}

.hover-center .center {
  left: 32.5%;
  width: 35%;
  background-position: 40%;
  z-index: 10;
}

.hover-right .right {
  width: 35%;
  z-index: 10;
  background-position: 75%;
}

@media screen and (min-width: 1171px) and (max-width: 1600px) {
  .page_container .home_page_title {
    font-size: 2.5rem;
    top: 80%;
  }
  .page_container .split img {
    width: 16rem;
  }
  .page_container .split .btn_home {
    font-size: 0.9rem;
    width: 12rem;
    padding: 1rem;
  }
  .page_container .left img {
    top: 20%;
  }
  .page_container .center img {
    top: 44.15%;
  }
  .page_container .right img {
    top: 20%;
  }
}
@media screen and (max-width: 1170px) {
  .page_container .home_page_title {
    font-size: 2rem;
    top: 80%;
  }
  .page_container .split {
    position: absolute;
    width: 100%;
    height: 33.5vh;
  }
  .page_container .split p {
    top: 90%;
  }
  .page_container .split img {
    width: 9rem;
  }
  .page_container .split .btn_home {
    font-size: 0.7rem;
    padding: 0.5rem;
    width: 6rem;
    top: 78%;
    border: white solid 0.1rem;
  }
  .page_container .left {
    top: 0;
    left: 0;
    background-size: 100% auto;
    background-position: 30% 55%;
  }
  .page_container .left img {
    top: 5%;
    width: auto;
    height: 70%;
  }
  .page_container .center {
    top: 33.33vh;
    left: 0;
    background-size: 100% auto;
  }
  .page_container .center img {
    width: auto;
    height: 50%;
    top: 15%;
  }
  .page_container .right {
    top: 66.66vh;
    left: 0;
    background-size: 100% auto;
    background-position: 50% 70%;
  }
  .page_container .right img {
    top: 5%;
    width: auto;
    height: 70%;
  }
  .page_container h1 {
    top: 13%;
    font-size: 2.4rem;
  }
  .page_container h2 {
    font-size: 1.7rem;
  }
  .page_container .btn_home {
    top: 55%;
    border: #fff solid 0.15rem;
    font-size: 0.9rem;
    width: auto;
    padding: 1rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .page_container .split {
    position: absolute;
    width: 100%;
    height: 33.5vh;
  }
  .page_container .left {
    top: 0;
    left: 0;
    background-size: 100% auto;
  }
  .page_container .left img {
    top: 5%;
    width: auto;
    height: 70%;
  }
  .page_container .center {
    top: 33.33vh;
    left: 0;
    background-size: 100% auto;
  }
  .page_container .center img {
    width: auto;
    height: 50%;
    top: 15%;
  }
  .page_container .right {
    top: 66.66vh;
    left: 0;
    background-size: 100% auto;
  }
  .page_container .right img {
    top: 5%;
    width: auto;
    height: 70%;
  }
  .page_container h1 {
    top: 10%;
    font-size: 2.5rem;
  }
  .page_container .btn_home {
    border: #fff solid 0.15rem;
    font-size: 0.9rem;
    width: auto;
    padding: 1rem;
    top: 60%;
  }
}
@media screen and (max-width: 649px) {
  .page_container .split {
    position: absolute;
    width: 100%;
    height: 33.4vh;
  }
  .page_container .split p {
    top: 92%;
    font-size: 0.7rem;
  }
  .page_container .split .btn_home {
    border: #fff solid 0.15rem;
    font-size: 0.6rem;
    width: auto;
    padding: 0.4rem;
    top: 77%;
  }
  .page_container .left {
    top: 0;
    left: 0;
    background-size: cover;
  }
  .page_container .left img {
    width: auto;
    height: 70%;
    top: 3%;
  }
  .page_container .center {
    top: 33.33vh;
    left: 0;
    background-size: cover;
  }
  .page_container .center img {
    top: 20%;
    width: auto;
    height: 50%;
  }
  .page_container .right {
    top: 66.66666vh;
    left: 0;
    background-size: cover;
  }
  .page_container .right img {
    width: auto;
    height: 70%;
    top: 3%;
  }
  .page_container h1 {
    top: 10%;
    font-size: 2.2rem;
  }
  .page_container h2 {
    font-size: 1.7rem;
  }
}
.home_hero {
  background-image: url(../pictures/le_jardin/jardin_bg.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 100%;
  height: 120vh;
  width: 100vw;
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;
}
.home_hero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.description {
  background-color: #000000;
  color: #fff;
  text-align: center;
  padding: 3rem 20rem;
  font-size: 2rem;
  letter-spacing: 0.2px;
}
.description span {
  font-size: 5rem;
}

.home_scrolling {
  width: 100vw;
  justify-content: center;
  text-align: center;
  background-color: transparent;
}
.home_scrolling .home_reservation_container {
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Noto Serif Georgian", serif;
  width: 100vw;
  height: auto;
  padding: 5rem 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
.home_scrolling .home_reservation_container .contact_h2 {
  font-size: 2.5rem;
  letter-spacing: 2px;
}
.home_scrolling .home_reservation_container div {
  font-size: 0.9rem;
  text-transform: uppercase;
  max-width: 14rem;
  margin: 0 auto;
  color: #fff;
  padding: 1rem 0rem;
  letter-spacing: 1px;
  word-spacing: 2px;
}
.home_scrolling .home_reservation_container .reservation_btn {
  background-color: #dd8808;
  border: 2px solid #dd8808;
}
.home_scrolling .home_reservation_container h1 {
  margin-top: 6rem;
  font-size: 3.5rem;
  color: #fff;
}
.home_scrolling .home_reservation_container .contact_h2 {
  color: #fff;
}
.home_scrolling .restaurants_section_container {
  width: 100%;
  height: auto;
  padding: 4rem 0;
  background-color: #fff;
}
.home_scrolling .restaurant_section {
  position: relative;
  width: 100vw;
  display: flex;
  background-color: #fff;
  margin: 0 auto;
  padding: 0 10%;
}
.home_scrolling .restaurant_section .restau_photo_container {
  width: 50%;
  align-items: center;
  justify-content: center;
  position: relative;
}
.home_scrolling .restaurant_section .restau_photo_container .photo_restau {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 90%;
  height: auto;
  max-height: 100%;
  background-size: cover;
}
.home_scrolling .restaurant_section .description_container {
  text-align: center;
  padding: auto 0;
  height: 100%;
  min-height: 35rem;
  width: 50%;
  justify-content: center;
  text-align: center;
  position: relative;
}
.home_scrolling .restaurant_section .description_container h3 {
  font-size: 1.4rem;
  font-weight: 400;
  word-spacing: 2.2px;
  margin: 0;
}
.home_scrolling .restaurant_section .description_container .text_container {
  height: auto;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
}
.home_scrolling .restaurant_section .description_container .text_container .separator {
  margin: 1.5rem auto 2rem auto;
  height: 0.05rem;
  width: 3rem;
  background-color: #000000;
}
.home_scrolling .restaurant_section .description_container .text_container p {
  margin: 1.5rem auto;
  line-height: 1.7rem;
  font-weight: 300;
  font-size: 110%;
  word-spacing: 2.2px;
}
.home_scrolling .restaurant_section .description_container .text_container .see_more_btn {
  outline: none;
  text-decoration: none;
  background-color: #dd8808;
  padding: 0.7rem 2.8rem;
  text-transform: uppercase;
  color: #fff;
  margin: 15rem 0 0 0;
}
.home_scrolling .restaurant_section .description_container .text_container .see_more_btn:hover {
  opacity: 0.7;
}
.home_scrolling .home_contact_container {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  justify-content: center;
  align-items: center;
  padding: 6rem 0;
}
.home_scrolling .home_contact_container .flex_wrap_container {
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  margin: 0 auto;
  justify-content: center;
}
.home_scrolling .home_contact_container .contact_one_restau_container {
  display: flex;
  width: 45rem;
  box-shadow: 0 20px 10px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 1rem;
}
.home_scrolling .home_contact_container .contact_one_restau_container .logo_container {
  width: 45%;
  padding: auto;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.home_scrolling .home_contact_container .contact_one_restau_container .logo_container img {
  width: 60%;
  vertical-align: middle;
  margin: auto, 0;
}
.home_scrolling .home_contact_container .contact_one_restau_container .description_contact {
  color: #fff;
  position: relative;
  width: 55%;
}
.home_scrolling .home_contact_container .contact_one_restau_container .description_contact .paragraph_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.home_scrolling .home_contact_container .contact_one_restau_container .description_contact .paragraph_container .p_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_scrolling .home_contact_container .contact_one_restau_container .description_contact .paragraph_container .p_icon_container .icon_contact {
  margin: 0 1rem;
}
.home_scrolling .home_contact_container .contact_one_restau_container .description_contact .paragraph_container .p_icon_container p {
  font-size: 0.9rem;
}
.home_scrolling .home_contact_container .contact_one_restau_container .description_contact .paragraph_container .contact_us_btn {
  outline: none;
  text-decoration: none;
  background-color: #dd8808;
  padding: 0.7rem 2.8rem;
  text-transform: uppercase;
  color: #fff;
}
.home_scrolling .home_contact_container .contact_one_restau_container .description_contact .paragraph_container .contact_us_btn:hover {
  opacity: 0.7;
}
.home_scrolling .mini_gallery_jardin {
  width: 100vw;
  background-color: #fff;
  padding: 3rem 0;
}
.home_scrolling .mini_gallery_jardin .mini_gallery_wrap {
  max-width: 90%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 1.5rem;
}
.home_scrolling .mini_gallery_jardin .mini_gallery_wrap img {
  width: 500px;
  height: 340px;
  background-size: cover;
}

@media screen and (min-width: 1171px) and (max-width: 1600px) {
  .home_contact_container .flex_wrap_container .contact_one_restau_container {
    min-height: 20rem;
  }
  .home_contact_container .flex_wrap_container .contact_one_restau_container .logo_container {
    position: relative;
    line-height: 0;
  }
  .home_contact_container .flex_wrap_container .contact_one_restau_container .logo_container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .home_scrolling .restaurants_section_container {
    padding: 0;
  }
  .restaurants_section_container {
    width: 100%;
    height: auto;
    padding: 2rem 0;
    background-color: #fff;
  }
  .restaurant_section {
    background-color: lightblue;
  }
  .text_container {
    width: 80%;
    font-size: 0.9;
  }
  .text_container p {
    font-size: 0.9rem;
  }
  .restau_description_p {
    margin: 1rem 0;
    word-spacing: 1.9px;
    width: 100%;
    font-size: 0.9rem;
    line-height: 1rem;
  }
}
@media screen and (max-width: 1170px) {
  .reverse {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .home_scrolling .restaurants_section_container {
    padding: 0;
  }
  .restaurant_section {
    display: block;
    flex-direction: column;
    padding: 3rem 0 0 0 !important;
  }
  .restaurant_section .restau_photo_container {
    margin: 0 auto;
    width: 85vw !important;
    min-height: 35rem !important;
  }
  .restaurant_section .restau_photo_container .photo_restau {
    padding: 0;
  }
  .restaurant_section .description_container {
    width: 85vw !important;
    margin: 0 auto !important;
    min-height: 35rem !important;
  }
  .restaurant_section .description_container .text_container {
    width: 80%;
    font-size: 0.9;
  }
  .restaurant_section .description_container .text_container p {
    font-size: 0.9rem;
  }
  .restaurant_section .description_container .restau_description_p {
    margin: 1rem 0;
    word-spacing: 1.9px;
    width: 100%;
    font-size: 0.9rem;
    line-height: 1rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .reverse {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .home_scrolling .restaurants_section_container {
    padding: 0;
  }
  .restaurant_section {
    flex-direction: column;
    padding: 0;
  }
  .restaurant_section .restau_photo_container {
    margin: 0 auto;
    width: 100vw !important;
    max-width: 50rem;
  }
  .restaurant_section .description_container {
    width: 100vw !important;
    height: auto;
    padding: 0;
    margin: 0;
    min-height: 35rem !important;
  }
  .restaurant_section .description_container .text_container {
    width: 80%;
    font-size: 0.9;
    margin: 0;
  }
  .restaurant_section .description_container .text_container p {
    font-size: 0.85rem !important;
  }
  .restaurant_section .description_container .restau_description_p {
    margin: 1rem 0;
    word-spacing: 1.9px;
    width: 100%;
    font-size: 0.9rem;
    line-height: 1rem;
  }
}
@media screen and (max-width: 649px) {
  .home_contact_container .flex_wrap_container .contact_one_restau_container {
    flex-direction: column;
    width: 100vw !important;
    padding: 1rem 0 !important;
  }
  .home_contact_container .flex_wrap_container .contact_one_restau_container .logo_container {
    width: 100vw;
    position: relative;
    margin-bottom: 1rem;
  }
  .home_contact_container .flex_wrap_container .contact_one_restau_container .logo_container img {
    position: relative;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-80%, -50%);
  }
  .home_contact_container .flex_wrap_container .contact_one_restau_container .description_contact {
    position: relative;
    width: 100vw;
    margin: 1rem auto;
  }
  .home_contact_container .flex_wrap_container .contact_one_restau_container .description_contact .paragraph_container {
    position: relative;
  }
  .reverse {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .home_scrolling .restaurants_section_container {
    padding: 0;
  }
  .restaurant_section {
    display: block;
    flex-direction: column;
    padding: 0;
  }
  .restaurant_section .restau_photo_container {
    left: 0;
    margin: 0;
    width: 100vw !important;
    min-height: 12rem !important;
    padding: 10rem 0;
  }
  .restaurant_section .restau_photo_container .photo_restau {
    width: 100% !important;
    height: auto !important;
    background-size: cover !important;
  }
  .restaurant_section .description_container {
    width: 90vw !important;
    height: auto !important;
    padding: 0;
    margin: 0;
    min-height: none !important;
  }
  .restaurant_section .description_container h3 {
    margin: 0 !important;
  }
  .restaurant_section .description_container .text_container {
    width: 90% !important;
    font-size: 0.9;
    margin: 0 !important;
    padding: 0 !important;
  }
  .restaurant_section .description_container .text_container p {
    width: 100%;
    margin: 0.5rem 0 !important;
    font-size: 0.85rem !important;
  }
  .restaurant_section .description_container .text_container .separator {
    margin: 0.7rem auto 1rem auto !important;
  }
  .restaurant_section .description_container .text_container .see_more_btn {
    padding: 0.7rem 2rem !important;
  }
  .restaurant_section .description_container .restau_description_p {
    margin: 1rem 0;
    word-spacing: 1.9px;
    width: 100%;
    font-size: 0.9rem;
    line-height: 1rem;
  }
}
@media screen and (max-width: 320px) {
  .reverse {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .home_scrolling .restaurants_section_container {
    padding: 0;
  }
  .restaurant_section {
    display: block;
    flex-direction: column;
    padding: 0;
  }
  .restaurant_section .restau_photo_container {
    left: 0;
    margin: 0;
    width: 100vw !important;
    padding: 10rem 0;
  }
  .restaurant_section .restau_photo_container .photo_restau {
    width: 100% !important;
    height: auto !important;
    background-size: cover !important;
  }
  .restaurant_section .description_container {
    width: 90vw !important;
    height: auto !important;
    padding: 0;
    margin: 0;
    min-height: none !important;
    transform: translateX(-2%);
  }
  .restaurant_section .description_container h3 {
    margin: 0 !important;
  }
  .restaurant_section .description_container .text_container {
    width: 100% !important;
    font-size: 0.9;
    margin: 0 !important;
    padding: 0 !important;
  }
  .restaurant_section .description_container .text_container p {
    width: 100%;
    margin: 0.5rem 0 !important;
    font-size: 0.85rem !important;
  }
  .restaurant_section .description_container .text_container .separator {
    margin: 0.7rem auto 1rem auto !important;
  }
  .restaurant_section .description_container .text_container .see_more_btn {
    padding: 0.7rem 2rem !important;
  }
  .restaurant_section .description_container .restau_description_p {
    margin: 1rem 0;
    word-spacing: 1.9px;
    width: 100%;
    font-size: 0.9rem;
    line-height: 1rem;
  }
}
.page2_container {
  position: absolute;
  height: auto;
  max-width: 100vw;
  width: 100vw;
  top: 0;
  left: 0;
}

.scroll_container {
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}
.scroll_container .fixed_bg_jardin {
  background-image: url(../pictures/le_jardin/shooting/jardin.jpg);
  background-size: cover;
  background-position: 100% 40%;
  z-index: -1;
}
.scroll_container .scroll_header {
  position: relative;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.scroll_container .scroll_header h2 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translatex(-50%);
  font-family: "Noto Serif Georgian", serif;
  color: #fff;
  font-size: 3rem;
  letter-spacing: 3px;
  font-weight: 600;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.8);
}
.scroll_container .scroll_header h2 div {
  font-size: 2.5rem;
}
.scroll_container .scroll_header h4 {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  font-family: "Noto Serif Georgian", serif;
  color: #fff;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  font-size: 2rem;
  text-transform: capitalize;
}

.jardin_grid_container {
  width: 100vw;
  display: flex;
  background-color: #fff;
  padding: 3rem 0;
}
.jardin_grid_container .grid_jardin_left_side {
  width: 50%;
}
.jardin_grid_container .grid_jardin_left_side .title {
  width: 55%;
  font-size: 2.5rem;
  margin: 0 5rem 2rem auto;
}
.jardin_grid_container .grid_jardin_left_side .jardin_title {
  color: #678D58;
}
.jardin_grid_container .grid_jardin_left_side .ile_plate_title {
  color: #008dc7;
}
.jardin_grid_container .grid_jardin_left_side .riviere_title {
  color: #48434c;
}
.jardin_grid_container .grid_jardin_left_side .plage_title {
  color: #CDAA6D;
}
.jardin_grid_container .grid_jardin_left_side .image_batch_container {
  margin: 0 5rem 5rem auto;
}
.jardin_grid_container .grid_jardin_right_side {
  width: 50%;
}
.jardin_grid_container .grid_jardin_right_side .image_batch_container {
  margin: 0rem auto 5rem 5rem;
}
.jardin_grid_container .image_batch_container {
  width: 55%;
  color: #000;
}
.jardin_grid_container .image_batch_container .ile_plate_batch_title {
  color: #66CCCC;
}
.jardin_grid_container .image_batch_container .jardin_batch_title {
  color: #A6C48A;
}
.jardin_grid_container .image_batch_container .riviere_batch_title {
  color: #b7b9c1;
}
.jardin_grid_container .image_batch_container .plage_batch_title {
  color: #f6d7b0;
}
.jardin_grid_container .image_batch_container .landscape_image {
  width: 100%;
}
.jardin_grid_container .image_batch_container p {
  width: 100%;
  text-align: justify;
  margin: 1rem auto;
}
.jardin_grid_container .image_batch_container .portrait_image {
  width: 30%;
  margin: 0 auto;
}
.jardin_grid_container .image_batch_container .move1 {
  transform: translate(10%, 25%);
}
.jardin_grid_container .image_batch_container .move2 {
  transform: translate(20%, 50%);
}

.content3 {
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Noto Serif Georgian", serif;
  width: 100vw;
  height: auto;
  padding: 5rem 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
.content3 h2 {
  font-size: 2.5rem;
  letter-spacing: 2px;
}
.content3 div {
  font-size: 0.9rem;
  text-transform: uppercase;
  max-width: 14rem;
  margin: 0 auto;
  color: #fff;
  padding: 1rem 0rem;
  letter-spacing: 1px;
  word-spacing: 2px;
}
.content3 .reservation_btn_ile_plate {
  background-color: #008dc7;
  border: 2px solid #008dc7;
}
.content3 .reservation_btn_riviere {
  background-color: #b7b9c1;
  border: 2px solid #b7b9c1;
}
.content3 .reservation_btn_plage {
  background-color: #f6d7b0;
  border: 2px solid #f6d7b0;
}
.content3 .reservation_btn_jardin {
  background-color: #678D58;
  border: 2px solid #678D58;
}
.content3 h1 {
  margin-top: 6rem;
  font-size: 3.5rem;
  color: #fff;
}
.content3 .contact_h2 {
  color: #fff;
}

.mini_gallery_jardin {
  width: 100vw;
  background-color: #fff;
  padding: 3rem 0;
}
.mini_gallery_jardin .mini_gallery_wrap {
  max-width: 90%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  gap: 1.5rem;
}
.mini_gallery_jardin .mini_gallery_wrap img {
  width: 500px;
  height: 340px;
  background-size: cover;
}

@media screen and (min-width: 1171px) and (max-width: 1600px) {
  .scroll_container .scroll_header h2 {
    top: 35%;
    font-size: 3rem;
  }
  .scroll_container .scroll_header h2 div {
    font-size: 2.5rem;
  }
  .scroll_container .scroll_header h4 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1170px) {
  .fixed_bg_jardin {
    background-position: center;
  }
  .scroll_header h2 {
    top: 40%;
    font-size: 2.5rem;
  }
  .scroll_header h2 div {
    font-size: 2rem;
  }
  .scroll_header h4 {
    top: 55%;
    font-size: 2rem;
  }
  .jardin_grid_container .grid_jardin_left_side .title {
    text-align: center;
    width: 100%;
    font-size: 2rem;
  }
  .jardin_grid_container .grid_jardin_left_side .image_batch_container {
    margin: 0 auto 3rem auto;
  }
  .jardin_grid_container .grid_jardin_right_side {
    width: 50%;
    align-items: center;
    justify-content: center;
  }
  .jardin_grid_container .grid_jardin_right_side .image_batch_container {
    margin: 0 auto 3rem auto;
  }
  .jardin_grid_container .image_batch_container {
    width: 80%;
    margin: 0 auto;
  }
  .jardin_grid_container .image_batch_container p {
    font-size: 0.8rem;
    word-spacing: 4px;
    line-height: 1.5rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .fixed_bg_jardin {
    background-position: center;
  }
  .scroll_header h2 {
    top: 35%;
    max-width: 100%;
    font-size: 2.5rem;
  }
  .scroll_header h2 div {
    font-size: 2rem;
  }
  .scroll_header h4 {
    top: 50%;
    font-size: 1.5rem;
  }
  .jardin_grid_container {
    display: block;
  }
  .jardin_grid_container .grid_jardin_left_side {
    width: 100%;
    margin-bottom: 10rem;
  }
  .jardin_grid_container .grid_jardin_right_side {
    width: 100%;
  }
  .content3 .contact_h2 {
    font-size: 2rem;
  }
  .content3 div {
    font-size: 0.8rem;
    padding: 0.7rem 0rem;
  }
  .content3 h1 {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 649px) {
  .page2_container {
    max-width: 100vw;
  }
  .scroll_container {
    max-width: 100vw;
  }
  .fixed_bg_jardin {
    background-position: center;
    background-size: cover;
  }
  .scroll_header h2 {
    display: block;
    top: 35%;
    font-size: 2.5rem;
    width: 90%;
    line-height: 3rem;
  }
  .scroll_header h2 div {
    font-size: 1.5rem;
  }
  .scroll_header h4 {
    top: 50%;
    font-size: 1.5rem;
  }
  .jardin_grid_container {
    display: block;
  }
  .jardin_grid_container .grid_jardin_left_side {
    width: 100%;
    margin-bottom: 10rem;
  }
  .jardin_grid_container .grid_jardin_right_side {
    width: 100%;
  }
  .content3 .contact_h2 {
    font-size: 2rem;
  }
  .content3 div {
    font-size: 0.8rem;
    padding: 0.7rem 0rem;
  }
  .content3 h1 {
    font-size: 2.5rem;
  }
  .mini_gallery_jardin {
    width: 100vw;
    background-color: #fff;
    padding: 3rem 0;
  }
  .mini_gallery_jardin .mini_gallery_wrap {
    max-width: 90%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    gap: 1.5rem;
  }
  .mini_gallery_jardin .mini_gallery_wrap img {
    width: 100vw !important;
    height: auto !important;
    background-size: cover;
  }
}
@media screen and (max-width: 320px) {
  .page2_container {
    max-width: 100vw;
  }
  .scroll_container {
    max-width: 100vw;
  }
  .fixed_bg_jardin {
    background-position: center;
    background-size: cover;
  }
  .scroll_header h2 {
    display: block;
    top: 35%;
    font-size: 2.1rem;
    width: 90%;
    line-height: 3rem;
  }
  .scroll_header h2 div {
    font-size: 1.4rem;
  }
  .scroll_header h4 {
    top: 55%;
    font-size: 1.2rem;
  }
  .jardin_grid_container {
    display: block;
  }
  .jardin_grid_container .grid_jardin_left_side {
    width: 100%;
    margin-bottom: 10rem;
  }
  .jardin_grid_container .grid_jardin_left_side .title {
    font-size: 1.3rem;
  }
  .jardin_grid_container .grid_jardin_right_side {
    width: 100%;
  }
  .content3 .contact_h2 {
    font-size: 1.5rem;
  }
  .content3 div {
    font-size: 0.7rem;
    padding: 0.7rem 0rem;
  }
  .content3 h1 {
    font-size: 2rem;
  }
}
.gallery_hero {
  background-image: url(../pictures/equipe_compressed.jpg);
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 40%;
  height: 100vh;
  width: 100vw;
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;
}
.gallery_hero:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.galelry_title_container {
  transform: translate(0, 100%);
}
.galelry_title_container .gallery_title {
  text-transform: uppercase;
  color: #fff;
  font-size: 5rem;
  font-weight: 400;
  letter-spacing: 3px;
  margin: 0;
}
.galelry_title_container h3 {
  margin: 0;
  text-transform: initial;
  color: #fff;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: 2px;
}

.gallery_container {
  padding: 2rem 0;
  width: 100vw;
  height: auto;
  background-color: #fff;
}
.gallery_container .sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery_container .sliderWrap .close_btn {
  position: fixed;
  top: 1%;
  right: 1%;
  cursor: pointer;
  color: #fff;
  z-index: 99;
  opacity: 0.6;
}
.gallery_container .sliderWrap .close_btn:hover {
  opacity: 1;
  transition: all 0.5s ease-out;
}
.gallery_container .sliderWrap .prev_btn {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 2%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  padding: 0.7rem;
  cursor: pointer;
  color: #fff;
  z-index: 99;
  opacity: 0.6;
}
.gallery_container .sliderWrap .prev_btn:hover {
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.gallery_container .sliderWrap .next_btn {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 2%;
  cursor: pointer;
  color: #fff;
  z-index: 99;
  opacity: 0.6;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  padding: 0.7rem;
}
.gallery_container .sliderWrap .next_btn:hover {
  opacity: 0.8;
  transition: all 0.5s ease-out;
}
.gallery_container .sliderWrap .full_screen_img {
  max-width: 90vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery_container .sliderWrap .full_screen_img .img {
  max-width: 90vw;
  max-height: 100vh;
}

.gallery_wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 90%;
  height: auto;
}
.gallery_wrap .single {
  max-width: 300px;
  height: 200px;
}
.gallery_wrap .single:hover {
  cursor: pointer;
}
.gallery_wrap .single img {
  max-width: 100%;
  height: 100%;
  transition: all 0.5s ease-out;
}
.gallery_wrap .single img:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 1170px) {
  .gallery_container {
    top: 7rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .gallery_container {
    top: 4rem;
  }
}
@media screen and (max-width: 649px) {
  .gallery_hero {
    background-position: 46% 50% !important;
  }
  .gallery_container {
    top: 3rem;
  }
  .galelry_title_container {
    transform: translate(0, 150%);
    width: 100vw;
  }
  .galelry_title_container .gallery_title {
    font-size: 3rem;
  }
  .galelry_title_container h3 {
    font-size: 0.75rem;
    width: 95%;
  }
}
@media screen and (max-width: 320px) {
  .gallery_container {
    top: 3rem;
  }
  .galelry_title_container .gallery_title {
    font-size: 2.7rem;
  }
  .galelry_title_container h3 {
    font-size: 1rem;
  }
  .galelry_title_container {
    transform: translate(0, 150%);
    width: 100vw;
  }
  .galelry_title_container .gallery_title {
    font-size: 3rem;
  }
  .galelry_title_container h3 {
    font-size: 0.75rem;
    width: 95%;
  }
}
.hero_half_contact_jardin {
  background-image: url(../pictures/le_jardin/shooting/terrasse.jpg);
  background-size: cover;
  background-position: 100% 21%;
}

.contact_post1 {
  display: flex;
  width: 60vw;
  height: 30vh;
  margin: 5rem auto;
}
.contact_post1 .left_container {
  position: relative;
  width: 50%;
  align-items: center;
  text-align: center;
  border: 1px solid #000000;
}
.contact_post1 .left_container .text_container {
  max-width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  width: 80%;
}
.contact_post1 .left_container .text_container h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Noto Serif Georgian", serif;
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.7);
}
.contact_post1 .left_container .text_container .lejardin_color1 {
  color: #678D58;
}
.contact_post1 .left_container .text_container .contact_adress {
  font-family: "Noto Serif Georgian", serif;
  font-weight: 300;
  font-size: 0.8125rem;
}
.contact_post1 .left_container .text_container .contact_phone {
  color: #A6C48A;
}
.contact_post1 .right_container {
  width: 50%;
  height: 100%;
}
.contact_post1 .right_container .contact_gmap {
  width: 100%;
  height: 100%;
  border: none;
}

.contact_post2, .contact_post2_cata {
  width: 100vw;
  position: relative;
}
.contact_post2 .text_container, .contact_post2_cata .text_container {
  width: 30vw;
  text-align: center;
  margin: 2rem auto;
}
.contact_post2 .text_container h2, .contact_post2_cata .text_container h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Noto Serif Georgian", serif;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 3px;
}
.contact_post2 .text_container .divider_lejardin, .contact_post2_cata .text_container .divider_lejardin {
  background-color: #678D58;
}
.contact_post2 .text_container P, .contact_post2_cata .text_container P {
  font-family: "Noto Serif Georgian", serif;
  font-size: 0.8rem;
  font-weight: 350;
}
.contact_post2 .text_container P span, .contact_post2_cata .text_container P span {
  color: #862b2b;
  font-weight: 600;
}
.contact_post2 .text_container P .span_resa_cata, .contact_post2_cata .text_container P .span_resa_cata {
  color: #008dc7;
}

.contact_else {
  width: 100vw;
  height: auto;
  margin: 5rem 0 10rem 0;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.contact_else h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Noto Serif Georgian", serif;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 3px;
}
.contact_else .contact_else_container {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  width: 90vw;
  height: auto;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.contact_else .contact_else_container .contact_cata {
  background-image: url(../pictures/le_catamaran/glasses.jpg);
  background-size: cover;
  background-position: center;
  height: 15rem;
  width: 30%;
  min-width: 25rem;
  margin: 2rem 0;
  position: relative;
  transition: all 0.5s ease-out;
}
.contact_else .contact_else_container .contact_cata:hover {
  transform: scale(1.02);
}
.contact_else .contact_else_container .contact_cata img {
  max-height: 100%;
  height: 80%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.contact_else .contact_else_container .contact_plage {
  background-image: url(../pictures/la_plage/sand_wave_zoom.jpg);
  background-size: cover;
  background-position: center;
  height: 15rem;
  width: 30%;
  min-width: 25rem;
  margin: 2rem 0;
  position: relative;
  transition: all 0.5s ease-out;
}
.contact_else .contact_else_container .contact_plage:hover {
  transform: scale(1.02);
}
.contact_else .contact_else_container .contact_plage img {
  max-height: 100%;
  height: 80%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.contact_else .contact_else_container .contact_jardin {
  background-image: url(../pictures/le_jardin/shooting/allée_bas.jpg);
  background-size: cover;
  background-position: center;
  height: 15rem;
  width: 30%;
  min-width: 25rem;
  margin: 2rem 0;
  position: relative;
  transition: all 0.5s ease-out;
}
.contact_else .contact_else_container .contact_jardin:hover {
  transform: scale(1.02);
}
.contact_else .contact_else_container .contact_jardin img {
  max-height: 100%;
  height: 60%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.contact_else .contact_else_container .contact_riviere {
  background-image: url(../pictures/la_riviere/love.jpg);
  background-size: cover;
  background-position: center;
  height: 15rem;
  width: 30%;
  min-width: 25rem;
  margin: 2rem 0;
  position: relative;
  transition: all 0.5s ease-out;
}
.contact_else .contact_else_container .contact_riviere:hover {
  transform: scale(1.02);
}
.contact_else .contact_else_container .contact_riviere img {
  max-height: 100%;
  height: 80%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.contact_jardin_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../pictures/le_jardin/shooting/allée_bas.jpg);
  background-size: cover;
  background-position: 50% 80%;
}

.contact_post3 {
  width: 100vw;
  display: flex;
  margin: 5rem auto 0 auto;
  justify-content: center;
  padding: 1rem 0;
}
.contact_post3 .image_salade {
  position: absolute;
  top: 0;
  left: 10rem;
  height: 15rem;
  transform: translateY(-70%);
  z-index: 3;
}
.contact_post3 .contact_post3_left {
  width: 25%;
  align-items: center;
  text-align: center;
  margin: 2rem 0;
  z-index: 2;
}
.contact_post3 .contact_post3_left .text_container h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Noto Serif Georgian", serif;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 3px;
  color: rgba(255, 255, 255, 0.5);
}
.contact_post3 .contact_post3_left .text_container .practical_infos h3 {
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Noto Serif Georgian", serif;
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
  color: #fff;
  margin: 0;
}
.contact_post3 .contact_post3_left .text_container .practical_infos p {
  font-family: "Noto Serif Georgian", serif;
  margin-bottom: 1.5rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}

.contact_openings, .contact_openings_riviere, .contact_openings_plage, .contact_openings_cata {
  width: 25%;
  align-items: center;
  text-align: center;
  border-top: 1px solid #A6C48A;
  border-right: 1px solid #A6C48A;
  border-bottom: 1px solid #A6C48A;
  margin: 1rem 0;
  z-index: 2;
}
.contact_openings h2, .contact_openings_riviere h2, .contact_openings_plage h2, .contact_openings_cata h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Noto Serif Georgian", serif;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 3px;
  color: #fff;
}
.contact_openings .day_wrap, .contact_openings_riviere .day_wrap, .contact_openings_plage .day_wrap, .contact_openings_cata .day_wrap {
  display: flex;
  margin: 0.2rem auto;
  width: 70%;
  align-content: center;
  text-align: center;
  justify-content: space-between;
  position: relative;
  color: #fff;
}
.contact_openings .day_wrap .day span, .contact_openings_riviere .day_wrap .day span, .contact_openings_plage .day_wrap .day span, .contact_openings_cata .day_wrap .day span {
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Noto Serif Georgian", serif;
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
  color: rgba(255, 255, 255, 0.5);
}
.contact_openings .day_wrap .hours, .contact_openings_riviere .day_wrap .hours, .contact_openings_plage .day_wrap .hours, .contact_openings_cata .day_wrap .hours {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(50%);
  font-family: "Noto Serif Georgian", serif;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1rem;
  height: auto;
  align-items: center;
  text-align: center;
}
.contact_openings .day_wrap .hours .hour, .contact_openings_riviere .day_wrap .hours .hour, .contact_openings_plage .day_wrap .hours .hour, .contact_openings_cata .day_wrap .hours .hour {
  font-family: "Noto Serif Georgian", serif;
  margin-bottom: 1.5rem;
  font-size: 0.8125rem;
  font-weight: 400;
}
.contact_openings .day_wrap .hours .hours_separator, .contact_openings_riviere .day_wrap .hours .hours_separator, .contact_openings_plage .day_wrap .hours .hours_separator, .contact_openings_cata .day_wrap .hours .hours_separator {
  margin: 0 0.5rem;
}

.divider, .divider_riviere, .divider_laplage, .divider_lecata, .contact_post2 .text_container .divider_lejardin, .contact_post2_cata .text_container .divider_lejardin, .contact_else .divider_lejardin {
  width: 20%;
  background-color: #A6C48A;
  border: none;
  height: 0.5px;
}

.closed_icon, .icon_fermé_riviere, .icon_fermé_plage, .icon_fermé_cata {
  color: #A6C48A;
  transform: translateY(10%);
  margin-right: 0.3rem;
}

@media screen and (min-width: 1171px) and (max-width: 1600px) {
  .contact_post1 {
    width: 80vw;
  }
  .contact_post2 .text_container, .contact_post2_cata .text_container {
    width: 40vw;
  }
  .contact_else {
    margin: 3rem 0 5rem 0;
  }
  .contact_else h2 {
    font-size: 1rem;
  }
  .contact_else .contact_else_container {
    gap: 0;
  }
  .contact_else .contact_else_container .contact_plage {
    min-width: none;
    width: 60%;
  }
  .contact_else .contact_else_container .contact_cata {
    min-width: none;
    width: 60%;
  }
  .contact_else .contact_else_container .contact_jardin {
    min-width: none;
    width: 60%;
  }
  .contact_else .contact_else_container .contact_riviere {
    min-width: none;
    width: 60%;
  }
  .contact_post3 .image_salade {
    top: 5%;
    left: 5%;
    height: 10rem;
    transform: translateY(-70%);
  }
  .contact_post3 .contact_post3_left {
    width: 35%;
  }
  .contact_post3 .contact_openings, .contact_post3 .contact_openings_riviere, .contact_post3 .contact_openings_plage, .contact_post3 .contact_openings_cata {
    width: 35%;
  }
  .contact_post3 .contact_openings .day_wrap, .contact_post3 .contact_openings_riviere .day_wrap, .contact_post3 .contact_openings_plage .day_wrap, .contact_post3 .contact_openings_cata .day_wrap {
    max-width: 20rem;
  }
}
@media screen and (max-width: 1170px) {
  .contact_post1 {
    width: 80vw;
  }
  .contact_post2 .text_container, .contact_post2_cata .text_container {
    width: 40vw;
  }
  .contact_else {
    margin: 3rem 0 5rem 0;
  }
  .contact_else h2 {
    font-size: 1rem;
  }
  .contact_else .contact_else_container {
    gap: 0;
  }
  .contact_else .contact_else_container .contact_plage {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_cata {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_jardin {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_riviere {
    min-width: none;
    width: 90%;
  }
  .contact_post3 {
    width: 100vw;
    flex-direction: column;
  }
  .contact_post3 .image_salade {
    top: 5%;
    left: 2%;
    height: 10rem;
    transform: translateY(-70%);
  }
  .contact_post3 .contact_post3_left {
    width: 60%;
    margin: 0 auto;
  }
  .contact_post3 .contact_post3_left .text_container {
    padding: 1rem;
  }
  .contact_post3 .contact_post3_left .text_container h2 {
    font-size: 1.1rem;
  }
  .contact_post3 .contact_openings, .contact_post3 .contact_openings_riviere, .contact_post3 .contact_openings_plage, .contact_post3 .contact_openings_cata {
    width: 60%;
    margin: 0 auto;
  }
  .contact_post3 .contact_openings h2, .contact_post3 .contact_openings_riviere h2, .contact_post3 .contact_openings_plage h2, .contact_post3 .contact_openings_cata h2 {
    font-size: 1.1rem;
  }
  .contact_post3 .contact_openings .day_wrap, .contact_post3 .contact_openings_riviere .day_wrap, .contact_post3 .contact_openings_plage .day_wrap, .contact_post3 .contact_openings_cata .day_wrap {
    max-width: 20rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .contact_post1 {
    flex-direction: column;
    height: 70vh;
    width: 70vw;
    margin: 5rem auto;
  }
  .contact_post1 .left_container {
    height: 50%;
    width: 100%;
  }
  .contact_post1 .right_container {
    height: 50%;
    width: 100%;
  }
  .contact_else {
    margin: 3rem 0 5rem 0;
  }
  .contact_else h2 {
    font-size: 1rem;
  }
  .contact_else .contact_else_container {
    gap: 0;
  }
  .contact_else .contact_else_container .contact_plage {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_cata {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_jardin {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_riviere {
    min-width: none;
    width: 90%;
  }
  .contact_post3 {
    flex-direction: column;
    width: 100vw;
  }
  .contact_post3 .image_salade {
    top: 2%;
    left: 2%;
    height: 8rem;
    transform: translateY(-70%);
  }
  .contact_post3 .contact_post3_left {
    width: 70%;
  }
  .contact_post3 .contact_openings, .contact_post3 .contact_openings_riviere, .contact_post3 .contact_openings_plage, .contact_post3 .contact_openings_cata {
    width: 70%;
  }
}
@media screen and (max-width: 649px) {
  .contact_post1 {
    flex-direction: column;
    height: 70vh;
    width: 70vw;
    margin: 3rem auto;
  }
  .contact_post1 .left_container {
    height: 50%;
    width: 100%;
  }
  .contact_post1 .left_container .text_container h3 {
    font-size: 0.9rem;
  }
  .contact_post1 .left_container .text_container .contact_adress {
    font-size: 0.7rem;
  }
  .contact_post1 .right_container {
    height: 50%;
    width: 100%;
  }
  .contact_post2 .text_container, .contact_post2_cata .text_container {
    width: 70vw;
  }
  .contact_post2 .text_container h2, .contact_post2_cata .text_container h2 {
    font-size: 1rem;
  }
  .contact_post2 .text_container p, .contact_post2_cata .text_container p {
    font-size: 0.7rem;
  }
  .contact_else {
    margin: 3rem 0 5rem 0;
  }
  .contact_else h2 {
    font-size: 1rem;
  }
  .contact_else .contact_else_container {
    gap: 0;
  }
  .contact_else .contact_else_container .contact_plage {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_cata {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_jardin {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_riviere {
    min-width: none;
    width: 90%;
  }
  .contact_post3 {
    flex-direction: column;
    width: 100vw;
  }
  .contact_post3 .image_salade {
    top: 0%;
    left: 2%;
    height: 6rem;
    transform: translateY(-70%);
  }
  .contact_post3 .contact_post3_left {
    width: 90%;
  }
  .contact_post3 .contact_post3_left .text_container {
    padding: 0.5rem;
  }
  .contact_post3 .contact_openings, .contact_post3 .contact_openings_riviere, .contact_post3 .contact_openings_plage, .contact_post3 .contact_openings_cata {
    width: 90%;
  }
}
@media screen and (max-width: 320px) {
  .contact_post1 {
    flex-direction: column;
    height: 90vh;
    width: 70vw;
    margin: 3rem auto;
  }
  .contact_post1 .left_container {
    height: 50%;
    width: 100%;
    padding: 1rem 0;
  }
  .contact_post1 .left_container .text_container h3 {
    font-size: 0.9rem;
  }
  .contact_post1 .left_container .text_container .contact_adress {
    font-size: 0.7rem;
  }
  .contact_post1 .right_container {
    height: 60%;
    width: 100%;
  }
  .contact_post1 .right_container .contact_gmap {
    font-size: 1rem;
  }
  .contact_post2 .text_container, .contact_post2_cata .text_container {
    width: 70vw;
  }
  .contact_post2 .text_container h2, .contact_post2_cata .text_container h2 {
    font-size: 1rem;
  }
  .contact_post2 .text_container p, .contact_post2_cata .text_container p {
    font-size: 0.7rem;
  }
  .contact_else {
    margin: 3rem 0 5rem 0;
  }
  .contact_else h2 {
    font-size: 1rem;
  }
  .contact_else .contact_else_container {
    gap: 0;
  }
  .contact_else .contact_else_container .contact_plage {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_cata {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_jardin {
    min-width: none;
    width: 90%;
  }
  .contact_else .contact_else_container .contact_riviere {
    min-width: none;
    width: 90%;
  }
  .contact_post3 {
    flex-direction: column;
    width: 100vw;
  }
  .contact_post3 .image_salade {
    top: 0%;
    left: 2%;
    height: 6rem;
    transform: translateY(-70%);
  }
  .contact_post3 .contact_post3_left {
    width: 90%;
  }
  .contact_post3 .contact_post3_left .text_container {
    padding: 0.5rem;
  }
  .contact_post3 .contact_openings, .contact_post3 .contact_openings_riviere, .contact_post3 .contact_openings_plage, .contact_post3 .contact_openings_cata {
    width: 90%;
  }
}
.autoswipe_container {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.autoswipe_container .swiper {
  width: 100%;
  height: 100%;
}
.autoswipe_container .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;
  border: none;
  height: 100%;
  width: 100%;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.autoswipe_container .swiper-slide img {
  max-width: 100%;
  height: 100%;
  vertical-align: top;
  border-style: none;
  background-position: center;
}

.swiper-button-next {
  color: rgba(0, 0, 0, 0.7);
}

.swiper-button-prev {
  color: rgba(0, 0, 0, 0.7);
}

.swiper-pagination-bullet-active {
  background-color: rgba(0, 0, 0, 0.7);
}

.contact_form_container {
  min-width: 35rem;
  width: 40%;
  margin: 0 auto;
}

.reservation_form_container {
  align-items: center;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
}
.reservation_form_container .reservation_flash_msg {
  font-style: italic;
  margin: 0.8rem;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.5);
}
.reservation_form_container .reservation_form {
  display: flex;
  flex-direction: column;
}
.reservation_form_container .reservation_form .contact_form_inputgrp {
  display: flex;
}
.reservation_form_container .reservation_form .reservation_input {
  text-decoration: none;
  resize: none;
  width: 45%;
  margin: 1rem;
  padding: 0.5rem;
  font-family: "Roboto", sans-serif;
  transition: all 0.5s ease-out;
}
.reservation_form_container .reservation_form .reservation_input :-webkit-autofill,
.reservation_form_container .reservation_form .reservation_input :-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.reservation_form_container .reservation_form .reservation_comment {
  text-decoration: none;
  resize: none;
  margin: 0.5rem;
  padding: 0.5rem;
  height: 5rem;
  text-align: left;
  font-family: "Roboto", sans-serif;
  transition: all 0.5s ease-out;
}
.reservation_form_container .reservation_form .reservation_submit {
  margin: 1rem auto;
  width: 100%;
  padding: 0.5rem 0.9rem;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
}

@media screen and (max-width: 1170px) {
  .contact_form_container {
    width: 60%;
    margin: 0 auto;
  }
  .annonce1 h2 {
    font-size: 1.3rem;
  }
  .annonce1 span {
    font-size: 1.3rem;
  }
  .annonce2 {
    width: 100vw;
  }
  .annonce2 .annonce2_subcontainer .reserve_photo {
    width: 70%;
    height: 40vh;
  }
  .annonce2 .annonce2_subcontainer .info_container h2 {
    font-size: 1.3rem;
  }
  .annonce2 .annonce2_subcontainer .info_container p {
    font-size: 0.9rem;
  }
  .annonce2 .reservation_form_container .reservation_flash_msg {
    margin: 0;
    font-size: 0.7rem;
    transform: translate(-5%, -170%);
  }
  .annonce2 .reservation_form_container .reservation_form .reservation_input {
    font-size: 0.8rem;
  }
  .annonce2 .reservation_form_container .reservation_form .reservation_comment {
    font-size: 0.8rem;
  }
  .annonce2 .reservation_form_container .reservation_form .reservation_submit {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .contact_form_container {
    width: 60%;
    margin: 0 auto;
  }
  .annonce2 {
    display: block;
    justify-content: center;
  }
  .annonce2 .annonce2_subcontainer {
    width: 100%;
  }
  .annonce2 .reservation_form_container {
    margin-top: 3rem;
    width: 100%;
  }
  .annonce2 .reservation_form_container .reservation_flash_msg {
    transform: translate(0%, -100%);
  }
  .annonce2 .reservation_form_container .reservation_form {
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 649px) {
  .contact_form_container {
    min-width: 100vw;
    width: 100vw;
    padding: 0;
  }
  .reservation_form_container {
    width: 70%;
    max-width: 90vw;
    margin: 1rem auto;
  }
  .reservation_flash_msg {
    font-size: 0.8rem;
  }
  .reservation_input {
    font-size: 0.75rem;
  }
  .reservation_comment {
    font-size: 0.75rem;
  }
}
.testhover {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 5rem;
}

.cata_title_container {
  height: 100vh;
  width: 100vw;
  text-align: center;
}

.cata_title {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 3rem;
  color: #fff;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  word-spacing: 10px;
  letter-spacing: 2px;
}

.cata_subtitle {
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translateX(-50%);
  color: #fff;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  word-spacing: 10px;
  font-size: 2rem;
  text-align: center;
  width: 54rem;
}

.cata_comingsoon {
  font-weight: 450;
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 1.2rem;
  transform: translateX(-50%);
  color: #fff;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  word-spacing: 10px;
  letter-spacing: 1px;
}

.btn_cata1 {
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 60%;
  transform: translateX(-50%);
  text-decoration: none;
  color: #fff;
  background-color: #008dc7;
  border-color: #008dc7;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1.5rem;
  letter-spacing: 1.5px;
  word-spacing: 3px;
  width: 15rem;
  text-align: center;
  transition: all 0.4s ease;
  z-index: 10;
  transition: all 0.5s ease-out;
}
.btn_cata1:hover {
  scale: 1.02;
}

.fixed_bg_cata {
  background-image: url(../pictures/le_catamaran/night/sunset1.jpg);
  background-size: cover;
  background-position: 50% 0%;
  z-index: -1;
}

.half {
  position: absolute;
  width: 50vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 1000ms ease-in-out;
}

.left_side {
  left: 0;
  background-image: url(../pictures/le_catamaran/night/sunset1.jpg);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  transition: all 1000ms ease-in-out;
}
.left_side .btn2:hover {
  background-color: #dd8808;
  border-color: #dd8808;
  transition: all 0.4s ease;
}

.hover_left_side .left_side {
  width: 53%;
}

.hover_right_side .right_side {
  width: 53%;
}

@media screen and (min-width: 1171px) and (max-width: 1600px) {
  .cata_title_container .cata_subtitle {
    font-size: 1.7rem;
    width: 46.5rem;
    margin: 0 auto;
    top: 45%;
  }
}
@media screen and (max-width: 1170px) {
  .cata_title_container .cata_title {
    font-size: 2.5rem;
  }
  .cata_title_container .cata_subtitle {
    font-size: 1.5rem;
    width: 41.5rem;
    margin: 0 auto;
    top: 43%;
  }
  .cata_title_container .cata_comingsoon {
    top: 47%;
    font-size: 1.1rem;
  }
  .cata_title_container .btn_cata1 {
    font-size: 0.9rem;
    font-weight: 500;
    top: 60%;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .cata_title_container .cata_title {
    top: 25%;
  }
  .cata_title_container .cata_subtitle {
    font-size: 1.3rem;
    width: 36.5rem;
    margin: 0 auto;
    top: 40%;
  }
  .cata_title_container .cata_comingsoon {
    font-size: 1rem;
  }
  .cata_title_container .btn_cata1 {
    width: 13rem;
    top: 50%;
  }
}
@media screen and (max-width: 649px) {
  .cata_title_container {
    width: 100vw;
    height: 100vh;
  }
  .cata_title_container .cata_title {
    font-size: 1.7rem;
    max-width: 95vw;
    top: 20%;
  }
  .cata_title_container .cata_subtitle {
    font-size: 1rem;
    max-width: 99vw;
    margin: 0 auto;
    top: 28%;
  }
  .cata_title_container .cata_comingsoon {
    font-size: 0.7rem;
    width: 15.5rem;
    white-space: nowrap;
    top: 50%;
  }
  .cata_title_container .btn_cata1 {
    padding: 1.2rem;
    white-space: nowrap;
    width: 13rem;
    top: 40%;
  }
}
@media screen and (max-width: 320px) {
  .cata_title_container {
    width: 100vw;
  }
  .cata_title_container .cata_title {
    font-size: 1.5rem;
    max-width: 95vw;
    top: 15%;
  }
  .cata_title_container .cata_subtitle {
    font-size: 0.9rem;
    max-width: 95vw;
    margin: 0 auto;
    top: 25%;
  }
  .cata_title_container .cata_comingsoon {
    font-size: 0.7rem;
    width: 15.5rem;
    white-space: nowrap;
    top: 45%;
  }
  .cata_title_container .btn_cata1 {
    padding: 1rem;
    white-space: nowrap;
    width: 10rem;
    font-size: 0.8rem;
    top: 40%;
  }
}
.hero_half_contact_cata {
  background-image: url(../pictures/le_catamaran/night/mauritius_sunset.jpg);
  background-position: 100% 50%;
}

.contact_post2_cata {
  min-height: none;
}

.span_resa_cata {
  color: #a84613;
}

.lecata_gmap {
  width: 100%;
  height: 100%;
  border: none;
}

.lecata_ocean {
  color: #008dc7;
  text-transform: uppercase;
}

.contact_phone_lecata {
  color: #66CCCC;
}

.divider_lecata {
  background-color: #66CCCC;
}

.icon_fermé_cata {
  color: #66CCCC;
}

.contact_openings_cata {
  border-top: 1px solid #66CCCC;
  border-right: 1px solid #66CCCC;
  border-bottom: 1px solid #66CCCC;
}

.contact_post3 {
  position: relative;
}

.ile_flottante_image {
  position: absolute;
  top: 0;
  left: 4%;
  height: 13rem;
  transform: translateY(-70%);
}

@media screen and (min-width: 1171px) and (max-width: 1600px) {
  .ile_flottante_image {
    top: 2%;
    left: 3%;
    height: 10rem;
  }
}
@media screen and (max-width: 1170px) {
  .ile_flottante_image {
    top: 2%;
    left: 3%;
    height: 8rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .ile_flottante_image {
    top: 0%;
    left: 3%;
    height: 7rem;
  }
}
@media screen and (max-width: 649px) {
  .ile_flottante_image {
    top: 0%;
    left: 3%;
    height: 5rem;
  }
}
.offers_container {
  width: 100vw;
  min-height: 80vh;
  margin: 11rem auto 3rem auto;
  display: block;
}
.offers_container .img_container_night {
  width: 70%;
  height: 60vh;
  margin: 0 auto;
}
.offers_container .img_container_night .img {
  background-image: url(../pictures/le_catamaran/night/catamaran_night.jpg);
  background-size: cover;
  background-position: 10% 50%;
  width: 95%;
  height: 95%;
  position: relative;
}
.offers_container .img_container_night .img:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: rgba(168, 70, 19, 0.9);
}
.offers_container .img_container_night .img .concept_description_container {
  position: absolute;
  right: 7.5%;
  top: 50%;
  transform: translateY(-50%);
  width: 35%;
  max-height: 95%;
  height: auto;
  text-align: center;
  padding: 0;
}
.offers_container .img_container_night .img .concept_description_container .concept_description {
  font-weight: 300;
  margin: 1.5rem 0;
  font-size: 1rem;
  word-spacing: 8px;
  line-height: 1.4rem;
  font-weight: 300;
  color: white;
  margin: 2rem 0;
  text-align: justify;
}
.offers_container .img_container_night .img .quote_icon {
  position: absolute;
  color: white;
}
.offers_container .img_container_night .img .begin {
  top: 10%;
  right: 40%;
}
.offers_container .img_container_night .img .end {
  bottom: 10%;
  right: 7.5%;
}
.offers_container .card_offer_container_night {
  padding-left: 2rem;
  width: 70%;
  height: auto;
  margin: 0 auto;
}
.offers_container .card_offer_container_night .offer_derscription_container .offer_title {
  color: #a84613;
  font-size: 2rem;
  margin-bottom: 1rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_category_title {
  color: #a84613;
  font-size: 1.7rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
  width: 70vw;
  border-bottom: 1px solid #a84613;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .intro_night {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin: 0.5rem;
  font-size: 0.9rem;
  text-align: justify;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .overview {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  line-height: 0.1rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container .cata_icon {
  color: #a84613;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container p {
  font-size: 0.85rem;
  margin-left: 1rem;
  color: #000;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container p span {
  color: rgba(0, 0, 0, 0.5);
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container p .green {
  color: #678D58;
  margin: 0 0.15rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container p .red {
  color: #862b2b;
  margin: 0 0.15rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .description {
  margin-bottom: 2rem;
  display: flex;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .golden {
  width: 50%;
  padding-right: 0.5rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .infinite {
  width: 50%;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container h3 {
  color: #d18f35;
  margin: 0;
  text-transform: capitalize;
  font-size: 1.3rem;
  letter-spacing: 1.5px;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container h4 {
  font-size: 1rem;
  margin: 0.5rem 0 0 0;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container .menu2_container {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  line-height: 0.7rem;
  align-items: center;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container .menu2_container p {
  margin: 0.5rem;
  font-size: 0.82rem;
  line-height: 0.5rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container .menu2_container .divide {
  background-color: #a84613;
  height: 10px;
  width: 1px;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: flex-start;
  text-align: start;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or {
  width: 30%;
  margin-right: 1.5rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h3 {
  color: #d18f35;
  margin: 0;
  text-transform: capitalize;
  font-size: 1.3rem;
  letter-spacing: 1.5px;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h4 {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.6);
  margin: 1rem 0 1rem 0;
  text-transform: capitalize;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h5 {
  font-weight: 400;
  margin: 1rem 0;
  font-size: 0.82rem;
  line-height: 0.5rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container {
  width: 100%;
  justify-content: center;
  text-align: flex-start;
  margin: 0.5rem 0;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container h5 {
  font-weight: 400;
  margin: 0.5rem;
  font-size: 0.82rem;
  line-height: 0.5rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.5rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container .divide_or {
  height: 1px;
  width: 2rem;
  background-color: #a84613;
  margin: 0 0rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container p {
  margin: 0.5rem;
  font-size: 0.8rem;
  line-height: 0.5rem;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .btn_tocontact {
  padding: 0.4rem 1rem;
  border: 1px solid #a84613;
  text-decoration: none;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #a84613;
  letter-spacing: 2px;
  transition: all 0.3s ease-in-out;
}
.offers_container .card_offer_container_night .offer_derscription_container .description_container .btn_tocontact:hover {
  cursor: pointer;
  background-color: #a84613;
  color: #fff;
}

@media screen and (min-width: 1171px) and (max-width: 1600px) {
  .offers_container {
    margin: 10rem auto 3rem auto;
    display: block;
    width: 90vw;
    height: auto;
  }
  .offers_container .img_container {
    width: 100%;
    height: 50vh;
  }
  .offers_container .card_offer_container {
    margin: 1rem 0;
  }
  .offers_container .card_offer_container .offer_derscription_container {
    width: 100%;
    margin: 0 auto;
  }
  .offers_container .card_offer_container .offer_derscription_container .offer_title {
    margin-top: 1rem;
    font-size: 2rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container {
    margin: 0 auto;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .overview {
    width: 100%;
    gap: 0.5rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .overview .icon_container p {
    font-size: 0.8rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .description p {
    text-align: justify;
    font-size: 0.8rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .description .menu_container h3 {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .description .menu_container .menu2_container {
    justify-content: left;
    width: auto;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .btn_tocontact {
    font-size: 1rem;
  }
  .offers_container .img_container_night {
    width: 100%;
    padding: 0;
  }
  .offers_container .img_container_night .img .concept_description_container .concept_description {
    line-height: 1.6rem;
  }
  .offers_container .card_offer_container_night {
    margin: 1rem 0;
    width: 86vw;
    padding: 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .offer_title {
    margin-left: 0;
    margin-top: 1rem;
    font-size: 2rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview {
    width: 100%;
    gap: 0.5rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container .cata_icon {
    margin-right: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container p {
    font-size: 0.8rem;
    line-height: 1rem;
    margin: 0.5rem 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container .menu2_container {
    display: block;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container .menu2_container p {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container {
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or {
    width: 30%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h3 {
    margin-left: 0.1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container {
    text-align: flex-start;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container h5 {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container {
    justify-content: left;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container h5 {
    margin: 0 0.5rem;
  }
}
@media screen and (max-width: 1170px) {
  .offers_container {
    margin: 9rem auto 3rem auto;
    display: block;
    width: 90vw;
    height: auto;
  }
  .offers_container .img_container {
    width: 100%;
    height: 40vh;
  }
  .offers_container .card_offer_container {
    margin: 1rem 0;
  }
  .offers_container .card_offer_container .offer_derscription_container {
    width: 100%;
    margin: 0 auto;
  }
  .offers_container .card_offer_container .offer_derscription_container .offer_title {
    margin-top: 1rem;
    font-size: 2rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container {
    margin: 0 auto;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .overview {
    width: 100%;
    gap: 0.5rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .overview .icon_container p {
    font-size: 0.8rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .description p {
    text-align: justify;
    font-size: 0.8rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .description .menu_container h3 {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .description .menu_container .menu2_container {
    justify-content: left;
    width: auto;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .btn_tocontact {
    font-size: 1rem;
  }
  .offers_container .img_container_night {
    width: 100%;
    height: 40vh;
    background-position: 100% 40%;
    padding: 0;
  }
  .offers_container .img_container_night .img .concept_description_container .concept_description {
    line-height: 1.1rem;
    font-size: 0.9rem;
    margin: 1rem 0;
    right: 7.5%;
  }
  .offers_container .img_container_night .img .begin {
    top: 5%;
    height: 2rem;
  }
  .offers_container .img_container_night .img .end {
    bottom: 5%;
    height: 2rem;
  }
  .offers_container .card_offer_container_night {
    margin: 1rem 0;
    width: 86vw;
    padding: 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .offer_title {
    margin-left: 0;
    margin-top: 1rem;
    font-size: 2rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview {
    width: 100%;
    gap: 0.5rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container .cata_icon {
    margin-right: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container p {
    font-size: 0.8rem;
    line-height: 1rem;
    margin: 0.5rem 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container .menu2_container {
    display: block;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container .menu2_container p {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container {
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or {
    width: 30%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h3 {
    margin-left: 0.1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container {
    text-align: flex-start;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container h5 {
    font-size: 0.8rem;
    line-height: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container {
    justify-content: left;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container h5 {
    margin: 0 0.5rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .offers_container {
    margin: 6rem auto 3rem auto;
    display: block;
    width: 95vw;
  }
  .offers_container .img_container {
    width: 100%;
    height: 40vh;
  }
  .offers_container .card_offer_container {
    margin: 1rem 0;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .intro_night {
    font-size: 1rem;
    color: red;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .description .menu_container .menu2_container {
    display: flex;
    flex-wrap: wrap;
    gap: 0rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .btn_tocontact {
    font-size: 0.9rem;
  }
  .offers_container .img_container_night {
    width: 100%;
    height: 40vh;
    background-position: 100% 40%;
  }
  .offers_container .img_container_night .img .concept_description_container {
    width: 40%;
    right: 5%;
  }
  .offers_container .img_container_night .img .concept_description_container .concept_description {
    line-height: 1.1rem;
    font-size: 0.85rem;
  }
  .offers_container .img_container_night .img .begin {
    top: 5%;
    height: 2rem;
  }
  .offers_container .img_container_night .img .end {
    bottom: 5%;
    height: 2rem;
  }
  .offers_container .card_offer_container_night {
    margin: 1rem 0;
    padding: 0;
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .offer_title {
    margin-left: 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container .cata_icon {
    margin-right: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container p {
    line-height: 1rem;
    margin: 0.5rem 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container .menu2_container p {
    line-height: 0.9rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container {
    width: 100vw;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or {
    width: 33.3%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h3 {
    font-size: 1.1rem;
    letter-spacing: 0.5px;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h4 {
    margin: 0.5rem 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container {
    margin: 0.2rem 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .btn_tocontact {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 649px) {
  .offers_container {
    margin: 6rem auto 3rem auto;
    display: block;
    width: 90vw;
  }
  .offers_container .img_container {
    width: 100%;
    height: 40vh;
  }
  .offers_container .card_offer_container {
    margin: 1rem 0;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .description .menu_container .menu2_container {
    display: flex;
    flex-wrap: wrap;
    gap: 0rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .btn_tocontact {
    font-size: 0.8rem;
  }
  .offers_container .img_container_night {
    width: 100%;
    height: 50vh;
    min-height: 25rem;
    background-position: 100% 40%;
  }
  .offers_container .img_container_night .img {
    background-position: 45% 50%;
  }
  .offers_container .img_container_night .img:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    background-color: rgba(168, 70, 19, 0.9);
  }
  .offers_container .img_container_night .img .concept_description_container {
    width: 55%;
    right: 2.5%;
  }
  .offers_container .img_container_night .img .concept_description_container .concept_description {
    line-height: 1rem;
    font-size: 0.75rem;
  }
  .offers_container .img_container_night .img .begin {
    height: 1rem;
    right: 50%;
  }
  .offers_container .img_container_night .img .end {
    height: 1rem;
    right: 0%;
  }
  .offers_container .card_offer_container_night {
    margin: 1rem 0;
    padding: 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .offer_title {
    margin-left: 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .intro_night {
    font-size: 0.8rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview {
    padding: 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container {
    margin: 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container .cata_icon {
    margin-right: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container p {
    line-height: 1rem;
    margin: 0.5rem 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .description {
    display: block;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .golden {
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .infinite {
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container {
    margin-bottom: 2rem;
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container {
    display: block;
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or {
    width: 100%;
    margin: 0;
    margin-bottom: 2rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h3 {
    width: 100%;
    margin: 1rem 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h4 {
    margin: 1rem 0rem 0rem 0rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    width: 100%;
    min-width: 25rem;
    align-items: center;
    justify-content: flex-start;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container h5 {
    margin: 0;
    line-height: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container {
    width: 3.5rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container .divide_or {
    width: 0.5rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .btn_tocontact {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 320px) {
  .offers_container {
    margin: 4.5rem auto 3rem auto;
    display: block;
    width: 90vw;
  }
  .offers_container .img_container {
    width: 100%;
    height: 40vh;
  }
  .offers_container .card_offer_container {
    margin: 1rem 0;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .description .menu_container .menu2_container {
    display: flex;
    flex-wrap: wrap;
    gap: 0rem;
  }
  .offers_container .card_offer_container .offer_derscription_container .description_container .btn_tocontact {
    font-size: 0.8rem;
  }
  .offers_container .img_container_night {
    width: 100%;
    height: 50vh;
    min-height: 25rem;
    background-position: 100% 40%;
  }
  .offers_container .img_container_night .img {
    background-position: 42% 50%;
  }
  .offers_container .img_container_night .img:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: 100%;
    background-color: rgba(168, 70, 19, 0.9);
  }
  .offers_container .img_container_night .img .concept_description_container {
    width: 55%;
    right: 2.5%;
  }
  .offers_container .img_container_night .img .concept_description_container .concept_description {
    line-height: 0.9rem;
    font-size: 0.7rem;
  }
  .offers_container .img_container_night .img .begin {
    height: 1rem;
    right: 46%;
  }
  .offers_container .img_container_night .img .end {
    height: 1rem;
    right: 0%;
  }
  .offers_container .card_offer_container_night {
    margin: 1rem 0;
    padding: 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .offer_title {
    margin-left: 0;
    font-size: 1.5rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview {
    padding: 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container {
    margin: 0;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container .cata_icon {
    margin-right: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .overview .icon_container p {
    line-height: 1rem;
    margin: 0.5rem 0;
    font-size: 0.75rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_category_title {
    font-size: 1.5rem;
    width: 90vw;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .description {
    display: block;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .golden {
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .infinite {
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container {
    margin-bottom: 2rem;
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container h3 {
    font-size: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container h4 {
    font-size: 0.9rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .menu_container .menu2_container p {
    font-size: 0.75rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container {
    display: block;
    width: 100%;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or {
    width: 100%;
    margin: 0;
    margin-bottom: 2rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h3 {
    width: 100%;
    margin: 1rem 0;
    font-size: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or h4 {
    margin: 1rem 0rem 0rem 0rem;
    font-size: 0.9rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    width: 95%;
    min-width: 10rem;
    align-items: center;
    justify-content: flex-start;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container h5 {
    margin: 0;
    line-height: 1rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container {
    width: 3.5rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .packs_container .pack_bulles_or .menu2_container .divider_container .divide_or {
    width: 0.5rem;
  }
  .offers_container .card_offer_container_night .offer_derscription_container .description_container .btn_tocontact {
    font-size: 0.8rem;
  }
}
.scroll_container {
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}
.scroll_container .fixed_bg_plage {
  position: fixed;
  top: 0rem;
  left: 0;
  background-image: url(../pictures/la_plage/fauteuils_paysage.jpg);
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: 40% 30%;
  z-index: -1;
}
.scroll_container .plage_comingsoon {
  position: absolute;
  top: 60vh;
  left: 50vw;
  transform: translateX(-50%);
  color: #fff;
  font-weight: bold;
  text-transform: capitalize;
}

@media screen and (max-width: 1170px) {
  .scroll_container .scroll_header .plage_comingsoon {
    white-space: nowrap;
    font-size: 1.3rem;
  }
}
.hero_half_contact_plage {
  background-image: url(../pictures/la_plage/la_plage.jpg);
  background-position: 60% 50%;
}

.laplage_gmap {
  width: 100%;
  height: 100%;
  border: none;
}

.laplage_ocean {
  color: #CDAA6D;
}

.contact_phone_laplage {
  color: #f6d7b0;
}

.divider_laplage {
  background-color: #CDAA6D;
}

.icon_fermé_plage {
  color: #CDAA6D;
}

.contact_openings_plage {
  border-top: 1px solid #CDAA6D;
  border-right: 1px solid #CDAA6D;
  border-bottom: 1px solid #CDAA6D;
}

.contact_plage_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../pictures/la_plage/sand_wave_zoom.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

.image_cut_contact_plage {
  position: absolute;
  top: 5rem;
  left: 3rem;
  height: 20rem;
  transform: translateY(-90%);
  z-index: 5;
}

@media screen and (min-width: 1171px) and (max-width: 1600px) {
  .image_cut_contact_plage {
    top: 10%;
    left: 2%;
    height: 18rem;
  }
}
@media screen and (max-width: 1170px) {
  .image_cut_contact_plage {
    top: 20%;
    left: -7%;
    height: 15rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .image_cut_contact_plage {
    height: 13rem;
  }
}
@media screen and (max-width: 649px) {
  .image_cut_contact_plage {
    top: 5%;
    left: 25%;
    height: 10rem;
  }
}
.fixed_bg_ile_plate {
  background-image: url(../pictures/lile_plate/nofinger.jpg);
  background-size: cover;
  background-position: 50% 30%;
  z-index: -1;
}

.hero_half_contact_ile_plate {
  background-image: url(../pictures/lile_plate/allee.jpg);
  background-position: 100% 50%;
}

.image_cut_contact_ile_plate {
  position: absolute;
  top: 4rem;
  left: 8rem;
  height: 25rem;
  transform: translateY(-90%);
  z-index: 5;
}

.contact_ile_plate_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../pictures/le_catamaran/glasses.jpg);
  background-size: cover;
  background-position: 60% 45%;
}

@media screen and (min-width: 1171px) and (max-width: 1600px) {
  .image_cut_contact_ile_plate {
    top: 10%;
    left: 5%;
    height: 20rem;
  }
}
@media screen and (max-width: 1170px) {
  .image_cut_contact_ile_plate {
    top: 15%;
    left: 3%;
    height: 17rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .image_cut_contact_ile_plate {
    height: 13rem;
  }
}
@media screen and (max-width: 649px) {
  .image_cut_contact_ile_plate {
    top: 5%;
    left: 3%;
    height: 12rem;
  }
}
.fixed_bg_riviere {
  position: fixed;
  top: 0rem;
  left: 0;
  background-image: url(../pictures/la_riviere/table_solo.jpg);
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  z-index: -2;
}

.riviere_light_text_color {
  color: #b7b9c1;
}

.riviere_dark_text_color {
  color: #48434c;
  text-transform: uppercase;
}

.contact_la_riviere_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../pictures/la_riviere/riviere.jpg);
  background-size: cover;
  background-position: 60% 55%;
}

.divider_riviere {
  background-color: #b7b9c1;
}

.contact_openings_riviere {
  border-top: 1px solid #b7b9c1;
  border-right: 1px solid #b7b9c1;
  border-bottom: 1px solid #b7b9c1;
}

.icon_fermé_riviere {
  color: #b7b9c1;
}

.image_planche {
  position: absolute;
  top: 0;
  left: 10rem;
  height: 10rem;
  transform: translateY(-70%);
  z-index: 3;
}

@media screen and (max-width: 1170px) {
  .image_planche {
    position: absolute;
    top: 3rem;
    left: 4rem;
    height: 9rem;
    transform: translateY(-70%);
    z-index: 3;
  }
}
@media screen and (max-width: 768px) and (min-width: 650px) {
  .image_planche {
    position: absolute;
    top: 0;
    left: 2rem;
    height: 7rem;
    transform: translateY(-70%);
    z-index: 3;
  }
}
@media screen and (max-width: 649px) {
  .image_planche {
    position: absolute;
    top: 0;
    left: 2rem;
    height: 7rem;
    transform: translateY(-70%);
    z-index: 3;
  }
}
@media screen and (max-width: 320px) {
  .image_planche {
    position: absolute;
    top: 0;
    left: 2rem;
    height: 7rem;
    transform: translateY(-70%);
    z-index: 3;
  }
}
*, *::after,
*::before {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
}

.page_container {
  z-index: 1;
}

.contact_page_container {
  background-color: #fff;
  padding: 2rem 0;
}/*# sourceMappingURL=main.css.map */