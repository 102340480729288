

.home_hero {
    // background-image: url(../../pictures/le_jardin/jardin_bg.jpg);
    background-image: url(../pictures/le_jardin/jardin_bg.jpg);
    
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 100%;
    height: 120vh;
    width: 100vw;

    z-index: -2;
    position: fixed;
    top: 0;
    left: 0; 
    
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0, 0, 0.4);
        z-index: -1;
    }
   
}
.description {
    background-color: $primary_color;
    color: $light_text_color;
    text-align: center;
    padding: 3rem 20rem;
    font-size: 2rem;
    letter-spacing: 0.2px;
    span {
        font-size: 5rem;
    }
}
.home_scrolling {
    width: 100vw;
    justify-content: center;
    text-align: center;
    background-color: transparent;    

    .home_reservation_container {
        background-color: rgba($primary_color,0.5);
        font-family: $style_font;
        width: 100vw;
        height: auto;
        padding: 5rem 0;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;

        
        .contact_h2 {
            font-size: 2.5rem;
            letter-spacing: 2px;
            @extend .h2_title;
        }
        div {
            font-size: 0.9rem;
            text-transform: uppercase;
            max-width: 14rem;
            margin: 0 auto;
            color: set_text_color($primary_color);
            
            
            padding: 1rem 0rem;
            letter-spacing: 1px;
            word-spacing: 2px;
           
        }
    
        .reservation_btn {
            background-color: $secondary_color;
            border: 2px solid $secondary_color;
        }
        h1 {
            margin-top: 6rem;
            font-size: 3.5rem;
            color: set_text_color(rgba($primary_color,0.5));
        }
        .contact_h2 {
            color: set_text_color(rgba($primary_color,0.9));
        }

        
    }

        .restaurants_section_container {
            width: 100%;
            height: auto;
            padding: 4rem 0;
            background-color: $light_text_color;
        }
        .restaurant_section  {
            position: relative;
            width: 100vw;
            display: flex;
            background-color: $light_text_color;

            // min-width: 800px;
          
            margin: 0 auto;
            padding: 0 10%;
            .restau_photo_container {
                
                width: 50%;
                
                // min-height: 35rem;
                // background-color: lightcoral;
                align-items: center;
                justify-content: center;
                position: relative;
                
                .photo_restau {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    margin: auto;
                    width: 90%;
                    height: auto;
                    max-height: 100%;
                    // min-height: 30rem;
                    background-size: cover;
                 
               
                   
                }
            }
            
            .description_container {
            //   background-color: lightblue;
                text-align: center;
                padding: auto 0;
                height: 100%;
                min-height: 35rem;
                width: 50%;
                justify-content: center;
                text-align: center;
                position: relative;
                h3 {
                    font-size: 1.4rem;
                    font-weight: 400;
                    word-spacing: 2.2px;
                    margin: 0;
                }
                .text_container {
              
                    height: auto;
                    margin: auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 65%;
             
                    
                    .separator {
                        margin: 1.5rem auto 2rem auto;
                        height: 0.05rem;
                        width: 3rem;
                        background-color: $primary_color; 
                    }
                    p {
                        margin: 1.5rem auto;
                        line-height: 1.7rem;
                        font-weight: 300;
                        font-size: 110%;
                        word-spacing: 2.2px;
                    }
                    .see_more_btn{
                        outline: none;
                        text-decoration: none;
                        background-color: $secondary_color;
                        padding: 0.7rem 2.8rem;
                        text-transform: uppercase;
                        color: $light_text_color;
                        margin: 15rem 0 0 0;
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
                   
            }
        }
    
    
    .home_contact_container {
        
        background-color: rgba($primary_color,0.5);
        
        width: 100vw;
        justify-content: center;
        align-items: center;
        padding: 6rem 0;
        .flex_wrap_container {
            width: 100vw;
            display: flex;
            flex-wrap: wrap;
            gap: 5rem;
            margin: 0 auto;
            // padding: 4rem auto;
            justify-content: center;
      
        }
        .contact_one_restau_container {
            
            display: flex;
            
            width: 45rem;
            box-shadow: 0 20px 10px rgba(0,0, 0, 0.3);
            background-color: rgba(white, 0.2);
            // background-color: rgba($primary_color, 0.5);
            border-radius: 10px;
            padding: 1rem;
            .logo_container {
                width: 45%;
                padding: auto ;
                justify-content: center;
                align-items: center;
                 white-space: nowrap;
                // background-color: rgba($primary_color, 0.5);
            
                img {
    
                width: 60%;
                vertical-align: middle;
                
                margin: auto, 0;
                }
            }
            
            .description_contact {
                // position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                color: $light_text_color;
                // background-color: rgba(white, 0.3);
                position: relative;
                width: 55%;
                .paragraph_container {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  
                    width: 100%;
                    .p_icon_container {
                        display: flex;
                       align-items: center;
                       justify-content: center;
                       
                       .icon_contact {
                        margin: 0 1rem;
                       }
                       p {
                        font-size: 0.9rem;
                       }
                    }
                    .contact_us_btn {
                        outline: none;
                        text-decoration: none;
                        background-color: $secondary_color;
                        padding: 0.7rem 2.8rem;
                        text-transform: uppercase;
                        color: $light_text_color;
                        
                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }
    .mini_gallery_jardin {
        width: 100vw;
        background-color: #fff;
        padding: 3rem 0;
        .mini_gallery_wrap {
            max-width: 90%;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            gap: 1.5rem;
            img {
                width: 500px;
                height: 340px;
                background-size: cover;
            }
        }
    }
}

@include mediaXl {
    .home_contact_container {
        .flex_wrap_container {
            .contact_one_restau_container {
                min-height: 20rem;
                .logo_container{
                    position: relative;
                    line-height: 0;
                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .description_contact {
                    .paragraph_container {
                        .p_icon_container {
                            .icon_contact {}
                            p {}
                        }
                        .contact_us_btn{}
                    }
                }
            }
        }
    }
    .home_scrolling  {
        .restaurants_section_container {
            padding:  0;
            
        }
    }
    .restaurants_section_container {
        width: 100%;
        height: auto;
        padding: 2rem 0;
        background-color: $light_text_color;
    }
        .restaurant_section {
       
            background-color: lightblue;
         
            .restau_photo_container {
          
                .photo_restau {
        
                }
            }
            
            .description_container {
               
                
            }
        }
        .text_container {
            width: 80%;
            font-size: 0.9;
            
            
            p {
               
                font-size: 0.9rem;
                
            }
        }
        .restau_description_p {
            margin: 1rem 0;
            word-spacing: 1.9px;
            width: 100%;
            font-size: 0.9rem;
            line-height: 1rem;
        }
}

@include mediaLg {
    .home_contact_container {
        .flex_wrap_container {
            .contact_one_restau_container {
                // min-height: 20rem;
                .logo_container{
                    // position: relative;
                    // line-height: 0;
                    img{
                        // position: absolute;
                        // top: 50%;
                        // left: 50%;
                        // transform: translate(-50%, -50%);
                    }
                }
                .description_contact {
                    .paragraph_container {
                        .p_icon_container {
                            .icon_contact {}
                            p {}
                        }
                        .contact_us_btn{}
                    }
                }
            }
        }
    }
    .reverse {
        display: flex ;
        flex-direction: column-reverse !important;

    }
    .home_scrolling  {
        .restaurants_section_container {
            padding: 0;
           
            
        }
    }
    .restaurant_section {
        display: block;
        flex-direction: column;
        padding: 3rem 0 0 0 !important;

        .restau_photo_container {
            margin: 0 auto;
            width: 85vw !important; 
            
            min-height: 35rem !important;

            .photo_restau {
                padding: 0;
            }
        }
        
        
        .description_container {
           width: 85vw !important;
           margin: 0 auto !important;
            min-height: 35rem !important;

            .text_container {
                width: 80%;
                font-size: 0.9;
 
                
                p {
                   
                    font-size: 0.9rem;
                    
                }
            }
            .restau_description_p {
                margin: 1rem 0;
                word-spacing: 1.9px;
                width: 100%;
                font-size: 0.9rem;
                line-height: 1rem;
            }
        }
    }

}

@include mediaMd {
    .home_contact_container {
        .flex_wrap_container {
            .contact_one_restau_container {
                .logo_container{
                    img{}
                }
                .description_contact {
                    .paragraph_container {
                        .p_icon_container {
                            .icon_contact {}
                            p {}
                        }
                        .contact_us_btn{}
                    }
                }
            }
        }
    }
    .reverse {
        display: flex ;
        flex-direction: column-reverse !important;

    }
    .home_scrolling  {
        .restaurants_section_container {
            padding:  0;
            
            
        }
    }
    .restaurant_section {
     
        flex-direction: column;
        padding: 0;
        

        .restau_photo_container {
            margin: 0 auto;
            width: 100vw !important; 
            max-width: 50rem;
            
            .photo_restau {
      
            }
        }
        
        
        .description_container {

           width: 100vw !important;
            height: auto;
            padding: 0;
            margin: 0;
            min-height: 35rem !important;
            .text_container {
                width: 80%;
                font-size: 0.9;
   
                margin: 0;
                
                p {

                    font-size: 0.85rem !important;
                    
                }
            }
            .restau_description_p {
                margin: 1rem 0;
                word-spacing: 1.9px;
                width: 100%;
                font-size: 0.9rem;
                line-height: 1rem;
            }
        }
    }

}

@include mediaSm {
    .home_contact_container {
        .flex_wrap_container {
            .contact_one_restau_container {
                flex-direction: column;
                width: 100vw !important;
                padding: 1rem 0 !important;
                .logo_container{
             
                    width: 100vw;
                    position: relative;
                    margin-bottom: 1rem;

                    img{
                
                        position:relative;
                        margin: 0 auto;
                        top: 50%;
                        left: 50%;
                        transform: translate(-80%, -50%);
                    }
                }
                .description_contact {
                    position: relative;
                    width: 100vw;
                    margin: 1rem auto;
                    .paragraph_container {
                        position: relative;
                        .p_icon_container {
                            .icon_contact {}
                            p {}
                        }
                        .contact_us_btn{}
                    }
                }
            }
        }
    }
    .reverse {
        display: flex ;
        flex-direction: column-reverse !important;

    }
    .home_scrolling  {
        .restaurants_section_container {
            padding: 0;
            
            
        }
    }
    .restaurant_section {
        display: block;
        flex-direction: column;
        padding: 0;
        

        .restau_photo_container {

            left: 0;
            margin: 0;
            width: 100vw !important; 
            // transform: translateX(-8%); 
           min-height: 12rem !important;
            padding: 10rem 0;    
           
            .photo_restau {
                width: 100% !important;
                // height: 10rem !important;
                height: auto !important;
            
                
                
                // background-attachment: fixed !important;
                background-size: cover !important;
            }
        }
        
        
        .description_container {
            width: 90vw !important;
            height: auto !important;
            padding: 0;
            margin: 0;
       
            
            min-height: none !important;
            // transform: translateX(-2%); 

            h3 {
                margin: 0 !important;

            }

            .text_container {
                width: 90% !important;
                font-size: 0.9;
                margin: 0 !important;
                padding: 0 !important;    
                // background-color: lightblue;         
                p {

                    width: 100%;
                    margin: 0.5rem 0 !important;
                    font-size: 0.85rem !important;
                    
                }
                .separator {
                    margin: 0.7rem auto 1rem auto !important;
                }
                .see_more_btn {
                    padding: 0.7rem 2rem !important;
                }
            }
            .restau_description_p {
                margin: 1rem 0;
                word-spacing: 1.9px;
                width: 100%;
                font-size: 0.9rem;
                line-height: 1rem;
            }
        }
    }

}

@include mediaXs {
    .home_contact_container {
        .flex_wrap_container {
            .contact_one_restau_container {
                .logo_container{
                    img{}
                }
                .description_contact {
                    .paragraph_container {
                        .p_icon_container {
                            .icon_contact {}
                            p {}
                        }
                        .contact_us_btn{}
                    }
                }
            }
        }
    }
    .reverse {
        display: flex ;
        flex-direction: column-reverse !important;

    }
    .home_scrolling  {
        .restaurants_section_container {
            padding:   0;
            
            
        }
    }
    .restaurant_section {
        display: block;
        flex-direction: column;
        padding: 0;
        

        .restau_photo_container {
            left: 0;
            margin: 0;
            width: 100vw !important; 
            // transform: translateX(-8%); 
           
            padding: 10rem 0;    
            
            .photo_restau {
                width: 100% !important;
                // height: 10rem !important;
                height: auto !important;
         
                // background-attachment: fixed !important;
                background-size: cover !important;
            }
        }
        
        
        .description_container {
            width: 90vw !important;
            height: auto !important;
            padding: 0;
            margin: 0;

            min-height: none !important;
            transform: translateX(-2%); 

            h3 {
                margin: 0 !important;

            }

            .text_container {
                width: 100% !important;
                font-size: 0.9;
                margin: 0 !important;
                padding: 0 !important;             
                p {

                    width: 100%;
                    margin: 0.5rem 0 !important;
                    font-size: 0.85rem !important;
                    
                }
                .separator {
                    margin: 0.7rem auto 1rem auto !important;
                }
                .see_more_btn {
                    padding: 0.7rem 2rem !important;
                }
            }
            .restau_description_p {
                margin: 1rem 0;
                word-spacing: 1.9px;
                width: 100%;
                font-size: 0.9rem;
                line-height: 1rem;
            }
        }
    }

}