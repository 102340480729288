.footer {
    
    display: flex;
    background-color: $primary-color;
    justify-content: space-evenly;
    padding: 2rem 10rem;

    .footer_container {
        // max-width: 30rem;
        width: 30%;
        margin: 1rem;    
        p {
            font-size: 0.8rem;
            color: $dark_grey;
            text-align: justify;
        }
        li {
            color: $dark_grey;
        }
    }
    .footer_title {
        font-size: 0.75rem;
        font-weight: 650;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: $light_text_color;

    }
    .footer_contact_infos {
        align-items: center;
        justify-content: center;
        
        
        .footer_contact_info {
            display: flex;

            .contact_icon{
                color: $dark_grey;
                
                background-color: transparent;
                margin: 0 0.5rem;  
                padding: 0.4rem;
                transform: translateY(15%);
                margin-right: 1rem;
            }
        }
    }

    
    .social_media {
        display: flex;
        justify-content: left;
        .social_media_link {
            margin: 0 0.5rem;  
            padding: 0.4rem;
            font-size: 0.7rem;
            color: $dark_grey;
        }
        
       .social_media_link_lecata {
        @extend .social_media_link;
            color: $dark_grey;
            &:hover {
                color: $ocean;
            }
       } 
       .social_media_link_lariviere {
        @extend .social_media_link;
        color: $dark_grey;
        &:hover {
            color: $riviere_light;
        }
       }
       .social_media_link_lejardin {
        @extend .social_media_link;
            color: $dark_grey;
            &:hover {
                color: $garden_dark;
            }
       } 
       .social_media_link_laplage {
        @extend .social_media_link;
            color: $dark_grey;
            &:hover {
                color: $dark_sand;
            }
       } 
    }
    .footer_openings {
        font-size: 0.8rem;
        color: $dark_grey;
        text-align: justify;
        width: 100%;
        margin-top: 1rem;
        .footer_openings_li {
          list-style: none;
          min-width: 15rem;
          max-width: 20rem;
          width: 70%;
          position: relative;
          text-transform: capitalize;
          
          .openings {
            position: absolute;
            right: 0;
            color: set_text_color($primary_color);
          }
        }
    }
}

.footer_gmap {
    width: 100%;
    max-width: 25rem;
    border: none !important;
}
// language
.footer_language_container {
    display: flex;
    color: set_text_color($primary-color);
    align-items: center;

    .footer_language_title {
        color: $dark_grey;
        margin-left: 1rem;
        @extend .info_title;
        
    }
}

// MEDIA

@include mediaLg {
    .footer {
        width: 100vw;
        padding: 0.5rem;

        .footer_container {
            width: 26%;  
            margin: 0;   
  
            p {
                font-size: 0.8rem;
                color: $dark_grey;
                text-align: justify;
            }
            li {
                color: $dark_grey;
                min-width: none;

            }
            .social_media {
                .social_media_link {
                    margin: 0 0.6rem;  
                    font-size: 0.7rem;
                    
                }
            }
            .footer_contact_infos {
                

                .footer_contact_info {
                    align-items: center;
                    width: 100%;
                    .contact_icon{
                        color: $dark_grey;
                        height: 1rem;
                        width: 1rem;
                        background-color: transparent;
                        margin-right: 0rem;
                        transform: translateY(5%);
                    }
                    p {
                        font-size: 0.7rem;
                        text-align: left;
                    }
                }
                
            }
            .footer_openings {
                font-size: 0.7rem;
                .footer_openings_li {
                    list-style: none;
                    width: 100%;
                    min-width: 6rem;
                    position: relative;
                    text-transform: capitalize;
                    .openings {
                      position: absolute;
                      right: 0;
                      color: set_text_color($primary_color);
                    }
                  }
            }
            
        }
    }
    
    .footer_contact_infos {
        .footer_contact_info {
            display: flex;
            margin: 0;
            padding: 0;
            i {
                font-family: FontAwesome !important;
                color: $dark_grey;
                height: 1rem;
                width: 1rem;
                background-color: transparent;
                margin: 0 0.5rem;  
                padding: 0.4rem;
                transform: translateY(-50%);
                margin-right: 1rem;
            }
            p {
                margin: 0;
            }
            
        }
    }
    
    .footer_gmap {
        width: 100%;
        height: 60%;
        border: none !important;
    }
}

@include mediaMd {
    .footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 1.5rem;
        .footer_container {
            width: 45%;

            .footer_gmap {
                width: 100%;
                height: 80%;
            }
            .social_media {
                a {
                    margin: 0 0.6rem;  
                    font-size: 0.6rem;
                }
            }
        }
    }
    .footer_contact_infos {
        .footer_contact_info {
            p {
                margin: 0;
                font-size: 0.7rem;
            }
            
        }
    }
    .footer_openings{
        margin-top: 0rem;
     
        .footer_openings_li {
            font-size: 0.7rem;
            min-width: none;
            max-width: 15rem;
        }
        
    }

 
}

@include mediaSm {
    .footer {
        display: block;
        align-items: center;
        justify-content: flex-start;
        .footer_container {
            width: 90%;
            margin-bottom: 1.5rem;
            .footer_gmap {
                width: 100%;
            }
            .social_media {
                a {
                    margin: 0 0.6rem;  
                    font-size: 0.8rem;
                }
            }
        }
    }
    .footer_contact_infos {
        .footer_contact_info {
            p {
                margin: 0;
                font-size: 0.8rem;
            }
        }
    }
    .footer_openings{
        max-width: 60%;
        margin-top: 0rem;
        .footer_openings_li {
            font-size: 0.7rem;
            max-width: 15rem;
            min-width: none;
        }
    }

}

@include mediaXs {
    footer {
        width: 100vw;
    }
    .footer_container {
        width: 100%;
        
    }
    .footer_openings{
        margin-top: 0rem;
      
        width: 100%;
        max-width: none;
        
     
        .footer_openings_li {
            
            font-size: 0.7rem;
            min-width: none;
            max-width: none;
            width: auto;
            
        }
        
    }
    .footer_gmap {
        width: 100%;
        max-width: none;
    }
}