
// COLORS
$light_text_color: #fff;
$dark_red:#862b2b;
// $primary_color:  #060202;
$primary_color: #000000;
$secondary_color: #dd8808;
$dark_grey: rgba(255,255,255,0.5);

$light_grey: #b7b9c1;
$dark_grey: rgba(255,255,255,0.5);

$riviere_light: #b7b9c1;
$riviere_dark: #48434c;

// SUNSET
$sunset: #a84613;
$sunset2: #d18f35;

$sand: #f6d7b0;
$dark_sand: #CDAA6D;

// $sand: #66CCCC;
// $dark_sand: #008dc7;

$lagoon: #66CCCC;
$ocean: #008dc7;

// $lagoon: #d18f35;
// $ocean: #a84613;

$garden_light: #A6C48A;
$garden_dark: 	#678D58;

$gradient_color: linear-gradient(to bottom, #A40606, #D98324);

// FONTS
$style_font:'Noto Serif Georgian', serif;

@function set_text_color($color) {
    @if (lightness($color) > 40) {
      @return #000;
    } @else {
      @return #fff;
    }
  }

//   GRADIENT 
@mixin gradient_text {
    background: $gradient_color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  @mixin background_gradient {
    background-image: $gradient_color;
  }
  @function set_restaurant_color($color) {
    @if ($color == "lejardin1") {
      @return $garden_dark;
    }
    @if ($color == "lejardin2") {
      @return $garden_light;
    }
    @if ($color == "laplage1") {
      @return $dark_sand;
    }
    @if ($color == "laplage2") {
      @return $sand;
    }
    @if ($color == "lecata1") {
      @return $ocean;
    }
    @else {
      @return $lagoon;
    }
  }

// EFFECTS
$light_text_shadow: 1px 1px rgba(black, 0.6);
$text-shadow2_06: 2px 2px rgba(black, 0.6);
$text-shadow2_08: 2px 2px rgba(black, 0.8);

@mixin fastEaseOut {
    transition: all 0.5s ease-out;
  }
  @mixin fade {
      opacity: 0.8;
  }
  @mixin spacing {
      letter-spacing: 0.4px;
  }
  @mixin spacing_title {
    letter-spacing: 3px;
  }

// Media Query Mixins
@mixin mediaXs {
  @media screen and (max-width: 320px) {
    @content;
  }
}
@mixin mediaSm {
    @media screen and (max-width: 649px) {
      @content;
    }
  }
  
  @mixin mediaMd {
    @media screen and ( max-width: 768px)and (min-width: 650px) {
      @content;
    }
  }
  
  @mixin mediaLg {
    @media screen and (max-width: 1170px) {
      @content;
    }
  }
  
  @mixin mediaXl {
    @media screen and (min-width: 1171px) and (max-width: 1600px){
      @content;
    }
  }
  