.info_title {
    font-weight: 300;
    font-size: 0.8rem;
    text-transform: capitalize;
    margin-right: 1rem ;
}

.h1_title {
    @include spacing_title;
    font-size: 6rem;
    font-family: $style_font;
    font-weight: 400;
    text-transform: uppercase;
}

.h2_title {
    font-family: $style_font;
    font-size: 3.4rem;
    font-weight: 400;
    text-transform: uppercase;
}

.h3_title {
    font-family: $style_font;
    font-size: 1.5rem;
    font-weight: 400;
 
}

.h3_title_upper {
    text-transform: uppercase;
    font-weight: 400;
    font-family: $style_font;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.08em;
}

.h3_title_bold {
    font-family: $style_font;
    font-weight: 600;
    font-size: 1.2rem;
}
.page_header {
    position: absolute;
    top: 25vh;
    left: 0;
    width: 100%;
    text-align: center;   
}
.page_header_title {
    color: set_text_color($primary_color);
    font-size: 3.5rem;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 3rem;
    font-family: $style_font; 
    text-transform: capitalize;
}

@include mediaLg {
    .h1_title{
        font-size: 3rem;
    }
    .page_header {
        top: 20vh;
    }
    .page_header_title {
        font-size: 3rem;
    }
    .h3_title {
        font-size: 1.2rem;
    }
}

@include mediaMd {
    .h1_title{
        font-size: 2rem;
    }
    .h3_title_upper {
        font-size: 1.2rem;
    }
}
@include mediaSm {

    .page_header_title {
        font-size: 1.5rem;
    }
    .h1_title{
        font-size: 1.5rem;
    }
    .h3_title_upper {
        font-size: 1rem;
    }
    .h3_title_bold {
        font-size: 1rem;
    }
}