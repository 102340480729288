
// RESERVATION FORM

.contact_form_container {

    min-width: 35rem;
    width: 40%;
    margin: 0 auto;
}
.reservation_form_container {
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 0 auto;

    .reservation_flash_msg {
        font-style: italic;
        margin: 0.8rem;
        
        font-weight: 300;
        color: rgba($primary_color, 0.5);
    }

    .reservation_form { 
        display: flex;
        flex-direction: column;
        .contact_form_inputgrp {
            display: flex;

        }
        .reservation_input {
            text-decoration: none;
            resize: none;
            width: 45%;
            margin: 1rem;
            padding: 0.5rem;
            font-family: "Roboto", sans-serif;
            
            @include fastEaseOut;
            :-webkit-autofill,
            :-webkit-autofill:focus {
                -webkit-box-shadow: 0 0 0 30px white inset !important;
            }
        }
        .reservation_comment {
            text-decoration: none;
            resize: none;
            margin: 0.5rem;
            padding: 0.5rem;
          
            // border: 1px solid rgba($primary_color, 0.1);
            // background-color: $light_text_color;
            // color: set_text_color($light_text_color);
            height: 5rem;
            text-align: left;
            font-family: "Roboto", sans-serif;
            @include fastEaseOut;
            // &:focus {
            //     outline: none !important;
            //     border: 1px solid rgba(rgba($primary_color, 0.4), 0.8);
            //     // border: 1px solid $garden_dark;
            // }
        }
        .reservation_submit {
            margin: 1rem auto;
            width: 100%;      
            padding: 0.5rem 0.9rem;
            // border: 1px solid $garden_dark;
            // background-color: $garden_dark;
            text-transform: uppercase;
            transition: .2s ease-in-out;
            transition-property: color, 
            background-color, border;
            // &:hover {    
            // background-color: $light_text_color;
            // border: 1px solid $garden_dark;
            // color: $garden_dark;
            // }
            // &:disabled {
            //     background-color: $dark_grey;
            //     border: 1px solid $dark_grey;
            // }
        }
    }
}

// MEDIA

@include mediaLg {
    .contact_form_container {

        
        width: 60%;
        margin: 0 auto;
    }
    .annonce1 {
        h2 {
            font-size: 1.3rem;
        }
        span {
            font-size: 1.3rem;
        }
    }
    .annonce2 {
        width: 100vw;
        .annonce2_subcontainer {
            .reserve_photo {
                width: 70%;
                height: 40vh;
            }
            .info_container{
                h2 {
                    font-size: 1.3rem;
                }
                p {
                    font-size: 0.9rem;
                }
            }
        }
        .reservation_form_container{
           
            .reservation_flash_msg{
                margin: 0;
                font-size: 0.7rem;
                transform: translate(-5%,-170%);
            }
            .reservation_form{
                .reservation_input{
                    font-size: 0.8rem;
                }
                .reservation_comment {
                    font-size: 0.8rem;
                }
                .reservation_submit{
                    font-size: 0.9rem;
                }
            }
        }
    }
}

@include mediaMd {
    .contact_form_container {

        width: 60%;
        margin: 0 auto;
    }
    .annonce2 {
        display: block;
        justify-content: center;
        .annonce2_subcontainer {
            width: 100%;
            .info_container{
                h2 {}
                p {}
            }
        }
        .reservation_form_container{
           
            margin-top: 3rem;
            width: 100%;
            .reservation_flash_msg{
                transform: translate(0%,-100%);
            }
            .reservation_form{
                top: 5%;
                left: 50%;
                transform: translateX(-50%);
                .reservation_input{}
                .reservation_comment {}
                .reservation_submit{}
            }
        }
    }
}

@include mediaSm {
    .contact_form_container {
        min-width: 100vw;
        width: 100vw;
        padding: 0;
        

    }
    .reservation_form_container {
        width: 70%;
        max-width: 90vw;
        margin: 1rem auto;

    }
    .annonce2 {
        .annonce2_subcontainer {
            .info_container{
                h2 {}
                p {}
            }
        }
        .reservation_form_container{
            .reservation_form{
                .contact_form_inputgrp{
                    
                    
    
                }
            }
            

        }
    }
    .reservation_flash_msg{
        font-size: 0.8rem;
        
    }
    .reservation_input{
        font-size: 0.75rem;
    }
    .reservation_comment {
        font-size: 0.75rem;
    }
    .reservation_submit{

    }
}