.language_container {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-60%, -10%);
    display: flex;
    align-items: center;
    transition: all .4s ease-in-out;
    z-index: 10000;
    .nav_language_title {
        @extend .info_title;
        color: set_text_color($primary_color);
    }
}
.navbar_header {
    position: fixed;
    top: 0; left: 0; right: 0;
    padding: 0px 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    transition: all .3s ease-in-out;
    background-color: transparent;
    box-sizing: border-box;
    height: 5rem;
    align-items: center;
    .nav-logo {
        height: 4rem;
        margin: 0.25rem 0;
        transition: all .3s ease-in-out;
        
    }
    #menu-bar {
        display: none;
    }
    .menu-bar {
        color: $light_text_color;
        text-decoration: none;
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        transition: all .3s ease-in-out;
        display: none;
        z-index: 10;
        &:hover {
            cursor: pointer;
        }
    }
    .nav {
        transition: all .3s ease-in-out;
        
        justify-content: center;
        height: 100%;
        align-items: center;
        text-align: center;
        padding: 1rem 0 0.2rem 0;
        .nav_container {
            list-style: none;
            transition: all .3s ease-in-out;
            li {
                position: relative;
                
                float: left;
                height: 2rem;
                text-align: center;
                align-items: center;
                div {
      
                    transform: translate(0, -8px);
               
                }
                .nav-link {
                    color: $light_text_color;
                    text-decoration: none;
                    padding: 0.5rem 1rem;
                    font-size: 1.3rem;
                   text-transform: capitalize;
                    transition: all .3s ease-in-out;
                    height: 100%;
                    margin: auto 0;
                    
                    &:hover {
                        color: $secondary_color;
                        cursor: pointer;
                    }
                    
                    .dropdown_icon {
                      
                        transform: translateY(4px);
                        // background-color: lime;
                    }
                }
              
                .navbar_submenu_off {
                    display: none;
                    transition: all .3s ease-in-out;
                }
                .navbar_submenu_on_sticky {
                    position: absolute;
                    left: 0;
                    top: 2.9rem;
                    width: 200px;
                    background-color: $primary_color;
                    // display: flex;
                    // flex-direction: column;background-color: lime;
                    list-style: none;
                    display: initial;
                    padding: 0;
                    transition: all .3s ease-in-out;
                    li {
                        margin: 1.5rem 0;
               
                        width: 100%;
                        color: $light_text_color;
                        text-decoration: none;
                        .navbar_sublink {
                            text-decoration: none;
                            color: $light_text_color;
                            font-size: 1.3rem;
               
                            
                            &:hover {
                                color: $secondary_color;
                            }
                        }
                    }
                }
                .navbar_submenu_on {
                    position: absolute;
                    left: 0;
                    top: 3.4rem;
                    width: 200px;
                    background-color: transparent;
                    // display: flex;
                    // flex-direction: column;
                    list-style: none;
                    display: initial;
                    padding: 0;
                    transition: all .3s ease-in-out;
                    li {
                        margin: 1.5rem 0;
               
                        width: 100%;
                        color: $light_text_color;
                        text-decoration: none;
                        .navbar_sublink {
                            text-decoration: none;
                            color: $light_text_color;
                            font-size: 1.3rem;
                            &:hover {
                                color: $secondary_color;
                            }
                        }
                    }
                }
            }
        }
    }

}

.sticky {
    background-color: $primary_color;
    box-shadow: 0 20px 10px rgba(0,0, 0, 0.3);
    height: 4.7rem;
    .nav-logo {
        height: 3.8rem;
        margin: 5px 0;
        display: flex;
    }
    .nav {
        margin: 10px 0;
        padding: 0.8rem 0 0.2rem 0;
        .nav_container {
            li {
                    div {
                        transform: translate(0, -6px);
                    }
               
                .nav-link {
                    color: $light_text_color;
          
                    &:hover {
                        color: $secondary_color;
                    }
                }
            }
            
        }
    }
}


@include mediaXl {
    .navbar_header {
        // background-color: lemonchiffon;
    }
}
@include mediaLg {
    .sticky {
        .nav-logo {
            height: 3.7rem !important;
   
        }
    }
    .navbar_header {
    //   background-color: lightblue;
        .nav-logo {
            height: 4rem;
            
        }
        
        .nav {
            .nav_container {
                
                li {
                    .nav-link {
                        font-size: 1.1rem;
                    }
                    .navbar_submenu_on_sticky {
                        top: 2.7rem;
                        li {
                            margin: 0.5rem 0;
                            .navbar_sublink {
                                font-size: 1rem;
                                
                            }
                        }
                    }
                    .navbar_submenu_on {
                        top: 2.9rem;
                        li {
                            margin: 0.5rem 0;
                            .navbar_sublink {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
@include mediaMd {
    .sticky {
        .nav-logo {
            height: 3.7rem !important;
          
        }
    }
    .navbar_header {
        // background-color: maroon;
        padding: 20px;

        .menu-bar {
            display: initial;
        }
        .nav-logo {
            height: 4rem;
            
        }
        
        .nav {
            position: absolute;
            top: 0; left: 50%; right: 0;
            background: rgba(black, 0.7);
            // background-color: lightblue;         height: 100vh;  
            width: 50vw;
            height: 100vh;
            display: none;
            justify-content: center;
            align-items: center;
            z-index: 2;
            .nav_container {
                margin: auto 0;
                padding: 0;
                height: auto;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                li {
                    transition: all .3s ease-in-out;
                    width: 100%;
                    margin: 2rem 0;
                    position: relative;
                    height: auto;
                    
                    .nav-link {
                        font-size: 1.3rem;
                    }
                    .navbar_submenu_on {
                        position: relative !important; 
                        top: 0;
                        height: auto;
                        width: 100%;
                        margin: 0;
                  
                        transition: all .3s ease-in-out;
                        li {
                            margin: 0.3rem 0;
                            // background-color: rgba(#fff, 0.1);
                            border-bottom: 1px solid rgba($light_text_color, 0.1) ;
                            padding: 0
                            width   100%;
                        }
                    }
                    .navbar_submenu_on_sticky {
                        position: relative !important; 
                        top: 0;
                        height: auto;
                        width: 100%;
                        margin: 0;
                        
                        transition: all .3s ease-in-out;
                        li {
                            margin: 0.3rem 0;
                            // background-color: rgba(#fff, 0.1);
                            border-bottom: 1px solid rgba($light_text_color, 0.1) ;
                            padding: 0
                            width   100%;
                         
                        }
                    }
                    
                }
            }
        }
        #menu-bar:checked ~ .nav {
            display: initial;
        }
    }
    
}

@include mediaSm {
    .sticky {
        .nav-logo {
            height: 3.3rem !important;
        }
    }
    .navbar_header {
        // background-color: salmon;
        padding: 1rem 1.5rem;
        width: 100vw;

        .menu-bar {
            display: initial;
        }
        .nav-logo {
            height: 3.7rem;
            
            
            
        }
        
        .nav {
            position: absolute;
            top: 0; left: 25%; right: 0;
            background: rgba(black, 0.7);
            // background-color: lightblue;         height: 100vh;  
            width: 75vw;
            height: 100vh;
            display: none;
            justify-content: center;
            align-items: center;
            z-index: 2;
            .nav_container {
                margin: auto 0;
                padding: 0;
                height: auto;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                li {
                    transition: all .3s ease-in-out;
                    width: 100%;
                    margin: 2rem 0;
                    position: relative;
                    height: auto;
                    
                    .nav-link {
                        font-size: 1.2rem;
                    }
                    .navbar_submenu_on {
                        position: relative !important; 
                        top: 0;
                        height: auto;
                        width: 100%;
                        margin: 0;
                 
                        transition: all .3s ease-in-out;
                        li {
                            margin: 0.3rem 0;
                            
                            padding: 0
                            width   100%;
                         
                            // background-color: rgba(#fff, 0.1);
                            border-bottom: 1px solid rgba($light_text_color, 0.1) ;

                            .navbar_sublink {
                                font-size: 1.15rem;
                                border-bottom: 1px solid rgba($light_text_color, 0.3);
                                
                                padding: 0 2rem;
                            }
                        }
                    }
                    .navbar_submenu_on_sticky {
                        position: relative !important; 
                        top: 0;
                        height: auto;
                        width: 100%;
                        margin: 0;
                
                        transition: all .3s ease-in-out;
                        li {
                            margin: 0;
                            margin: 0.3rem 0;
                            // background-color: rgba(#fff, 0.1);
                            border-bottom: 1px solid rgba($light_text_color, 0.1) ;
                            padding: 0
                            width   100%;
                            .navbar_sublink {
                                font-size: 1.15rem;
                                border-bottom: 1px solid rgba($light_text_color, 0.3);
                                padding: 0 2rem;
                            }
                         
                        }
                    }
                    
                }
            }
        }
        #menu-bar:checked ~ .nav {
            display: initial;
        }
    }
}

@include mediaXs {
    .language_container {
        transform: translate(-15%, -10%);
        // background-color: ;
    }
    .sticky {
        .nav-logo {
            height: 3rem !important;
           
        }
    }
    .navbar_header {
        // background-color: sandybrown;
        padding: 20px;

        .menu-bar {
            display: initial;
            padding: 0.5rem 0.5rem;
            transform: translateY(15%);
        }
        .nav-logo {
            height: 3.3rem;
            
        }
        
        .nav {
            position: absolute;
            top: 0; left: 20%; right: 0;
            background: rgba(black, 0.7);
        
            width: 80vw;
            height: 100vh;
            display: none;
            justify-content: center;
            align-items: center;
            z-index: 2;
            .nav_container {
                margin: auto 0;
                padding: 0;
                height: auto;
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                flex-direction: column;
                li {
                    transition: all .3s ease-in-out;
                    width: 100%;
                    margin: 2rem 0;
                    position: relative;
                    height: auto;
                    
                    .nav-link {
                        font-size: 1.1rem;
                    }
                    .navbar_submenu_on {
                        position: relative !important; 
                        top: 0;
                        height: auto;
                        width: 100%;
                        margin: 0;
                        transition: all .3s ease-in-out;
                        li {
                            margin: 0;
                        
                            padding: 0
                            width   100%;
                            .navbar_sublink {
                                font-size: 1rem;
                                border-bottom: 1px solid rgba($light_text_color, 0.3);
                                padding: 0 2rem;
                            }
                        }
                    }
                    .navbar_submenu_on_sticky {
                        position: relative !important; 
                        top: 0;
                        height: auto;
                        width: 100%;
                        margin: 0;
                       
                        transition: all .3s ease-in-out;
                        li {
                            margin: 0;
                            .navbar_sublink {
                                font-size: 1rem;
                                border-bottom: 1px solid rgba($light_text_color, 0.3);
                                padding: 0 2rem;
                            }
                         
                        }
                    }
                    
                }
            }
        }
        #menu-bar:checked ~ .nav {
            display: initial;
        }
    }
}