.hero_half_contact_jardin {
    @extend .hero_half;
    @extend .black_veil_04;
    background-image: url(../pictures/le_jardin/shooting/terrasse.jpg);
    background-size:cover;
    background-position: 100% 21%;
}

.contact_post1 {
    display: flex;
    width: 60vw;
    height: 30vh;
    margin: 5rem auto;

    .left_container {
        position: relative;
        width: 50%;

        align-items: center;
        text-align: center;
        border: 1px solid $primary_color;
        
        .text_container {
            max-width: 100%;
            
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
            width: 80%;
            
            h2 {
                text-transform: uppercase;
                font-weight: 400;
                
                font-family: $style_font;
                font-size: 0.8125rem;
                letter-spacing: 0.08em;
                color: rgba($primary_color, 0.7);
            }
            h3 {
                @extend .h3_title_upper; 
            }
            .lejardin_color1 {
                color: $garden_dark;
            }
            .contact_adress {
                font-family: $style_font;
                font-weight: 300;
                font-size: 0.8125rem;
            }
            .contact_phone {
                @extend .h3_title_bold;
                color: $garden_light;
            }
        }
        
    }
    .right_container{
        width: 50%;
        height: 100%;
        .contact_gmap{
            width: 100%;
            height: 100%;
            border: none;
            // -webkit-filter: grayscale(100%);
            // -moz-filter: grayscale(100%);
            // -ms-filter: grayscale(100%);
            // -o-filter: grayscale(100%);
            // filter: grayscale(100%);
        }
    }
}

.contact_post2 {
    width: 100vw;
    // min-height: 30rem;
   position: relative;
    .text_container{
        width: 30vw;
        text-align: center;
        margin: 2rem auto;
        h2 {
            text-transform: uppercase;
                font-weight: 400;
                font-family: $style_font;
                font-size: 1.3rem;
                line-height: 2rem;
                letter-spacing: 3px;
        }
        .divider_lejardin {
            @extend .divider;
            background-color: $garden_dark;
        }
        P {
            font-family: $style_font; 
            font-size: 0.8rem;
            font-weight: 350;
            span {
                color: $dark_red;
                font-weight: 600;
            }
            .span_resa_cata {
                color: $ocean;
            }
        }
    }
}
.contact_else {
    width: 100vw;
    height: auto;
    margin: 5rem 0 10rem 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    .divider_lejardin {
        @extend .divider;
    }
    h2 {
        text-transform: uppercase;
        font-weight: 400;
        font-family: $style_font;
        font-size: 1.3rem;
        line-height: 2rem;
        letter-spacing: 3px;
    }
    .contact_else_container {
        display: flex;
        flex-wrap: wrap;
        gap: 4rem;
        width: 90vw;
        height: auto;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        .contact_cata {
            background-image: url(../pictures/le_catamaran/glasses.jpg);
            background-size: cover;
            background-position: center;
            height: 15rem;
            width: 30%;
            min-width: 25rem;
            margin: 2rem 0;
            position: relative;
            @extend .black_veil_03;
            @include fastEaseOut;
            &:hover {
                transform: scale(1.02);
            }
            img {
                max-height: 100%;
                height: 80%;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);  
            }
        }
        .contact_plage {
            background-image: url(../pictures/la_plage/sand_wave_zoom.jpg);
            background-size: cover;
            background-position: center;
            height: 15rem;
            width: 30%;
            min-width: 25rem;
            margin: 2rem 0;
            position: relative;
            @extend .black_veil_03;
            @include fastEaseOut;
            &:hover {
                transform: scale(1.02);
            }
            img {
                max-height: 100%;
                height: 80%;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);  
            }
        }
        .contact_jardin {
            background-image: url(../pictures/le_jardin/shooting/allée_bas.jpg);
            background-size: cover;
            background-position: center;
            height: 15rem;
            width: 30%;
            min-width: 25rem;
            margin: 2rem 0;
            position: relative;
            @extend .black_veil_03;
            @include fastEaseOut;
            &:hover {
                transform: scale(1.02);
            }
            img {
                max-height: 100%;
                height: 60%;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);  
            }
        }
        .contact_riviere {
            background-image: url(../pictures/la_riviere/love.jpg);
            background-size: cover;
            background-position: center;
            height: 15rem;
            width: 30%;
            min-width: 25rem;
            margin: 2rem 0;
            position: relative;
            @extend .black_veil_03;
            @include fastEaseOut;
            &:hover {
                transform: scale(1.02);
            }
            img {
                max-height: 100%;
                height: 80%;
                position: absolute;
                top: 50%;
                right: 50%;
                transform: translate(50%, -50%);  
            }
        }
       
    }
}
.contact_jardin_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../pictures/le_jardin/shooting/allée_bas.jpg);
    
    background-size: cover;
    background-position: 50% 80%;
    
    @extend .black_veil_07; 
}
.contact_post3 {
    width: 100vw;
    display: flex;
    margin: 5rem auto 0 auto;

    justify-content: center;
    padding: 1rem 0;
    
    
    .image_salade {
        position: absolute;
        top: 0;
        left: 10rem;
        height: 15rem;
        transform: translateY(-70%);
        z-index: 3;
    }
    
    .contact_post3_left {
        width: 25%;
        align-items: center;
        text-align: center;
        margin: 2rem 0;
        z-index: 2;
        .text_container {
            h2 {
                text-transform: uppercase;
                font-weight: 400;
                font-family: $style_font;
                font-size: 1.3rem;
                line-height: 2rem;
                letter-spacing: 3px;
                color: $dark_grey;
                
            }
            
            .practical_infos {
                h3 {
                    text-transform: uppercase;
                    font-weight: 400;
                    font-family: $style_font;
                    font-size: 0.8125rem;
                    letter-spacing: 0.08em;
                    color: $light_text_color;
                    margin: 0;
                }
                p{
                    font-family: $style_font; 
                    margin-bottom: 1.5rem;
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: $dark_grey;
                }
                
            }
        }
    }
}
.contact_openings{
    width: 25%;
    align-items: center;
    text-align: center;
    border-top: 1px solid $garden_light;
    border-right: 1px solid $garden_light;
    border-bottom: 1px solid $garden_light;
    margin: 1rem 0;
    z-index: 2;
    h2 {
        text-transform: uppercase;
        font-weight: 400;
        font-family: $style_font;
        font-size: 1.3rem;
        line-height: 2rem;
        letter-spacing: 3px;
        color: set_text_color($primary_color);
        
    }
    
    .day_wrap {
        display: flex;
        margin: 0.2rem auto;
        width: 70%;
        align-content: center;
        text-align: center;
        justify-content: space-between;
        position: relative;
        color: $light_text_color;

    
        .day {
            span {
                text-transform: uppercase;
                font-weight: 400;
                font-family: $style_font;
                font-size: 0.8125rem;
                letter-spacing: 0.08em;
                color: $dark_grey;
            }
        }
        .hours {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateY(50%);
                font-family: $style_font; 
                font-size: 0.8125rem;
                font-weight: 400;
                line-height: 1rem;
                height: auto;
                align-items: center;
                text-align: center;

            .hour {
                font-family: $style_font; 
                margin-bottom: 1.5rem;
                font-size: 0.8125rem;
                font-weight: 400;
            }
            .hours_separator {
                margin: 0 0.5rem;
            }
            
        }
    }
}
.divider {
    width: 20%;
    background-color: $garden_light;
    border: none;
    height: 0.5px;
}

.closed_icon {
    color: $garden_light;
    transform: translateY(10%);
    margin-right: 0.3rem;
}
// MEDIA
@include mediaXl {
    .contact_post1{
        width: 80vw;
        .left_container {
            .text_container {
                
                h2 {}
                h3 {}
                p {}
            }
        }
        .right_container {
            .contact_gmap {}
        }
    }
    .contact_post2 {
        .text_container {
            width: 40vw;
            
            h2 {}
            p {}
        }
    }
    .contact_else {
        margin: 3rem 0 5rem 0;
        h2 {
            font-size: 1rem;
        }
        .contact_else_container {
            gap: 0;
            .contact_plage {
                min-width: none;
                width: 60%;
            }
            .contact_cata {
                min-width: none;
                width: 60%;
            }
            .contact_jardin {
                min-width: none;
                width: 60%;
            }
            .contact_riviere {
                min-width: none;
                width: 60%;
            }
        }
    }
    .contact_post3 {
        .image_salade {
            top: 5%;
            left: 5%;
            height: 10rem;
            transform: translateY(-70%);
        }
        .contact_post3_left {
          
            width: 35%;
            .text_container {
                
                h2 {
                    
                }
                .practical_infos {
                    h3  {}
                    p {}
                }
            }
        }
        .contact_openings {
            width: 35%;
            h2 {
                
            }
            .day_wrap {
                max-width: 20rem;
                .day {
                    span {}
                }
                .hours {
                    .hour {}
                    .closed_icon {}
                }

            }
        }
    }
}


@include mediaLg {
    .contact_post1{
        width: 80vw;
        .left_container {
            .text_container {
                
                h2 {}
                h3 {}
                p {}
            }
        }
        .right_container {
            .contact_gmap {}
        }
    }
    .contact_post2 {
        .text_container {
            width: 40vw;
            
            h2 {}
            p {}
        }
    }
    .contact_else {
        margin: 3rem 0 5rem 0;
        h2 {
            font-size: 1rem;
        }
        .contact_else_container {
            gap: 0;
            .contact_plage {
                min-width: none;
                width: 90%;
            }
            .contact_cata {
                min-width: none;
                width: 90%;
            }
            .contact_jardin {
                min-width: none;
                width: 90%;
            }
            .contact_riviere {
                min-width: none;
                width: 90%;
            }
        }
    }
    .contact_post3 {
        width: 100vw;
        flex-direction: column;
        
        .image_salade {
            top: 5%;
            left: 2%;
            height: 10rem;
            transform: translateY(-70%);
        }
        .contact_post3_left {
            width: 60%;
            margin: 0 auto;
    
    
            .text_container {
                padding: 1rem;
                h2 {
                    font-size: 1.1rem;
                }
                .practical_infos {
                    h3  {}
                    p {}
                }
            }
        }
        .contact_openings {
            
            width: 60%;
            margin: 0 auto;
            h2 {
                font-size: 1.1rem;
            }
            .day_wrap {
                max-width: 20rem;
                .day {
                    span {}
                }
                .hours {
                    .hour {}
                    .closed_icon {}
                }

            }
        }
    }
}

@include mediaMd {
    .contact_post1{
        flex-direction: column;
        height: 70vh;
        width: 70vw;
        margin: 5rem auto;
        .left_container {
            height: 50%;
            width: 100%;
            .text_container {
                h2 {}
                h3 {}
                p {}
            }
        }
        .right_container {
            height: 50%;
            width: 100%;
            .contact_gmap {}
        }
    }
    .contact_post2 {
        .text_container {
            h2 {}
            p {}
        }
    }
    .contact_else {
        margin: 3rem 0 5rem 0;
        h2 {
            font-size: 1rem;
        }
        .contact_else_container {
            gap: 0;
            .contact_plage {
                min-width: none;
                width: 90%;
            }
            .contact_cata {
                min-width: none;
                width: 90%;
            }
            .contact_jardin {
                min-width: none;
                width: 90%;
            }
            .contact_riviere {
                min-width: none;
                width: 90%;
            }
        }
    }
    .contact_post3 {
        flex-direction: column;
        width: 100vw;
        .image_salade {
            top: 2%;
            left: 2%;
            height: 8rem;
            transform: translateY(-70%);
        }
        .contact_post3_left {
            width: 70%;
            .text_container {
                h2 {}
                .practical_infos {
                    h3  {}
                    p {}
                }
            }
        }
        .contact_openings {
            width: 70%;
            h2 {}
            .day_wrap {
                .day {
                    span {}
                }
                .hours {
                    .hour {}
                    .closed_icon {}
                }

            }
        }
    }
}


@include mediaSm {
    .contact_post1{
        flex-direction: column;
        height: 70vh;
        width: 70vw;
        margin: 3rem auto;
        .left_container {
            height: 50%;
            width: 100%;
            .text_container {
                h2 {
                    
                }
                h3 {
                    font-size: 0.9rem;
                }
                p {}
                .contact_adress {
                    font-size: 0.7rem;
                }
            }
        }
        .right_container {
            height: 50%;
            width: 100%;
            .contact_gmap {}
        }
    }
    .contact_post2 {
        .text_container {
            width: 70vw;
            h2 {font-size: 1rem;}
            p {
                font-size: 0.7rem;
            }
        }
    }
    .contact_else {
        margin: 3rem 0 5rem 0;
        h2 {
            font-size: 1rem;
        }
        .contact_else_container {
            gap: 0;
            .contact_plage {
                min-width: none;
                width: 90%;
                h3 {}
            }
            .contact_cata {
                min-width: none;
                width: 90%;
                h3 {}
            }
            .contact_jardin {
                min-width: none;
                width: 90%;
                h3 {}
            }
            .contact_riviere {
                min-width: none;
                width: 90%;
                h3 {}
            }
        }
    }
    .contact_post3 {
        flex-direction: column;
        width: 100vw;
        .image_salade {
            top: 0%;
            left: 2%;
            height: 6rem;
            transform: translateY(-70%);
        }
        .contact_post3_left {
            width: 90%;
            
            .text_container {
                padding: 0.5rem;
                h2 {}
                .practical_infos {
                    h3  {}
                    p {}
                }
            }
        }
        .contact_openings {
            width: 90%;
        
            h2 {}
            .day_wrap {
                .day {
                    span {}
                }
                .hours {
                    .hour {}
                    .closed_icon {}
                }

            }
        }
    }
}

@include mediaXs {
    .contact_post1{
        flex-direction: column;
        height: 90vh;
        width: 70vw;
        margin: 3rem auto;
        .left_container {
            height: 50%;
            width: 100%;
            padding: 1rem 0;
            .text_container {
                h2 {
                
                }
                h3 {
                    font-size: 0.9rem;
                }
                p {}
                .contact_adress {
                    font-size: 0.7rem;
                }
            }
        }
        .right_container {
            height: 60%;
            width: 100%;
            .contact_gmap {
                font-size: 1rem;
            }
        }
    }
    .contact_post2 {
        .text_container {
            width: 70vw;
            h2 {font-size: 1rem;}
            p {
                font-size: 0.7rem;
            }
        }
    }
    .contact_else {
        margin: 3rem 0 5rem 0;
        h2 {
            font-size: 1rem;
        }
        .contact_else_container {
            gap: 0;
            .contact_plage {
                min-width: none;
                width: 90%;
                h3 {}
            }
            .contact_cata {
                min-width: none;
                width: 90%;
                h3 {}
            }
            .contact_jardin {
                min-width: none;
                width: 90%;
                h3 {}
            }
            .contact_riviere {
                min-width: none;
                width: 90%;
                h3 {}
            }
        }
    }
    .contact_post3 {
        flex-direction: column;
        width: 100vw;
        .image_salade {
            top: 0%;
            left: 2%;
            height: 6rem;
            transform: translateY(-70%);
        }
        .contact_post3_left {
            width: 90%;
            
            .text_container {
                padding: 0.5rem;
                h2 {}
                .practical_infos {
                    h3  {}
                    p {}
                }
            }
        }
        .contact_openings {
            width: 90%;
        
            h2 {}
            .day_wrap {
                .day {
                    span {}
                }
                .hours {
                    .hour {}
                    .closed_icon {}
                }

            }
        }
    }
}
