.links_container {
    display: flex;
    h3 {
        color: $light_text_color;
       font-weight: 400;
        font-size: 1rem;
        text-transform: uppercase;
        
    }
    ul {
        display: flex;
        flex-direction: column ;
        .quick_link {
            list-style: none;
            color: $light_text_color;
            text-decoration: none;
            font-size: 0.8rem;
            &:hover {
                 color: $secondary_color;
            }
        }
    }
}