.hero_half_contact_cata {
    @extend .hero_half;
    @extend .black_veil_04;
    background-image: url(../pictures/le_catamaran/night/mauritius_sunset.jpg);
    background-position: 100% 50%;
}

.contact_post2_cata {
    min-height: none;
    @extend .contact_post2;
    
}
.span_resa_cata {
    color: $sunset;
}
.lecata_gmap{
        width: 100%;
        height: 100%;
        border: none;    
        // -webkit-filter: grayscale(100%);
        // -moz-filter: grayscale(100%);
        // -ms-filter: grayscale(100%);
        // -o-filter: grayscale(100%);
        // filter: grayscale(100%);
}
.lecata_ocean{
    color: $ocean;
    text-transform: uppercase
}
.contact_phone_lecata{
    @extend .h3_title_bold;
    color: $lagoon;
}
.divider_lecata{
    @extend .divider;
    background-color: $lagoon;
}

.icon_fermé_cata {
    @extend .closed_icon;
    color: $lagoon;
}

.contact_openings_cata {
    @extend .contact_openings;
    border-top: 1px solid $lagoon;
    border-right: 1px solid $lagoon;
    border-bottom: 1px solid $lagoon;
}


.contact_post3 {
    position: relative;
}


.ile_flottante_image {
    position: absolute;
    top: 0;
    left: 4%;
    height: 13rem;
    transform: translateY(-70%);
}

@include mediaXl {
    .ile_flottante_image {
        top: 2%;
        left: 3%;
        // transform: translateY(10%);
        height: 10rem;
    }
}

@include mediaLg {
    .ile_flottante_image {
        top: 2%;
        left: 3%;
        // transform: translateY(10%);
        height: 8rem;
    }
}

@include mediaMd {
    .ile_flottante_image {
        top: 0%;
        left: 3%;
        // transform: translateY(10%);
        height: 7rem;
    }
}
@include mediaSm {
    .ile_flottante_image {
        top: 0%;
        left: 3%;
        // transform: translateY(10%);
        height: 5rem;
    }
}