// .hero2 {
//     background-image: url(./pictures/restaurant_terrasse2.jpg);
//     background-size: cover;
//     background-position: center;
//     width: 100vw;
//     height: 60vh;
//     &:before {
//         content: "";
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         background-color: rgba(0,0, 0, 0.5);
        
//     }

// }
.gallery_hero {
    background-image: url(../pictures/equipe_compressed.jpg);
    
    background-position: 50% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 40%;
    height: 100vh;
    width: 100vw;

    z-index: -2;
    position: fixed;
    top: 0;
    left: 0; 
    
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0, 0, 0.4);
        z-index: -1;
    }
   
}
.galelry_title_container {
    transform: translate(0, 100%);
    .gallery_title {
        text-transform: uppercase;
        color: $light_text_color;
        font-size: 5rem;
        font-weight: 400;
        letter-spacing: 3px;
        margin: 0;

    }
    h3 {
        margin: 0;
        text-transform:initial ;
        color: $light_text_color;
        font-size: 2rem;
        font-weight: 400;
        letter-spacing: 2px;
    }
}

.gallery_container {
    // position: absolute;
    // top: 9rem;
    // left: 0;
    // width: 100vw;
    // height: 100vh;
    padding: 2rem 0;
    width: 100vw;
    height: auto;
    background-color: $light_text_color;
    
    .sliderWrap {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99;
        background-color: rgba($primary_color, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        
        .close_btn {
            position: fixed;
            top: 1%;
            right: 1%;
            cursor: pointer;
            color: $light_text_color;
            z-index: 99;
            opacity: 0.6;
            
            
            &:hover {
                opacity: 1;
                @include fastEaseOut;
            }
        }
        .prev_btn {
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            left: 2%;
            background-color: $dark_grey;
            border-radius: 30px; 
            padding: 0.7rem;
            cursor: pointer;
            color: $light_text_color;
            z-index: 99;
            opacity: 0.6;
            &:hover {
                opacity: 0.8;
                @include fastEaseOut;
            }
        }
        .next_btn {
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            right: 2%;
            cursor: pointer;
            color: $light_text_color;
            z-index: 99;
            opacity: 0.6;
            background-color: $dark_grey;
            border-radius: 30px; 
            padding: 0.7rem;
            &:hover {
                opacity: 0.8;
                @include fastEaseOut;
            }
        }
        .full_screen_img {
            max-width: 90vw;
            display: flex;
            align-items: center;
            justify-content: center;
            .img {
                max-width: 90vw;
                max-height: 100vh;
            }

        }
    }
}


.gallery_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 90%;
    height: auto;


    .single {
        max-width: 300px;
        height: 200px;
        &:hover {
            cursor: pointer;
        }
        img {
            max-width: 100%;
            height: 100%;
            @include fastEaseOut;
            &:hover {
                transform: scale(1.02);
            }
        }
    }
}

@include mediaLg {
    .gallery_container {
        top: 7rem;
    }
    .galelry_title_container {
        .gallery_title {}
    }
}

@include mediaMd {
    .gallery_container {
        top: 4rem;
    }
}

@include mediaSm {
    .gallery_hero {
        background-position: 46% 50% !important;
    }
    .gallery_container {
        top: 3rem;
    }
    .galelry_title_container {
        transform: translate(0, 150%);
        width: 100vw;
        .gallery_title {
            font-size: 3rem;
        }
        h3 {
            font-size: 0.75rem;
            width: 95%;
            // background-color: lightblue;
        }
    }
}
@include mediaXs {
    .gallery_container {
        top: 3rem;
    }
    .galelry_title_container {
        .gallery_title {
            font-size: 2.7rem;
        }
        h3 {
            font-size: 1rem;
        }
    }
    .galelry_title_container {
        transform: translate(0, 150%);
        width: 100vw;
        .gallery_title {
            font-size: 3rem;
        }
        h3 {
            font-size: 0.75rem;
            width: 95%;
            // background-color: lightblue;
        }
    }
}