.hero_half_contact_ile_plate {
    @extend .hero_half;
    @extend .black_veil_04;
    background-image: url(../pictures/lile_plate/allee.jpg);
    background-position: 100% 50%;
}

.image_cut_contact_ile_plate {
    position: absolute;
    top: 4rem;
    left: 8rem;
    height: 25rem;
    transform: translateY(-90%);
    z-index:5;
}
.contact_ile_plate_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../pictures/le_catamaran/glasses.jpg);
    background-size: cover;
    background-position: 60% 45%;
 
    @extend .black_veil_07;

}

@include mediaXl {
    .image_cut_contact_ile_plate {
       top: 10%;
        left:5%;
        height: 20rem;
    } 
}

@include mediaLg {
    .image_cut_contact_ile_plate {
        top: 15%;
        left: 3%;
        height: 17rem;
    }
}

@include mediaMd {
    .image_cut_contact_ile_plate {
        
        
        height: 13rem;
    }
}
@include mediaSm {
    .image_cut_contact_ile_plate {
        top: 5%;
        left: 3%;
        height: 12rem;
    }
}