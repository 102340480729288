.scroll_container {
    background-color: transparent;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;

    .fixed_bg_plage{
        position: fixed;
        top: 0rem;
        left: 0;
        background-image:url(../pictures/la_plage/fauteuils_paysage.jpg);
        width: 100vw;
        height: 100vh;
        background-size: cover;
        background-position: 40% 30%;
        z-index: -1;
        @extend .black_veil_04;
    }
    .plage_comingsoon {
        @extend .h3_title_upper;
        position: absolute;
        top: 60vh;
        left: 50vw;
        transform: translateX(-50%);
        color: set_text_color(black);
        font-weight: bold;
        text-transform: capitalize;
    }
}

@include mediaLg {
    .scroll_container {
        .fixed_bg_plage {}
        .scroll_header {
            h2 {

            }
            .plage_comingsoon {
                white-space: nowrap;
                font-size: 1.3rem;
         
            }
        }
    }
}

@include mediaMd {
    .scroll_container {
        .fixed_bg_plage {}
        .scroll_header {
            h2 {

            }
            .plage_comingsoon {
               
            }
        }
    }
}

@include mediaSm {
    .scroll_container {
        .fixed_bg_plage {}
        .scroll_header {
            h2 {

            }
            .plage_comingsoon {}
        }
    }
}